.auto-complete{
  .search-store {
    position: relative;
    // padding: pxToVW(10) pxToVW(20) 0 pxToVW(20);
    margin-top: 23px;
    // .icon-search{
    //   position: absolute;
    //   top: 22px;
    //   right: 35px;
    // }
    input{
      caret-color: #fed400;
    }
    input.input-search{
      position: relative;
      border-color: #f5f6f8;
      outline: none;
      box-shadow: none;
      height: 46px;
      background: #f5f6f8;
      border-radius: 10px;
      border: none !important;
      font-family: Pretendard;
      font-size: 14px;
      &::placeholder{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #c2c9d5;
      }
    }
    input.has-select{
      padding-left: 90px;
    }
    .select-dropdown{
      position: absolute;
      display: flex;
      align-items: center;
      left: 30px;
      height: 46px;
      span{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #000;
        padding-right: 5px;
      }
      .icon-dropdown{
        width: 9.5px;
        height: 5.4px;
      }
    }
    .icon-search-position{
      position: absolute;
      left: 16px;  
      top: 16px;
    }
    input.has-search-position{
      padding-left: 40px;
    }
  }
  .find-store-search{
    margin-top: 15px;
    padding: 0px;
    position: relative;
    // .icon-search{
    //   position: absolute;
    //   top: 15px;
    //   right: 17px;
    // }
    input.input-search{
      border-color: #d9d9d9;
      outline: none;
      box-shadow: none;
    }
  }
  .find-store-like-search{
    margin-top: 20px;
  }
  .result-search{
    // height: 30vh;
    // position: absolute;
    width: 100%;
    background: #fff;
    z-index: 999;
    // left: 20px;
    // right: 20px;
    // border-radius: 5px;
    // box-shadow: 0 pxToVW(3) pxToVW(20) 0 rgba(243, 242, 235, 0.2);
    // border: solid 1px #d9d9d9;
    overflow: scroll;
    // margin-top: 20px;
    width: auto;
    padding: 20px 0px;
    .list-search-item {
      border-bottom: 1px solid #d9d9d9;
      padding: 10px 0;
      // display: flex;
      // align-items: center;
      &__text{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
        margin-bottom: 12px;

        span.color-autocompletes{
          // color: #fed400;
          font-weight: bold;
        }
      }
      .position{
        display: flex;
        justify-content: left;
        align-items: flex-start;;
        margin-bottom: 15px;
        .icon-position{
          width: 8px;
          height: 16.9px;
          margin-right: 4px;
        }
        span.address{
          line-height: 20px;
          font-family: Pretendard;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #8f949c;
        }
        
      }
    }
    .infinite-scroll-component {
      .no-data{
        border-bottom: 1px solid #d9d9d9;
        padding: 10px 0;
        .text-muted{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #fed400;
        }
      }
    }
  }
  .flex-filter{
    display: flex;
    align-items: center;
    .icon-filter{
      width: 30px;
      text-align: center;
    }
  }

  .flex-search{
    display: flex;
    align-items: center;
    .icon-search{
      width: 47px;
      height: 46px;
      margin: 0 0 0 10px;
      padding: 16px 16px 15px;
      border-radius: 10px;
      background-color: #fed400;
      &.disabled{
        background-color: #f5f6f8;
      }
    }
  }
}
