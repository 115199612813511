
.playground-main {
  padding: 0;
  .mt-32px {
    margin-top: 32px;
  }
  &__filter {
    border-bottom: 1px solid #f2f2f2;
    padding: 20px 20px 16px;
    position: fixed;
    width: 100%;
    background: white;
    z-index: 900;
  }
  .main-box-category {
    padding-top: 40px;
    display: flex;
    align-content: center;
    .btn-category {
      padding: 5px 12px 5px 5px;
      border-radius: 18px;
      border: solid 1px #dfe2e6;
      background-color: #fff;
      display: flex;
      align-items: center;
      margin-right: 6px;
      &.active {
        border: solid 1px #363b45;
      }
      label {
        margin: 0;
        padding: 0;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.35px;
        text-align: left;
        color: #363b45;
        padding-left: 6px;
      }
      img {
        width: 25px;
        height: 26px;
        display: inline-block;
      }
    }
  }
  .main-box-add-store {
    text-align: center;
    p {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.4px;
      text-align: center;
      color: #363b45;
    }
    label {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: -0.35px;
      text-align: center;
      color: #a8afb9;
      display: block;
      padding: 9px 0 0;
    }
    button {
      padding: 11px 28px;
      border-radius: 19px;
      border: solid 1px #ffe4b4;
      background-color: #fff9d6;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.35px;
      text-align: left;
      color: #efb752;
      margin-top: 13px;
    }
  }
  .main-box-store-relation {
    text-align: center;
    p {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.4px;
      text-align: center;
      color: #363b45;
    }
    label {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.35px;
      text-align: center;
      color: #a8afb9;
      margin-top: 9px;
    }
  }
  .main-box-communicate {
    p {
      font-family: Pretendard;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.5px;
      text-align: left;
      color: #363b45;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 18px;
        margin-left: 5px;
      }
    }
    label {
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.45px;
      text-align: left;
      color: #363b45;
    }
  }
  .main-box-title-filter {
    display: flex;
    margin-bottom: 15px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 3px;
    }
    label {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.4px;
      text-align: left;
      color: #363b45;
    }
  }
  .main-box-filter {
    //overflow-x: auto;
    margin: 0 -20px;
    //display: flex;
    //align-items: center;
    height: 36px;
    .item-filter {
      padding: 8px 16px;
      border-radius: 17px;
      background-color: #f5f6f8;
      white-space: nowrap;
      margin-right: 6px;
      border: none;
      &:first-child {
        margin-left: 20px;
      }
      border: solid 1px #f5f6f8;
      &.active {
        border-radius: 17px;
        border: solid 1px #fed400;
        background-color: #fffbe2;
        label {
          color: #d4b100;
        }
      }
      label {
        margin: 0;
        padding: 0;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.35px;
        text-align: left;
        color: #363b45;
      }
    }
  }
  .wrapper-page-playground {
    padding: 0px 20px;
    .playground-list {
      padding-bottom: 20px;
      &__item {
        border-bottom: 1px solid #f2f2f2;
        padding: 16px 0;
        position: relative;
        .item-content-post {
          display: flex;
          position: relative;
          &__text {
            width: calc(100% - 97px);
            .text-title {
              font-family: Pretendard;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: -0.4px;
              text-align: left;
              color: #363b45;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block !important;
              margin-bottom: 4px;
              div{
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.4px;
                text-align: left;
                color: #363b45;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block !important;
              }
              strong {
                color: #fed400;
                font-family: Pretendard;
                font-size: 16px;
              }
            }
            .text-description {
              font-family: Pretendard;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: -0.35px;
              text-align: left;
              color: #8f949c;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block !important;
              max-height: 20px;
              strong {
                color: #fed400;
                font-family: Pretendard;
                font-size: 14px;
              }
            }
            .box-hashtag-like-comment {
              margin-top: 20px;
              min-height: 30px;
            }
            .item-box-store {
              margin-top: 2px;
              &__store {
                max-width: calc(100% - 60px);
                &__text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: block !important;

                }
              }
            }
            .box-hasttag {
              width: calc(100% - 35px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .item-hashtag {
                background: transparent;
                padding: 0;
                margin-right: 6px;
                //overflow: hidden;
                //text-overflow: ellipsis;
                //white-space: nowrap;
                line-height: .9;
                font-family: Pretendard;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.3px;
                text-align: left;
                color: #363b45;
                &.active {
                  color: #fed400;
                }
              }
            }
          }
          &__image {
            margin-left: 0px;
            position: relative;
            .img-post {
              width: 80px;
              height: 80px;
              object-fit: cover;
              border-radius: 12px;
            }
            .count-number {
              position: absolute;
              right: 4px;
              top: 4px;
              padding: 1px 7px 1px 6px;
              border-radius: 6px;
              background-color: rgba(14, 15, 17, 0.4);
              height: 16px;
              display: flex;
              align-items: center;
              label {
                font-family: Pretendard;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: .9;
                letter-spacing: -0.3px;
                text-align: left;
                color: #dfe2e6;
              }
            }
          }
        }
        &.playground-item {
          padding: 16px;
          border-radius: 14px;
          box-shadow: 0 3px 8px 0 rgba(236, 233, 227, 0.32);
          border: solid 1px #f7efe5;
          background-color: #fff;
          margin-bottom: 12px;
          .box-action-like-comment {
            right: 0px;
            bottom: 2px;
            .item-action {
              img {
                width: 9px;
                height: 8px;
              }
              &.item-like {
                padding: 3px 8px 3px 7px;
                border-radius: 9px;
                background-color: rgba(14, 15, 17, 0.5);
                margin: 4px;
                label {
                  font-family: Pretendard;
                  font-size: 11px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: -0.28px;
                  text-align: left;
                  color: #fff;
                  margin: 2px 0 0 3px;
                }
              }
              &.item-comment {
                display: none;
              }
            }
          }
          .text-title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            strong {
              color: #fed400;
              font-family: Pretendard;
              font-size: 16px;
            }
          }
          .text-description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-top: -3px;
          }
          //.item-action-dropdown{
          //  width: 16px;
          //  height: 16px;
          //  background-color: rgba(14, 15, 17, 0.6);
          //  border-radius: 50%;
          //  display: flex;
          //  align-items: center;
          //  justify-content: center;
          //  right: 16px;
          //  top: 16px;
          //  margin: 4px;
          //  span {
          //    display: inline-block;
          //    width: 2px;
          //    height: 2px;
          //    background-color: rgba(255, 255, 255, 0.8);
          //    margin:0 2px 0 0;
          //    &:last-child {
          //      margin-right: 0;
          //    }
          //  }
          //}
          .item-action-dropdown {
            right: 2px;
            height: 18px;
            top: 26px;
            span {
              &:first-child {
                margin-top: 0;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .dropdown-action {
            right: 13px;
            top: 44px;
          }
          .item-content-post {
            justify-content: space-between;
            align-items: center;
          }
          .box-hashtag-like-comment {
            margin: 0;
            min-height: auto;
          }
          .item-info-user {
            
            margin-top: 10px;
            margin-bottom: 8px;
            &__avatar {
              width: 18px;
              height: 18px;
            }
            &__text {
              display: flex;
              align-items: center;
              .nick-name {
                font-family: Pretendard;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.3px;
                text-align: left;
                color: #363b45;
                margin-bottom: 0;
                padding-top: 0;
                margin-right: 4px;
              }
              .created-at {
                font-family: Pretendard;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.3px;
                text-align: left;
                color: #a8afb9;
                white-space: nowrap;
                max-width: 120px;
                overflow: hidden;
                position: relative;
                display: flex;align-items: center;
                &::before {
                  content: "";
                  background-color: #a0a8b4;
                  width: 2px;
                  height: 2px;
                  border-radius: 50%;
                  display: block;
                  margin-right: 4px;
                }
              }
            }
          }

          .highlight {
            color: #fed400;
          }
        }
      }

      .non-data {
        width: 100%;
        
        p {
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: -0.4px;
          text-align: center;
          color: #767b84;
        }

        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: -0.35px;
          text-align: center;
          color: #a8afb9;
        }
      }
    }
    &.list-inquiry-qna {
      .playground-list {
        padding-bottom: 20px;
        .item-action-dropdown {
          span {
            background-color: #363b45;
          }
        }
        &__item {
          border-bottom: 1px solid #f2f2f2;
          padding: 16px 0;
          position: relative;
          .item-info-user {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            &__avatar {
              width: 44px;
              height: 44px;
              border-radius: 50%;
              object-fit: cover;
              margin-right: 6px;
            }
            &__text {

              .nick-name {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.35px;
                text-align: left;
                color: #363b45;
                margin-bottom: 6px;
                padding-top: 5px;
              }
              .created-at {
                font-family: Pretendard;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.3px;
                text-align: left;
                color: #a8afb9;
              }
            }
          }
          .item-content-post {
            display: flex;
            position: relative;
            &__text {
              width: 100%;
              .text-title {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: -0.4px;
                text-align: left;
                color: #363b45;
                strong {
                  color: #fed400;
                  font-family: Pretendard;
                  font-size: 16px;
                }
              }
              .text-description {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.35px;
                text-align: left;
                color: #363b45;
                overflow: hidden;
                display: block;
                max-height: inherit;
                text-overflow: initial;
                white-space: initial;
                strong {
                  color: #fed400;
                  font-family: Pretendard;
                  font-size: 14px;
                }
              }
              .box-hashtag-like-comment {
                margin-top: 39px;
                min-height: 0px;
              }
              .box-hasttag {

                .item-hashtag {
                  padding: 6px 8px;
                  border-radius: 13px;
                  background-color: #f5f6f8;
                  font-family: Pretendard;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 2.5;
                  letter-spacing: -0.3px;
                  text-align: left;
                  color: #767b84;
                  margin-right: 4px;
                  white-space: nowrap;
                  &.active {
                    color: #fed400;
                  }
                }
              }
            }
            &__image {
              margin-left: 0px;
              position: relative;
              margin-bottom: 15px;
              .img-post {
                width: 100%;
                height: 150px;
                object-fit: cover;
                border-radius: 5px;
              }
              .count-number {
                position: absolute;
                right: 4px;
                top: 4px;
                padding: 1px 7px 1px 6px;
                border-radius: 6px;
                background-color: rgba(14, 15, 17, 0.4);
                height: 16px;
                display: flex;
                align-items: center;
                label {
                  font-family: Pretendard;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: .9;
                  letter-spacing: -0.3px;
                  text-align: left;
                  color: #dfe2e6;
                }
              }
            }
          }
          .box-action-like-comment {
            display: flex;
            align-items: center;
            margin-top: 10px;
            justify-content: end;
            &.not-has-image {
              position: absolute;
              bottom: 15px;
              right: 0;
            }
            .item-action {
              display: flex;
              align-items: center;
              label{
                font-family: Pretendard;
                font-size: 11px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: .9;
                letter-spacing: normal;
                text-align: left;
                color: #0e0f11;
                margin-left: 3px;
                margin-top: 3px;
              }
              img {
                width: 20px;
                height: 18px;
              }
              &.item-comment {
                margin-left: 23px;
              }
            }
          }
        }
        .comment {
          background-color: #f5f5f5 !important;
          margin: 0 -20px;
          .item-comment {
            .wrap-item-comment {
              .comment-right__info {
                margin-bottom: 15px;
                .left {
                  display: block;
                  .time {
                    font-family: Pretendard;
                    font-size: 11px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: left;
                    color: #8f949c;
                  }
                }

              }
              .comment-right {
                .comment-content {
                  .content {
                    font-family: Pretendard;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: left;
                    color: #363b45;
                  }
                }
              }
            }
          }
          .comment-reply {
            .input-comment {

              .input-comment__custom {
                min-height: 46px;
                padding-right: 35px;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }
          .comment-form {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .btn-to-page-create{
    width: 54px;
    height: 54px;
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #fed400;
    right: 20px;
    position: fixed;
    z-index: 999;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.playground-search-main {
  padding: 5.55556vw 0 5.55556vw;
  .playground-search-main__filter {
    padding: 0 5.55556vw;
  }
  .auto-complete {
    .item-clear {
      width: 22px;
      height: 22px;
      position: absolute;
      right: 70px;
    }

    .search-store {
      position: relative;
      // padding: pxToVW(10) pxToVW(20) 0 pxToVW(20);
      margin-top: 8px;
      margin-bottom: 20px;

      input {
        caret-color: #fed400;
      }

      input.input-search {
        position: relative;
        border-color: #f5f6f8;
        outline: none;
        box-shadow: none;
        height: 46px;
        background: #f5f6f8;
        border-radius: 10px;
        border: none !important;
        font-family: Pretendard;
        font-size: 14px;
        padding-right: 42px;

        &::placeholder {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #c2c9d5;
        }
      }

      input.has-select {
        padding-left: 90px;
      }

      .select-dropdown {
        position: absolute;
        display: flex;
        align-items: center;
        left: 30px;
        height: 46px;

        span {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #000;
          padding-right: 5px;
        }

        .icon-dropdown {
          width: 9.5px;
          height: 5.4px;
        }
      }

      .icon-search-position {
        position: absolute;
        left: 16px;
        top: 16px;
      }

      input.has-search-position {
        padding-left: 40px;
      }
    }

    .find-store-search {
      margin-top: 15px;
      padding: 0px;
      position: relative;
      // .icon-search{
      //   position: absolute;
      //   top: 15px;
      //   right: 17px;
      // }
      input.input-search {
        border-color: #d9d9d9;
        outline: none;
        box-shadow: none;
      }
    }

    .find-store-like-search {
      margin-top: 20px;
    }

    .result-search {
      // height: 30vh;
      // position: absolute;
      width: 100%;
      background: #fff;
      z-index: 999;
      // left: 20px;
      // right: 20px;
      // border-radius: 5px;
      // box-shadow: 0 pxToVW(3) pxToVW(20) 0 rgba(243, 242, 235, 0.2);
      // border: solid 1px #d9d9d9;
      overflow: scroll;
      // margin-top: 20px;
      width: auto;
      padding: 20px 0px;

      .list-search-item {
        border-bottom: 1px solid #d9d9d9;
        padding: 10px 0;
        // display: flex;
        // align-items: center;
        &__text {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: left;
          color: #363b45;
          margin-bottom: 12px;

          span.color-autocomplete {
            color: #fed400;
          }
        }

        .position {
          display: flex;
          justify-content: left;
          align-items: flex-start;;
          margin-bottom: 15px;

          .icon-position {
            width: 8px;
            height: 16.9px;
            margin-right: 4px;
          }

          span.address {
            line-height: 20px;
            font-family: Pretendard;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #8f949c;
          }

        }
      }

      .infinite-scroll-component {
        .no-data {
          border-bottom: 1px solid #d9d9d9;
          padding: 10px 0;

          .text-muted {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #fed400;
          }
        }
      }
    }

    .flex-filter {
      display: flex;
      align-items: center;

      .icon-filter {
        width: 30px;
        text-align: center;
      }
    }

    .flex-search {
      display: flex;
      align-items: center;

      .icon-search {
        width: 47px;
        height: 46px;
        margin: 0 0 0 10px;
        padding: 16px 16px 15px;
        border-radius: 10px;
        background-color: #fed400;

        &.disabled {
          background-color: #f5f6f8;
        }
      }
    }
  }

  &__filter {
    .box-data-empty {
      text-align: center;

      span {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.14;
        letter-spacing: -0.35px;
        text-align: center;
        color: #a8afb9;
      }
    }

    .box-data-default {
      &__hashtag {
        margin-bottom: 17px;

        .text-title {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.4px;
          text-align: left;
          color: #363b45;
          margin-bottom: 16px;
        }

        .list-hashtag {
          margin: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          span {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.35px;
            text-align: left;
            color: #d4b100;
            margin: 0 6px 0 0;
            padding: 9px 16px;
            border-radius: 17px;
            background-color: #fffbe2;
            white-space: nowrap;
            margin-bottom: 6px;
          }
        }
        .main-box-title-filter {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.4px;
          text-align: left;
          color: #363b45;
          margin: 20px 0 14px;
        }
      }

      &__search-nearby {
        margin-bottom: 34px;

        .box-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          .btn-delete {
            border: none;
            font-family: Pretendard;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: -0.3px;
            text-align: left;
            color: #767b84;
            margin: 0;
            padding: 5px 9px;
            border-radius: 12px;
            background-color: #f2f2f2;
          }
        }

        .text-title {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.4px;
          text-align: left;
          color: #363b45;

        }
        .box-list-item {
          margin-right: -20px;
          overflow-x: auto;
          margin-left: -20px;
          .list-item {
            width: 100%;
            max-height: 450px;
            display: flex;
            align-items: center;

            &__item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: auto;
              padding: 9px 14px ;
              border-bottom: 1px solid #f2f2f2;
              margin-right: 6px;
              border-radius: 18px;
              border: solid 1px #efefef;
              background-color: #fff;
              &:first-child {
                margin-left: 20px;
              }
              span {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.35px;
                text-align: left;
                color: #767b84;
                white-space: nowrap;
                margin-right: 7px;
              }

              .item-delete {
                width: 10px;
                height: 10px;
              }
            }


          }
        }

      }
      .main-box-filter {
        height: auto;
        margin: 0;
        .x-scroll {
          overflow-x: auto;
          white-space:initial;
        }
        .item-filter {
          margin-bottom: 6px;
          border-radius: 17px;
          background-color: #fffbe2;
          label {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -0.35px;
            text-align: left;
            color: #d4b100;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .box-data-search {
    padding: 0;
  }
}

.item-action-dropdown {
  width: 32px;
  height: 32px;
  position: absolute;
  right: -14px;
  top: 13px;
  z-index: 100;
  span {
    width: 4px;
    height: 4px;
    margin: 3px auto 3px;
    background-color: #c2c9d5;
    border-radius: 50%;
    display: block;
  }
}
.dropdown-action {
  position: absolute;
  right: 0px;
  top: 40px;
  width: 65px;
  min-height: 68px;
  background: white;
  border-radius: 7px;
  border: solid 1px #f2f2f2;
  z-index: 99;
  ul {
    li {
      padding: 10px 19px 8px 20px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #0e0f11;
      white-space: nowrap;
      &:first-child {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
}

.box-action-like-comment {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: end;
  z-index: 50;
  &.not-has-image {
    position: absolute;
    bottom: 3px;
    right: 0;
  }
  .item-action {
    display: flex;
    align-items: center;
    label{
      font-family: Pretendard;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: .9;
      letter-spacing: normal;
      text-align: left;
      color: #767b84;
      margin-left: 3px;
      margin-top: 3px;
    }
    img {
      width: 17px;
      height: 16px;
    }
    &.item-comment {
      margin-left: 12px;
    }
  }
}

.item-info-user {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 6px;
  }
  &__text {

    .nick-name {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.35px;
      text-align: left;
      color: #363b45;
      margin-bottom: 3px;
      padding-top: 5px;
    }
    .is-admin{
      border: 1px solid red;
      border-radius: 5px;
      height: 20px;
      display: flex;
      align-items: center;
      margin-right: 6px;
      .text-admin{
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        text-align: left;
        color: red;
        padding: 0px 12px;
      }
    }
    .created-at {
      font-family: Pretendard;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.3px;
      text-align: left;
      color: #a8afb9;
    }
  }
}

.box-hasttag {

  .item-hashtag {
    padding: 6px 8px;
    border-radius: 13px;
    background-color: #f5f6f8;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: -0.3px;
    text-align: left;
    color: #767b84;
    margin-right: 4px;
    white-space: nowrap;
    &.active {
      color: #fed400;
    }
  }
}

.page-playground{

  margin-top: pxToVW(29);
}

.loading-community{
  padding: 23px 20px;
  .info{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 24px;
    .avatar{
      margin-right: 6px;
    }
  }
  .content{
    .text{
      padding-bottom: 10px;
    }
    .img{
      .react-loading-skeleton{
        border-radius: 10px;
      }
    }
  }
}
.loading-comment{
  padding: 25px 20px 0px 20px;
  .info{
    display: flex;
    justify-content: left;
    align-items: center;
    .avatar{
      margin-right: 6px;
    }
  }

  .content{
    .name{
      .react-loading-skeleton{
        margin-bottom: 5px
      }
    }
  }
}

@import "./form";

.position-inherit {
  position: inherit !important;
}

.item-box-store {
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
    &__store {
      padding: 4px 6px;
      border-radius: 6px;
      background-color: #fdf5e0;
      display: flex;
      align-items: center;
      margin-right: 4px;
      &__icon {
        width: 10px;
        height: 11px;
        margin-right: 4px;
      }
      &__text {
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.3px;
        text-align: left;
        color: #f1a869;
      }
    }
  &__count{
    display: flex;
    align-items: center;
    label {
      font-family: Pretendard;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.3px;
      text-align: left;
      color: #f1a869;
    }
  }
}

.comment-in-list {
  padding-bottom: pxToVW(25);
  background-color: #f5f5f5;
  margin-right: pxToVW(-20);
  margin-left: pxToVW(-20);
  // max-height: 500px;
  overflow-x: scroll;
  padding-bottom: 0;

  textarea.input-comment__custom {
    border-radius: 23px !important;
    padding: 13px 14px !important;
    padding-right: 35px !important;
  }

  .comment-form {
    margin-right: pxToVW(20);
    margin-left: pxToVW(20);
    position: sticky;
    bottom: -1px;
    background: #f5f5f5 !important;
    margin: 0;
    z-index: 888;

    form {
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .comment-list-empty {
    padding: 15px;
  }
}

.x-scroll {
  overflow-x: scroll;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
}
.box-hashtag-comment {
  display: flex;
  justify-content: space-between;
  .item-comment {
    display: flex;
    align-items: center;
    margin-top: -6px;
    img {
      width: 13px;
      height: 13px;
    }
    label {
      font-family: Pretendard;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #767b84;
      padding-top: 2px;
      padding-left: 5px;
    }
  }
}
.item-box-comment {
  display: flex;
  align-items: center;
  margin-top: -6px;
  position: absolute;
  right: 0;
  bottom: 10px;
  img {
    width: 12px;
    height: 12px;
  }
  label {
    font-family: Pretendard;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #767b84;
    padding-top: 2px;
  }
}
.box-store-hashtag {
  position: relative;
  &.has-store-hashtag {
    .item-box-comment {
      bottom: 3px;
    }
  }
  &.non-store-hashtag {
    .box-hasttag{
      display: none;
    }
    .item-box-comment {
      position: static;
      margin-top: 3px;
    }

    .box-hashtag-comment {
      justify-content: end;
    }
  }
  &.has-box-store {
    .item-box-comment {
      bottom: 10px;
    }
  }
  &.has-box-hashtag {
    .item-box-comment {
      bottom: 3px;
    }
  }
}
