.modal {
  z-index: 99999;

  &.guide-point-modal {
    .modal-content {
      &__iconClose-B {
        position: absolute;
        right: pxToVW(20);
        top: pxToVW(32);
      }
      .modal-title {
        width: 100%;
        font-family: NanumSquareEB;
        font-size: pxToVW(18);
        font-weight: normal;
        line-height: pxToVW(21);
        letter-spacing: normal;
        color: $black-light-3;
        padding: pxToVW(30) 0;
      }

      .modal-body {
        padding: 0 pxToVW(30) pxToVW(30);

        .content {
          text-align: left;

          p {
            margin-bottom: pxToVW(10);

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.point-correct {
    .modal-content {
      &__iconClose-B {
        position: absolute;
        right: pxToVW(20);
        top: pxToVW(32);
      }
      .modal-title {
        width: 100%;
        font-family: NanumSquareB;
        font-size: pxToVW(14);
        font-weight: normal;
        line-height: pxToVW(20);
        letter-spacing: normal;
        color: $black-light-3;
        padding: pxToVW(25) 0 pxToVW(20);
      }

      .modal-body {
        padding: 0 pxToVW(15) pxToVW(30) pxToVW(15);

        .content-point {
          background-color: $bg-stability;
          border-radius: pxToVW(10);
          padding: pxToVW(20);

          &__address {
            display: flex;
            justify-content: space-between;

            &::after {
              content: '';
              position: absolute;
              top: pxToVW(74);
              width: pxToVW(260);
              height: pxToVW(2);
              background-color: $white;
            }

            &__title {
              text-align: left;
              font-size: pxToVW(14);
              line-height: pxToVW(20);
              opacity: 0.8;

              p {
                margin-bottom: pxToVW(5);
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            &__information {
              text-align: right;

              p {
                &:first-child {
                  font-size: pxToVW(14);
                  line-height: pxToVW(16);
                  font-family: NanumSquareEB;
                  margin-bottom: pxToVW(7);
                }

                &:last-child {
                  font-size: pxToVW(14);
                  line-height: pxToVW(16);
                  font-family: Roboto;
                  font-weight: $bold;
                }
              }
            }
          }

          &__price {
            margin-top: pxToVW(25);
            display: flex;
            justify-content: space-between;

            p {
              &:first-child {
                font-size: pxToVW(16);
                line-height: pxToVW(20);
                font-family: NanumSquareB;
                font-weight: $medium;
                opacity: 0.8;
              }

              &:last-child {
                font-size: pxToVW(20);
                line-height: pxToVW(26);
                font-family: Roboto;
                font-weight: $bold;
                color: $governor-2;
              }
            }
          }
        }
      }
    }
  }

  &.index-9998 {
    z-index: 99998;
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 pxToVW(20);
    margin: 0;
    max-width: pxToVW(500);
  }
}

.modal-backdrop {
  &.show {
    opacity: 0.4;
  }

  z-index: 99999;
}

.modal-content {
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: $popup-txt;
  padding: 0;
  border: none;
  border-radius: 0;

  &__iconClose {
    position: absolute;
    right: pxToVW(0);
    top: pxToVW(-35);
    width: pxToVW(30);
    height: pxToVW(30);

    &:focus {
      outline: none;
    }

    img {
      width: pxToVW(30);
      height: pxToVW(30);

      &:focus {
        outline: none;
      }
    }
    .icon-close-pre{
      width: 21px;
      height: 21px;
    }
  }

  .modal-title {
    width: 100%;
    font-size: pxToVW(14);
    font-weight: $bold;
    line-height: pxToVW(20);
    letter-spacing: normal;
    color: $black-light-3;
    padding: pxToVW(20) pxToVW(0);
  }

  .modal-body {
    width: 100%;
    white-space: pre-line;
    padding: pxToVW(50) pxToVW(20) pxToVW(30) pxToVW(20);
    margin: 0;

    &__has-header {
      font-size: pxToVW(14);
      line-height: pxToVW(20);
      letter-spacing: pxToVW(-0.28);
      color: $popup-txt;

      .title-content {
        font-weight: $light;
      }

      .icon-character {
        width: pxToVW(140);
        height: pxToVW(100);
        margin-bottom: pxToVW(20);
      }

      .icon-character-success {
        width: pxToVW(250);
        height: pxToVW(155);
        margin-bottom: pxToVW(20);
      }
    }

    .content {
      font-size: pxToVW(13);
      font-weight: normal;
      letter-spacing: normal;
      text-align: center;
      font-size: pxToVW(14);
      line-height: pxToVW(20);
      color: $black-light-3;

      .title {
        margin-top: pxToVW(20);
        margin-bottom: pxToVW(15);
        font-size: pxToVW(23);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        font-weight: normal;

        span {
          font-weight: $black;
          position: relative;

          &::before {
            content: '';
            height: pxToVW(4);
            background-color: $orange-2;
            width: 100%;
            bottom: pxToVW(2);
            position: absolute;
            z-index: -1;
          }
        }
      }

      .text {
        line-height: pxToVW(20);
        font-size: pxToVW(14);
        font-weight: $bold;
      }

      &--login-success {
        img {
          margin-bottom: pxToVW(20);
          width: pxToVW(280);
          height: pxToVW(149);
        }

        p {
          // @include fontSize(#{pxToVW(20)}, $bold);
          font-size: pxToVW(20);
          line-height: pxToVW(27);
          letter-spacing: pxToVW(-0.71);
          color: $popup-txt;
          margin: 0;

          &.text-welcome {
            width: 100%;
            padding-bottom: pxToVW(14);
          }

          &.pointSuccess {
            width: pxToVW(190);
          }
        }

        h2 {
          font-size: pxToVW(25);
          line-height: pxToVW(27);
          // color: $btn-bg;
          font-weight: $bold;
        }

        h3 {
          // @include fontSize(#{pxToVW(13)}, $regular);
          line-height: pxToVW(18);
          letter-spacing: pxToVW(-0.46);
          color: $popup-txt;

          &.text-middle {
            padding-bottom: pxToVW(15);
          }
        }
      }
    }
  }

  .img-popup {
    width: pxToVW(140);
    height: pxToVW(90);
    margin-bottom: pxToVW(15);
  }

  .modal-footer {
    width: 100%;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: $white;
    border: none;
    border: 0;
    border-radius: 0;
    position: relative;
    z-index: 999;
    padding: 0;

    .group-button {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .button {
      font-size: pxToVW(14);
      height: pxToVW(46);
      padding: pxToVW(15);
      background-color: $orange-2;
      font-weight: $black;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $black-light-3;
      width: 100%;

      &.buttonConfirm {
        font-weight: $bold;
      }

      &.btn-left {
        background-color: #e6e6e6;
      }

      &.btn-right {
        color: $text-box-active;
      }
    }
  }
}

.product-popup {
  .modal-content .modal-body {
    padding-top: pxToVW(50);
    padding-bottom: pxToVW(30);
  }
}
.delete-comment-popup{
  .modal-content{
    border-radius: 20px;
    .modal-body{
      padding: 36px 20px;
      .content{
        h3{
          font-family: Pretendard;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #363b45;
          margin-bottom: 34px;
        }
      }
      .group-button-popup{
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Pretendard;
        
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        .button--half{
          border-radius: 10px;
          height: 56px;
        }
        .btn-left{
          font-size: 16px;
          background-color: #f5f6f8;
          margin-right: 11px;
        }
        .btn-right{
          font-size: 16px;
          background-color: #fed400;
        }
      }
    }
  }
}

.view-product-popup {
  .modal-content {
    background-color: #ffffff00;
    .modal-body {
      padding: 0;
  
      * {
        line-height: 0;
      }
  
      img {
        width: 100%;
        margin: 0;
      }
      .review-modal{
        
        .img-review{
          width: 100%;
          // height: 330px;
          margin: 0;
          border-radius: 10px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
        .btn-close-review-img{
          width: 100%;
          height: 57px;
          margin: 8px 0 0;
          border-radius: 10px;
          background-color: #fed400;
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #363b45;
        }
      }
    }
  }
}

.register-success {
  .modal-content .modal-body {
    padding-bottom: pxToVW(30);
  }
}

.modal-footer > * {
  margin: 0;
}

.modal-open {
  height: 100%;
  overflow: hidden;
  width: 100%;
  // position: fixed;
  position: static !important;
}

.modal-bottom {
  &__wrapper {
    overflow: hidden;
  }

  &__body-photo {
    .btn {
      justify-content: flex-start;
      margin-bottom: pxToVH(27);
      line-height: 0;
      padding: 0;
    }
  }
}

.modal__backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-color: $black-light-3;
  z-index: 9999;
  display: block;
  top: 0;
}

.modal-bottom__body {
  .content {
    background: $white;
    width: 100%;
    position: fixed;
    z-index: 999999;
    padding: 0;
    box-sizing: border-box;
    bottom: 0;
    transition: 1s;
    left: 0;

    .icon-ico_dismiss {
      z-index: 1;
    }

    .icon__wrapper {
      width: pxToVW(21);
      height: pxToVW(21);
      position: absolute;
      right: pxToVW(10);
      top: pxToVW(-31);
      z-index: 999999;
    }

    &__head {
      position: relative;
      padding: pxToVW(17) pxToVW(20) pxToVW(13) pxToVW(20);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: pxToVW(2) solid $bg-stability;

      .icon-closeX {
        position: relative;
        top: pxToVW(2);
      }

      * {
        font-size: pxToVW(14);
        font-weight: bold;
        line-height: 1.14;
      }
    }

    &__recent {
      overflow-x: auto;
      height: 100%;
      padding: pxToVW(30);
      height: pxToVW(250);

      &--mb-10 {
        padding-bottom: pxToVW(60);
      }

      &__list {
        &--status {
          li {
            &:nth-child(2) {
              h2 {
                color: $red;
              }

              &.active,
              &:active,
              &:focus {
                h2 {
                  color: $red;
                }
              }
            }

            &:nth-child(3) {
              h2 {
                color: $blue;
              }

              &.active,
              &:active,
              &:focus {
                h2 {
                  color: $blue;
                }
              }
            }

            &:nth-child(4) {
              h2 {
                color: $red;
              }

              &.active,
              &:active,
              &:focus {
                h2 {
                  color: $red;
                }
              }
            }

            &:nth-child(5) {
              h2 {
                color: $blue;
              }

              &.active,
              &:active,
              &:focus {
                h2 {
                  color: $blue;
                }
              }
            }

            &.active,
            &:active,
            &:focus {
              background-color: $bg-box;

              h2 {
                color: initial;
                font-weight: $bold;
              }
            }
          }
        }

        li {
          // height: pxToVW(48);
          padding: pxToVW(15) pxToVW(20);
          outline: none;
          padding: pxToVW(10);
          border-radius: pxToVW(10);
          background-color: $white;
          font-size: pxToVW(18);
          font-weight: bold;
          line-height: 1.17;
          letter-spacing: normal;
          opacity: 0.3;
          text-align: center;

          &:last-child {
            border-bottom: 0;
          }

          h2 {
            font-size: pxToVW(18);
            font-weight: bold;
            line-height: 1.17;
            letter-spacing: normal;
            opacity: 0.3;
            text-align: center;
          }

          &.active,
          &:active,
          &:focus {
            padding: pxToVW(10);
            background-color: rgba($wild-sand, 0.5);
            color: $governor-2;
            font-weight: $black;
            opacity: 1;

            h2 {
              color: $governor-2;
              font-weight: $black;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.modal-touch-bottom {
  &.overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 9999;
    background-color: rgba(14, 15, 17, 0.4);
  }
}

.touch-bottom {
  background-color: $white;
  padding: 35px;
  box-shadow: 0 pxToVW(-5) pxToVW(15) 0 rgba(0, 0, 0, 0.2);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: fixed;
  bottom: 0;
  // top: pxToVW(450);
  top: pxToVW(100);
  width: 100%;
  z-index: 99999999999;

  &::after {
    content: '';
    position: absolute;
    top: pxToVW(10);
    left: 50%;
    transform: translateX(-50%);
    background-color: #dfe2e6;
    width: 31px;
    height: 4px;
    border-radius: 3px;
  }

  // .touch-down{
  //   width: 100%;
  //   background: red;
  //   height: 30px;
  //   span{
  //     position: absolute;
  //       top: 2.77778vw;
  //       left: 50%;
  //       transform: translateX(-50%);
  //       background-color: #dfe2e6;
  //       width: 31px;
  //       height: 4px;
  //       border-radius: 3px;
  //   }
  // }

  .overlay-modal {
    position: fixed;
    top: calc(100vh - #{pxToVW(150)});
    left: 0;
    z-index: 100001;
    width: 100%;
    height: pxToVW(380);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &.back {
    animation: touchDownModal 0.6s ease-in-out both;

    @keyframes touchDownModal {
      0% {
        top: calc(100vh - #{pxToVW(150)});
      }

      100% {
        top: pxToVW(1000);
      }
    }
  }

  &.touchend {
    animation: touchUpModal 0.6s ease-in-out both;

    @keyframes touchUpModal {
      0% {
        top: pxToVW(1000);
      }

      100% {
        top: calc(100vh - #{pxToVW(150)});
      }
    }
  }

  .content{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    .item{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #363b45;
      padding: 14px 0px;
      &.active{
        font-weight: bold;
        font-family: Pretendard;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: #fdd300;
      }
    }
  }

  .text-desc {
    font-size: pxToVW(14);
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    // margin-bottom: pxToVW(15);
    color: $black-light-3;
    text-align: center;
  }
}
.filter_store{
  &.back {
    animation: touchDownFilterModal 0.6s ease-in-out both;

    @keyframes touchDownFilterModal {
      0% {
        top: calc(100vh - #{pxToVW(200)});
      }

      100% {
        top: pxToVW(1000);
      }
    }
  }

  &.touchend {
    animation: touchUpFilterModal 0.6s ease-in-out both;

    @keyframes touchUpFilterModal {
      0% {
        top: pxToVW(1000);
      }

      100% {
        top: calc(100vh - #{pxToVW(200)});
      }
    }
  }
}