.wrap-radio-cus {
  width: 30px;
  height: 30px;
  padding: 11px 10px 12px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #dfe2e6;
  .status-check {
    display: inline-block;
    transform: rotate(45deg);
    height: 12px;
    width: 6px;
    margin-left: 3px;
    margin-bottom: 3px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    //display: none;
  }
  &.checked {
    background-color: #fed503;
    .status-check {
      //display: block;
      border-bottom: 2px solid #000;
      border-right: 2px solid #000;
    }
  }
}