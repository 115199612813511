.layout-main {
  background-color: $white;
  padding: pxToVW(20);
  min-height: 86vh;
  position: relative;

  &.min-height {
    min-height: 100vh;
  }

  &.pt-48 {
    padding-top: pxToVW(48);
  }
}

.mb-80 {
  margin-bottom: pxToVW(80) !important;
}

.btn-notice {
  width: 120px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fed400;

  right: 20px;
  position: fixed;
  z-index: 999;
  bottom: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .contact{
    display: flex;
    justify-content: center;
    align-items: center;
    .img-contact{
      width: 18px;
    }
    .text-contact{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #363b45;
      margin-left: 7px;
    }
  }
}

.hidden-footer {
  footer.footer,
  .btn-notice {
    display: none;
    opacity: 0;
  }
}
