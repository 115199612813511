.intro {
  @media (min-width: 600px) {
    background-image: url('../../assets/images/intro-ipad-pro.gif') !important;
  }

  background-color: $orange-2;
  height: 100%;
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
  background-color: #fed400;

  img {
    width: 100%;
  }

  .logo-intro {
    max-width: pxToVW(200);
    margin: 0 auto 0 auto;
    padding: pxToVW(130) 0 pxToVW(110) 0;
  }

  .intro-character {
    position: absolute;
    bottom: pxToVW(-3);
    right: 0;
    max-width: pxToVW(336);
  }
}