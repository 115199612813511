.event-page{
  padding: 10px 20px 20px 20px;
  margin-top: 51px;
  .filter-form{
    display: flex;
    justify-content: left;
    align-items: center;
    .btn-filter{
      width: 60px;
      height: 34px;
      border-radius: 8px;
      border: solid 1px #dfe2e6;
      background-color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      span{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #8f949c;
      }
      &.active{
        border: solid 1px #fed400;
        background-color: #fffbe2;
        span{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #d4b100;
        }
      }
    }
  }
  &__content{
    margin-top: 27px;
    &__event-item{
      margin-bottom: 34px;
      div{
        .border-img{
          .react-loading-skeleton{
            border-radius: 20px;
          }
        }
      }
      .box-img{
        position: relative;
        width: 100%;
        height: 197px;
        .event-img{
          width: 100%;
          height: 100%;
          // object-fit: contain;
        }
        .marker{
          position: absolute;
          width: 100%;
          height: 197px;
          background-color: #333;
          border-radius: 20px;
          top: 0px;
          left: 0px;
          opacity: 0.7;
          z-index: 1000;
        }
        .text{
          font-family: Pretendard;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1001;
        }
      }
      .note{
        margin-top: 14px;
        .event-name{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.32px;
          text-align: left;
          color: #0e0f11;
        }
        .event-note{
          margin-top: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .event-time-title{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            text-align: left;
            color: #363b45;
          }
          .event-space{
            width: 0;
            height: 12px;
            margin: 0px 8px;
            border: solid 1px #dfe2e6;
          }
          .event-time{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.14;
            letter-spacing: -0.28px;
            text-align: left;
            color: #363b45;
            &.space{
              padding: 0px 3px;
            }
          }
        }
      }
    }
  }
}
.event-detail-page{
  padding: 0px;
  margin-top: 51px;
  
  &__content{
    margin-top: 27px;
    &__event-item{
      // padding-bottom: 10px;

      
      .content-top{
        padding-bottom: 0px;
        background-color: #fbca6c;
        .content-lottery{
          border-radius: 10px;
          background-color: #fe7e3c;
          margin: 0px 20px 20px 20px;
          padding: 26px 20px;
          .lottery-number-title{
            font-family: Pretendard;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.18;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            margin-bottom: 16px;
          }
          .number-box{
            border-radius: 10px;
            background-color: #e95c14;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            span.number{
              font-family: Pretendard;
              font-size: 36px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: center;
              color: #fff;
            }
          }
          .event-detail-page__button-tickets{
            bottom: 20px;
            width: 100%;
          }
        }
      }
      .no-background{
        background-color: #fff;
      }
      .content-bottom{
        background-color: #e6e8ec;
        // padding-bottom: 10px;
        .content-text{
          padding: 35px 20px;
          p{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.32px;
            text-align: left;
            color: #8f949c;
            margin-bottom: 14px;
          }
          label{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.28px;
            text-align: left;
            line-height: 1.43;
            color: #8f949c;
            margin-bottom: 10px;
            span{
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 500;
              padding-left: 9px;
              line-height: 1.43;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: -0.28px;
              text-align: left;
              color: #8f949c;
            }
          }
        }
      }
      .note{
        margin-top: 14px;
        .event-name{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          text-align: left;
          color: #0e0f11;
        }
        .event-note{
          margin-top: 13px;
          .event-time-title{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            text-align: left;
            color: #363b45;
          }
          .event-space{
            width: 0;
            height: 12px;
            margin: 0px 8px;
            border: solid 1px #dfe2e6;
          }
          .event-time{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            text-align: left;
            color: #363b45;
          }
        }
      }
      .hashtag{
        position: relative;

      }
      .hashtag-icon{
        position: absolute;
        top: 10.0222307%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 36px;
        height: 38px;
        .icon{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__button-tickets{
    .button-give-ticket{
      border-radius: 20px;
    }
  }
}
.wait-ticket-popup{
  .modal-content{
    border-radius: 20px;
    min-height: 337px;
    .modal-body{
      padding-top: 10px;
    }
  }
}
.give-ticket-popup{
  .modal-content{
    border-radius: 20px;
    min-height: 337px;
    .modal-body{
      .content{
        .content-head{
          .ticket-number{
            h2{
              font-family: Pretendard;
              font-size: 38px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.21;
              letter-spacing: -0.76px;
              text-align: center;
              color: #363b45;
            }
          }
          .ticket-decription{
            margin-top: 16px;
            p{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: -0.32px;
              text-align: center;
              color: #363b45;
              span.text-focus{
                font-weight: bold;
              }
            }  
          }
        }
        .group-button-popup{
          margin-top: 39px;
          .button-give-ticket{
            border-radius: 10px;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #363b45;
          }
        }
      }
    }
  }
}
.no-lottery-popup{
  .modal-content{
    border-radius: 20px;
    .modal-body{
      padding-top: 36px;
      .content{
        .content-no-lottery{
          .note{
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            text-align: center;
            color: #363b45;
            label{
              font-family: Pretendard;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              text-align: center;
              color: #363b45;
            }
          }
          .button-close{
            margin-top: 34px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}