.input {

  &__wrapper {
    .input__label {
      font-size: pxToVW(14);
      font-weight: $black;
      line-height: 1.4;
      color: $black-light-3;
      margin-bottom: pxToVW(10);
      display: flex;
    }

    .input__box {
      position: relative;
      display: block;

      &.left {
        .input {
          padding-left: pxToVW(30);
        }
      }

      input {
        border: pxToVW(1) solid $gallery;
        height: pxToVW(36);
        display: block;
        font-size: pxToVW(16);
        padding: pxToVW(10);
        color: $black-light-3;
        letter-spacing: pxToVW(-0.28);
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.21;
        font-weight: bold;
        position: relative;
        border-radius: pxToVW(5);
        padding-left: pxToVW(15);

        &[type="password"] {
          font-family: 'Noto Sans KR';
          font-weight: bold;
        }

        &.no-value {
          background-color: $border-button;
          opacity: 0.5;
        }

        &::-webkit-input-placeholder {
          color: $black-light-3;
          font-weight: $regular;
          font-size: pxToVW(14);
          line-height: pxToVW(20);
          letter-spacing: pxToVW(-0.38);
          opacity: 0.5;
        }

        &::placeholder {
          color: $black-light-3;
          font-weight: $regular;
          font-size: pxToVW(14);
          line-height: pxToVW(20);
          letter-spacing: pxToVW(-0.38);
          opacity: 0.5;
        }

        &::-moz-placeholder {
          color: $black-light-3;
          font-weight: $regular;
          font-size: pxToVW(14);
          line-height: pxToVW(20);
          letter-spacing: pxToVW(-0.38);
          opacity: 0.5;
        }

        &:-ms-input-placeholder {
          color: $black-light-3;
          font-weight: $regular;
          font-size: pxToVW(14);
          line-height: pxToVW(20);
          letter-spacing: pxToVW(-0.38);
          opacity: 0.5;
        }

        &:focus {
          border: pxToVW(1) solid $blue-2;
          outline: none;
        }
      }

      img {
        position: absolute;
        width: pxToVW(20);
        height: pxToVW(20);
        right: 0;
        top: pxToVW(10);
      }
    }
  }
}

input,
textarea {
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}