.notice-page {
  padding-top: pxToVW(20);

  .item-notice {
    border-bottom: pxToVW(2) solid $bg-stability;
    margin-bottom: pxToVW(20);
    padding-bottom: pxToVW(20);
    cursor: pointer;

    .time {
      opacity: 0.5;
      font-size: pxToVW(11);
      font-weight: normal;
      line-height: 1.09;
      margin-bottom: pxToVW(3);
    }

    .title {
      font-size: pxToVW(14);
      font-weight: $black;
      line-height: 1.4;
      letter-spacing: normal;

      span {
        margin: 0 pxToVW(4);
        font-weight: 700;

        &.blue {
          color: $color-reduction !important;
        }

        &.red {
          color: $text-box-active !important;
        }

        &.yellow {
          color: $yellow !important;
        }

        &.other {
          color: $governor-2 !important;
        }
      }

    }
  }
}