.body-overflow {
  overflow: hidden;
}

.level-page {
  background-color: $color-box1;
  min-height: 100vh;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0 !important;
  padding-bottom: 0;

  >.h-100 {
    min-height: 90vh;
    overflow: hidden;
  }

  &.level2 {
    background-color: $color-box1;

    .app-sub-header * {
      background-color: $color-box1 !important;
    }
  }

  &.level3 {
    background-color: $bg-box-overlay;

    .app-sub-header * {
      background-color: $bg-box-overlay !important;
    }
  }

  &.level4 {
    background-color: $bg-button-primary;

    .app-sub-header * {
      background-color: $bg-button-primary !important;
    }


  }

  &.level5 {
    background-color: $orange-2;

    .app-sub-header * {
      background-color: $orange-2 !important;
    }

    .level-header {
      .icon-level {
        margin-top: 0;

        div[aria-label="animation"] {
          width: pxToRem(210) !important;
        }

        .sub-level {
          bottom: pxToVW(50);
        }
      }
    }
  }

  .level-header {
    margin-top: pxToVW(20);
    padding-left: pxToVW(20);
    padding-right: pxToVW(20);

    .level-title {
      font-size: pxToVW(14);
      font-weight: $black;
      line-height: 1.14;
      letter-spacing: normal;
      margin-bottom: pxToVW(5);
    }

    .level-desc {
      font-size: pxToVW(11);
      font-weight: normal;
      line-height: 1.09;
      letter-spacing: normal;
    }

    .icon-level {
      text-align: center;
      margin-top: pxToVW(-40);
      position: relative;

      div[aria-label="animation"] {
        width: 80% !important;
        position: relative;
        z-index: 9;
      }

      .level {
        width: pxToVW(185);
        position: relative;
        z-index: 2;
      }

      .sub-level {
        position: absolute;
        left: 0;
        bottom: pxToVW(28);
        z-index: 1;
        width: 100%;
      }
    }

    .name-level {
      width: pxToVW(74);
      margin: pxToVW(10) auto 0 auto;
    }

    .processing-level {
      padding: 0 pxToVW(20);
      margin-bottom: pxToVW(30);

      .progress {
        height: pxToVW(10);
        border-radius: pxToVW(5);
        background-color: $white;

        .progress-bar {
          border-radius: pxToVW(5);
          background-color: $governor-2;
        }
      }

      .progress-unit {
        margin-top: pxToVW(5);
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          opacity: 0.7;
          font-family: $Roboto;
          font-size: pxToVW(12);
          font-weight: 500;
          line-height: 1.17;
          color: $color-black;

          &.opacity {
            opacity: 1;

            span {
              opacity: 1;
            }
          }

          span {
            font-family: $NanumSquare-Font;
            font-size: pxToVW(11);
          }
        }
      }
    }
  }

  .level-list {
    background-color: $white;
    padding: pxToVW(40) pxToVW(30) pxToVW(40) pxToVW(30);
    box-shadow: 0 pxToVW(-5) pxToVW(15) 0 rgba(0, 0, 0, 0.2);
    border-top-left-radius: pxToVW(30);
    border-top-right-radius: pxToVW(30);
    position: fixed;
    bottom: 0;
    top: pxToVW(450);
    width: 100%;
    z-index: 99;

    &.back {
      animation: touchDown 0.6s ease-in-out both;

      @keyframes touchDown {
        0% {
          top: pxToVW(60);
        }

        100% {
          top: pxToVW(450);
        }
      }
    }

    &.touchend {

      animation: touchUp 0.6s ease-in-out both;

      @keyframes touchUp {
        0% {
          top: pxToVW(450);
        }

        100% {
          top: pxToVW(60);
        }
      }
    }

    .level-item {
      background-color: $white;
      display: flex;
      align-items: center;
      padding: pxToVW(8) pxToVW(15);
      border-radius: pxToVW(10);
      box-shadow: 0 pxToVW(3) pxToVW(6) 0 rgba(0, 0, 0, 0.16);
      margin-bottom: pxToVW(11);

      img {
        width: pxToVW(60);
        height: pxToVW(60);
        margin-right: pxToVW(10);
      }

      .head-level {
        display: flex;
        align-items: center;
        margin-bottom: pxToVW(5);

        .name {
          font-size: pxToVW(9);
          font-weight: $black;
          line-height: 1.11;
          letter-spacing: pxToVW(-0.27);
          text-align: center;
          min-width: pxToVW(26);
          height: pxToVW(12);
          padding: pxToVW(1) pxToVW(4) pxToVW(2) pxToVW(4);
          border-radius: pxToVW(10);
          background-color: $color-black;
          margin-right: pxToVW(5);
          color: $white;
        }

        .name-ko {
          font-size: pxToVW(14);
          font-weight: $black;
          line-height: 1.14;
        }
      }

      .desc-level {
        font-size: pxToVW(11);
        font-weight: normal;
        line-height: pxToVW(15);
        text-align: left;
      }
    }
  }
}