.toggle-switch {
  position: relative;
  width: pxToVW(54);
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  top: 0;
  right: 0;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: pxToVW(20);
  margin: 0;
  box-shadow: 0 0 pxToVW(5) 0 rgba(0, 0, 0, 0.3);
}

.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  @include transition(0.3s ease-in 0s);

  &::before,
  &::after {
    display: block;
    float: left;
    width: 50%;
    height: pxToVW(24);
    padding: 0;
    line-height: pxToVW(24);
    font-size: pxToVW(10);

    padding: 0 pxToVW(6) 0 pxToVW(6);
    border-radius: pxToVW(15);
    box-shadow: 0 0 pxToVW(5) 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    color: $white;
    box-sizing: border-box;
    font-family: 'NanumSquare',
      sans-serif;
    text-transform: uppercase;
    letter-spacing: pxToVW(-0.25);
  }

  &:before {
    content: "알림 ON";
    background-color: $blue-2;
    color: $white;
  }
}

.toggle-switch-disabled {
  background-color: $hummer;
  cursor: not-allowed;

  &::before {
    background-color: $hummer;
    cursor: not-allowed;
  }
}

.toggle-switch-inner::after {
  content: "알림 OFF";
  background-color: rgba($color_black, 0.3);
  color: $white;
  text-align: center;
}

.toggle-switch-switch {
  display: block;
  width: pxToVW(13);
  margin: pxToVW(4);
  background: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: 0 solid $hummer;
  border-radius: pxToVW(20);
  @include transition(0.5s);
}

.toggle-switch-checkbox:checked+.toggle-switch-label {
  .toggle-switch-inner {
    margin-left: 0;
  }

  .toggle-switch-switch {
    right: 0;
    left: auto;
  }
}