/* noto-sans-kr-100 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-100.eot');
  /* IE9 Compat Modes */
  src: local('Noto Sans KR Thin'), local('NotoSansKR-Thin'),
    url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-100.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-100.woff')
      format('woff'),
    url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-100.svg#NotoSansKR')
      format('svg');
  /* Legacy iOS */
}

/* noto-sans-kr-300 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-300.eot');
  /* IE9 Compat Modes */
  src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
    url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-300.svg#NotoSansKR')
      format('svg');
  /* Legacy iOS */
}

/* noto-sans-kr-regular - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-regular.eot');
  /* IE9 Compat Modes */
  src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
    url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-regular.svg#NotoSansKR')
      format('svg');
  /* Legacy iOS */
}

/* noto-sans-kr-500 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-500.eot');
  /* IE9 Compat Modes */
  src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
    url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-500.svg#NotoSansKR')
      format('svg');
  /* Legacy iOS */
}

/* noto-sans-kr-700 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-700.eot');
  /* IE9 Compat Modes */
  src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
    url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-700.svg#NotoSansKR')
      format('svg');
  /* Legacy iOS */
}

/* noto-sans-kr-900 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-900.eot');
  /* IE9 Compat Modes */
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
    url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-900.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/NotoSans/noto-sans-kr-v8-korean-900.svg#NotoSansKR')
      format('svg');
  /* Legacy iOS */
}

/* NanumSquare-200 */
@font-face {
  font-family: 'NanumSquare';
  font-weight: 200;
  src: url('../assets/fonts/NanumSquare/NanumSquareL.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/NanumSquare/NanumSquareL.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/NanumSquare/NanumSquareL.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/NanumSquare/NanumSquareL.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

/* NanumSquare-400 */
@font-face {
  font-family: 'NanumSquare';
  font-weight: 400;
  src: url('../assets/fonts/NanumSquare/NanumSquareR.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/NanumSquare/NanumSquareR.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/NanumSquare/NanumSquareR.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/NanumSquare/NanumSquareR.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

/* NanumSquare-700 */
@font-face {
  font-family: 'NanumSquare';
  font-weight: 700;
  src: url('../assets/fonts/NanumSquare/NanumSquareB.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/NanumSquare/NanumSquareB.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/NanumSquare/NanumSquareB.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/NanumSquare/NanumSquareB.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NanumSquareB';
  font-weight: 700;
  src: url('../assets/fonts/NanumSquare/NanumSquareB.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/NanumSquare/NanumSquareB.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/NanumSquare/NanumSquareB.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/NanumSquare/NanumSquareB.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

/* NanumSquare-900 */
@font-face {
  font-family: 'NanumSquare';
  font-weight: 900;
  src: url('../assets/fonts/NanumSquare/NanumSquareEB.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/NanumSquare/NanumSquareEB.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/NanumSquare/NanumSquareEB.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/NanumSquare/NanumSquareEB.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NanumSquareEB';
  font-weight: 900;
  src: url('../assets/fonts/NanumSquare/NanumSquareEB.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/NanumSquare/NanumSquareEB.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/NanumSquare/NanumSquareEB.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/NanumSquare/NanumSquareEB.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

// /* Roboto-100 */
@font-face {
  unicode-range: U+02C6- U+0323;
  font-family: 'Roboto';
  font-weight: 100;
  src: url('../assets/fonts/Roboto/roboto-thin-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-thin-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-thin-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-thin-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-thin-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/Roboto/roboto-thin-webfont.svg#Roboto') format('svg');
  font-style: normal;
}

// /* Roboto-300 */
@font-face {
  unicode-range: U+02C6- U+0323;
  font-family: 'Roboto';
  font-weight: 300;
  src: url('../assets/fonts/Roboto/roboto-light-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-light-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-light-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-light-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/Roboto/roboto-light-webfont.svg#Roboto')
      format('svg');
  font-style: normal;
}

// /* Roboto-400 */
@font-face {
  unicode-range: U+02C6- U+0323;
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../assets/fonts/Roboto/roboto-regular-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-regular-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-regular-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/Roboto/roboto-regular-webfont.svg#Roboto')
      format('svg');
  font-style: normal;
}

// /* Roboto-500 */
@font-face {
  unicode-range: U+02C6- U+0323;
  font-family: 'Roboto';
  font-weight: 500;
  src: url('../assets/fonts/Roboto/roboto-medium-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-medium-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-medium-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-medium-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/Roboto/roboto-medium-webfont.svg#Roboto')
      format('svg');
  font-style: normal;
}

// /* Roboto-700 */
@font-face {
  unicode-range: U+02C6- U+0323;
  font-family: 'Roboto';
  font-weight: 700;
  src: url('../assets/fonts/Roboto/roboto-bold-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-bold-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-bold-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/Roboto/roboto-bold-webfont.svg#Roboto') format('svg');
  font-style: normal;
}

// /* Roboto-900 */
@font-face {
  unicode-range: U+02C6- U+0323;
  font-family: 'Roboto';
  font-weight: 900;
  src: url('../assets/fonts/Roboto/roboto-black-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-black-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-black-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-black-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-black-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/Roboto/roboto-black-webfont.svg#Roboto')
      format('svg');
  font-style: normal;
}

//Pretendard Regular
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard/Pretendard-Regular.eot');
  src: url('../assets/fonts/Pretendard/Pretendard-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Pretendard/Pretendard-Regular.woff2') format('woff2'),
  url('../assets/fonts/Pretendard/Pretendard-Regular.woff') format('woff'),
  url('../assets/fonts/Pretendard/Pretendard-Regular.ttf') format('truetype'),
  url('../assets/fonts/Pretendard/Pretendard-Regular.svg#Pretendard-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard/Pretendard-SemiBold.eot');
  src: url('../assets/fonts/Pretendard/Pretendard-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Pretendard/Pretendard-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/Pretendard/Pretendard-SemiBold.woff') format('woff'),
  url('../assets/fonts/Pretendard/Pretendard-SemiBold.ttf') format('truetype'),
  url('../assets/fonts/Pretendard/Pretendard-SemiBold.svg#Pretendard-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard/Pretendard-Medium.eot');
  src: url('../assets/fonts/Pretendard/Pretendard-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Pretendard/Pretendard-Medium.woff2') format('woff2'),
  url('../assets/fonts/Pretendard/Pretendard-Medium.woff') format('woff'),
  url('../assets/fonts/Pretendard/Pretendard-Medium.ttf') format('truetype'),
  url('../assets/fonts/Pretendard/Pretendard-Medium.svg#Pretendard-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard/Pretendard-Bold.eot');
  src: url('../assets/fonts/Pretendard/Pretendard-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Pretendard/Pretendard-Bold.woff2') format('woff2'),
  url('../assets/fonts/Pretendard/Pretendard-Bold.woff') format('woff'),
  url('../assets/fonts/Pretendard/Pretendard-Bold.ttf') format('truetype'),
  url('../assets/fonts/Pretendard/Pretendard-Bold.svg#Pretendard-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard/Pretendard-Light.eot');
  src: url('../assets/fonts/Pretendard/Pretendard-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Pretendard/Pretendard-Light.woff2') format('woff2'),
  url('../assets/fonts/Pretendard/Pretendard-Light.woff') format('woff'),
  url('../assets/fonts/Pretendard/Pretendard-Light.ttf') format('truetype'),
  url('../assets/fonts/Pretendard/Pretendard-Light.svg#Pretendard-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
