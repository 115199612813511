.point-page {
  margin-top: pxToVW(20);

  .header-box {
    padding: pxToVW(20);
    border-radius: pxToVW(15);
    box-shadow: 0 pxToVW(3) pxToVW(20) 0 rgba(88, 21, 165, 0.25);
    background-color: $governor-2;
    height: pxToVW(185);
    margin-bottom: pxToVW(40);

    .cart-info {
      margin-bottom: pxToVW(25);

      .cart-title {
        font-size: pxToVW(12);
        font-weight: 600;
        line-height: 1.08;
        color: $white;
        margin-bottom: pxToVW(5);
      }

      .cart-number {
        font-size: pxToVW(12);
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        opacity: 0.8;
        letter-spacing: normal;
        color: $white;
      }
    }

    .point-number {
      font-family: $Roboto;
      font-size: pxToVW(50);
      font-weight: 900;
      line-height: 1.22;
      letter-spacing: normal;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-left: pxToVW(5);
        font-size: pxToVW(20);
        font-weight: $black;
        line-height: 1.2;
        color: $white;
        font-family: $Roboto;
      }
    }
  }

  .list-point {
    padding: 0 pxToVW(10);
    .title {
      font-size: pxToVW(14);
      font-weight: $black;
      line-height: 1.14;
      text-align: left;
      color: $black-light-3;
      margin-bottom: pxToVW(15);
    }

    ul {
      li {
        height: pxToVW(46);
        padding: pxToVW(13) pxToVW(20);
        border-radius: pxToVW(10);
        background-color: rgba($border-button , 0.5);
        margin-bottom: pxToVW(10);
        display: flex;
        align-items: center;

        .dot {
          margin-right: pxToVW(10);
          opacity: 1;
          img {
            width: pxToVW(20);
            height: pxToVW(20);
          }
        }

        div {
          font-family: $Roboto;
          font-size: pxToVW(18);
          font-weight: 900;
          line-height: 1.22;
          opacity: 0.5;
          display: flex;
          align-items: center;

          span {
            font-size: pxToVW(14);
            font-weight: normal;
            line-height: 1.14;
            font-family: $NanumSquare-Font;
          }
        }
        &:last-child{
          div{
            opacity: 1;
          }
        }

        .input__wrapper {
          width: 100%;

          * {
            width: 100%;
            border: 0;
            background-color: transparent;
            font-family: $Roboto;
            font-size: pxToVW(18);
            font-weight: 900;
            line-height: 1.22;
            display: flex;
            align-items: center;
            color: $governor-2;
            padding-left: 0;
          }
        }

        &.active {
          background-color: $wild-sand;

          div {
            color: $governor-2;
            opacity: 1;

            span {
              color: $governor-2;
            }
          }
        }
      }
    }
  }
}

.point-success-page {
  margin-top: pxToVW(40);

  .img-register-success {
    width: pxToVW(300);
    height: pxToVW(202);
    margin: auto;
    div[aria-label='animation'] {
      width: pxToVW(340) !important;
      position: relative;
      left: pxToVW(-15);
      height: pxToVW(175);
    }

    img {
      width: 100%;
    }
  }

  .content {
    font-size: pxToVW(13);
    font-weight: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: pxToVW(14);
    line-height: 1.14;
    color: $black-light-3;

    .title {
      margin-top: pxToVW(20);
      margin-bottom: pxToVW(37);
      font-size: pxToVW(23);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      font-weight: normal;

      span {
        font-weight: $black;
        position: relative;
        z-index: 2;

        &::before {
          content: "";
          height: pxToVW(4);
          background-color: $orange-2;
          width: 100%;
          bottom: pxToVW(2);
          position: absolute;
          z-index: -1;
        }
      }
    }
  }

  .content-point {

    // padding: 0 pxToVW(10);

    ul {
      padding-bottom: pxToVW(12);
      margin-bottom: pxToVW(15);
      border-bottom: pxToVW(2) solid $bg-stability;
      padding-left: pxToVW(10);
      padding-right: pxToVW(10);
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: pxToVW(9) 0;

      .name {
        opacity: 0.8;
        font-size: pxToVW(16);
        font-weight: normal;
        line-height: 1.25;
      }

      .point {
        font-family: $Roboto;
        font-weight: 500;
        opacity: 0.5;
        font-size: pxToVW(18);

        &.new {
          opacity: 1;
          font-weight: $black;
          line-height: 1.17;
        }
      }
    }

    .total-point {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: pxToVW(7) 0;
      padding-left: pxToVW(10);
      padding-right: pxToVW(10);
      .name {
        opacity: 0.8;
        font-size: pxToVW(18);
        font-weight: $black;
        line-height: 1.25;
      }

      .point {
        font-family: $Roboto;
        font-weight: 500;
        font-size: pxToVW(23);
        font-weight: $black;
        line-height: 1.17;
      }
    }
  }
}