.wrapper-page-term-policy{
  .title{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 10px;
  }
  .content{
    // padding-right: 20px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    // text-align: justify;
    color: #767b84;
    margin-bottom: 30px;
    white-space: pre-line;
    word-break: keep-all;
    span{
      // font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #767b84;
    }
    p{
      font-family: Pretendard;
      color: #767b84;
    }
  }
  table{
    thead{
      tr{
        th{
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
  th {
    white-space: nowrap;

  }
  tr, th , td{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #767b84;
    white-space: nowrap;
    padding: 8px;
  }
}
.wrapper-page-privacy-policy{
  ul{
    li{
      font-family: Pretendard;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: .7rem;
      margin-bottom: 10px;
      a{
        font-family: Pretendard;
        font-size: .7rem;
        border-bottom: 1px solid #ccc;
        &.active{
          font-weight: bold;
        }
      }
    }
  }
  .content-policy{
    h1{
      font-family: Pretendard;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      margin: 20px auto;
    }
    div{
      font-size: 12px;
      // padding-right: 20px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      // text-align: justify;
      color: #767b84;
      margin-bottom: 30px;
      white-space: pre-line;
      word-break: keep-all;
      div{
        h2{
          font-family: Pretendard;
        }
      }
      span{
        // font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #767b84;
      }
      p{
        font-family: Pretendard;
        color: #767b84;
      }
    }
    div{
      h2{
        font-family: Pretendard;
      }
    }
  }

  table{
    thead{
      tr{
        th{
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
  th {
    white-space: nowrap;

  }
  tr, th , td{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #767b84;
    white-space: nowrap;
    padding: 8px;
  }
}


.wrapper-page-web-policy{
  ul{
    li{
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: .7rem;
      margin-bottom: 10px;
      a{
        font-family: Pretendard;
        font-size: .7rem;
        border-bottom: 1px solid #ccc;
        &.active{
          font-weight: bold;
        }
      }
    }
  }
  .content-policy{
    margin-top: 20px;
    font-size: 16px;
    font-family: Pretendard;
    // font-weight: 400;
    line-height: 23px;
    margin-bottom: 20px;
    h1{
      font-family: Pretendard;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      margin: 20px auto;
      padding-bottom: 10px;
    }
    .title{
      text-indent: 20px;
      font-family: Pretendard;
      // font-weight: 400;
      color: #111111;
    }
    .guide{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #767b84;
      margin-bottom: 30px;
      white-space: pre-line;
      word-break: keep-all;
    }
    .content{
      text-indent: 20px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #767b84;
      margin-bottom: 30px;
      white-space: pre-line;
      word-break: keep-all;
      margin-bottom: 10px;
      a{
        font-family: Pretendard;
        // text-decoration: underline;
        color: #0000ee;
        &:hover{
          cursor: pointer;
        }
      }
      p{
        font-family: Pretendard;
        color: #767b84;
      }
    }
    .warning{
      font-family: Pretendard;
      font-weight: 600;
    }
    .img{
      display: flex;
      justify-content: center;
      margin: 20px 0px;
      img{
        width: 60vw;
        max-width: 400px;
      }
    }
    div{
      font-family: Pretendard;
    }
  }

  table{
    thead{
      tr{
        th{
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
  th {
    white-space: nowrap;

  }
  tr, th , td{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #767b84;
    white-space: nowrap;
    padding: 8px;
  }
}