.main-playground-form {
  .wrapper-header {
    .app-sub-header {
      button {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
.playground-form {
  font-family: Pretendard;

   .page-playground {
     //margin-bottom: pxToVW(54);
     .overflow-auto {
       &::-webkit-scrollbar {
         display: none;
       }
     }
     textarea {
       &::-webkit-scrollbar {
         display: none;
       }
     }
     .group-item-hashtag {
       padding-top: 27px;
        .group-box-hastag {
          position: relative;
          &::before {
            content: "#";
            position: absolute;
            left: 29px;
            top: 51%;
            transform: translateY(-50%);
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.35px;
            text-align: left;
            color: #363b45;
            display: block;
            z-index: 999;
          }
          input {
            padding-left: 20px;
          }
        }
        input {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: -0.35px;
          text-align: left;
          position: relative;
          padding-left: 20px;
          &:disabled {
            background-color: #efefef;
          }
          &:focus {

          }


        }

        label {
          display: flex;
          align-items: center;
          line-height: 1;
          margin-bottom: 13px;
        }
       .text-muted {
          padding-left: 6px;
       }
     }
   }

  .app-sub-header {
    padding-left: pxToVW(20);
    padding-right: pxToVW(20);
    background-color: #fff;
  }

  button {
    font-size: pxToVW(16);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: -0.4px;
    text-align: right;
    color: #0e0f11;
  }
  
  label {
    font-size: pxToVW(16);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #363b45;

    .text-muted {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: -0.3px;
      text-align: left;
      color: #8f949c;
    }
  }

  input {
    height: pxToVW(46)
  }

  input, textarea {
    font-family: Pretendard;
    border-radius: pxToVW(10);
    border: solid 1px #efefef;

    &::placeholder {
      color: #c2c9d5;
    }
  }

  .required {
    &:after {
      content: '*';
      font-size: pxToVW(16);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      color: #ff4e4e;
      margin-left: pxToVW(2);
    }
  }

  .row {
    padding-top: pxToVW(16);

    &.border-bottom {
      padding-bottom: pxToVW(25);
    }
  }

  .tags {

    .tag-item {
      margin: pxToVW(10) 8px 0 0;
      padding: 6px 8px;
      border-radius: 13px;
      background-color: #f5f6f8;
      display: inline-block;
      max-width: 100%;

      .tag-label {
        font-family: Pretendard;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: left;
        color: #767b84;
        word-break: break-word;
      }

      .tag-remove {
        padding-left: 6px
      }
    }
  }

  .image-select {
    .overflow-auto {
      margin-left: pxToVW(-20);
      margin-right: pxToVW(-20);
    }
    
    .select-img {
      margin-top: 8px;
      margin-right: 12px;
      margin-left: pxToVW(20);
      // width: pxToVW(72);
      // min-width: pxToVW(72);
      // height: pxToVW(72);
      border-radius: 8px;
      // border: solid 1px #dfe2e6;
      background-color: #f5f6f8;
      // margin-top: 8px;
      // margin-right: 12px;
      position: relative;

      // display: flex;
      // justify-content: center;
      // align-items: center;
      .icon {
        position: absolute;
        top: 28px;
        left: 26px;
      }
    }

    .img-preview {
      margin-top: 8px;
      margin-right: 12px;

      &:last-child {
        margin-right: pxToVW(20);
      }

      .remove-image {
        position: absolute;
        top: -8px;
        right: -8px;
      }
    }
  }

  .images-count {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // height: pxToVW(54);
    // width: 100%;
    // border-top: 1px solid #dee2e6!important;
    background-color: #fff;
    // padding-left: pxToVW(20);
    // padding-right: pxToVW(20);
    z-index: 9;

    // &.disabled {
    //   .title {
    //     color: #c2c9d5;
    //   }
    // }

    .title {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      color: #363b45;

      span {
        vertical-align: middle;
        color: #a8afb9;
      }
    }
  }

  .image-notice {
    position: fixed;
    bottom: 36px;
    padding: 16px 10px;
    background-color: rgba(54, 59, 69, 0.7);
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 75px);
    color: #fff;
    border-radius: 12px;
    z-index: 9;

    .content {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.32px;
      text-align: center;
      color: #fff;
    }
  }

  .note {
    padding: 17px 24px 18px 18px;
    border-radius: 9px;
    background-color: #fffbe2;

    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.35px;
    text-align: left;

    .title {
      font-family: Pretendard;
      font-weight: bold;
      line-height: 1.14;
      color: #dea640;

    }

    .content {
      font-family: Pretendard;
      font-weight: 500;
      line-height: 1.43;
      color: #dea640;
      white-space: nowrap;
    }
  }

  .store-list {
    .title {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      color: #363b45;
      margin-bottom: pxToVW(6);
    }

    .desc {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: -0.3px;
      text-align: left;
      color: #8f949c;
    }

    .store-item {
      padding: 9px 16px;
      border-radius: 17px;
      margin-left: pxToVW(6);
      margin-right: pxToVW(6);
      margin-top: pxToVW(11);
      background-color: #f5f6f8;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.35px;
      text-align: left;
      color: #363b45;
      white-space: nowrap;
      &:first-child {
        margin-left: pxToVW(20);
      }

      &:last-child {
        margin-right: pxToVW(20);
      }

      &.active {
        border: solid 1px #efb752;
        background-color: #fffbe2;
        color: #dea640;
      }
    }

    .overflow-auto {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
