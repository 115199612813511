.dropdown-common {
  width: 100%;
  position: relative;
  padding: 11px 10.6px 10px 10.8px;
  border: solid 1px #f5f6f8;
  background-color: #f5f6f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown {
    width: calc(100% - 12px);
    position: static;
    .dropdown-toggle {
      background-color: transparent;
      font-family: Pretendard;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      border: none;
      padding: 0;
      width: 100%;
      box-shadow: none;
      color: #a8afb9;
    }
    &.show {

      .dropdown-toggle {
        &:focus {
          background-color: transparent;
          color: #000;
          border: none !important;
          outline: none;
          box-shadow:none;
        }
      }
      .dropdown-menu {
        width: 100%;
        left: 0 !important;
        transform: inherit !important;
        inset: 39px auto auto 0px !important;
        overflow: hidden;
        height: 77px;
        overflow-y: auto;
        padding-top: 0;
        .dropdown-item {
          padding: 5px 10px;
          &.active {
            background-color: #f5f6f8;
            color: #0e0f11;
          }
          &.disabled {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #767b84;
            pointer-events: none;
          }
        }
      }
    }
  }
  &.has-choose {
    background-color: #fff;
    border:1px solid #dfe2e6;
    .dropdown {
      .dropdown-toggle {
        color: #111;
      }
    }
  }
  &.read-only {
    pointer-events: none;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .icon-arrow-down {
    width: 14px;
    height: 8px;
    position: absolute;
    right: 15px;
  }
  &.active {
    background-color: #fff;
  }
}
.list-choose-time {
  display: flex;
  align-items: center;
  border: solid 1px #a4a4a4;
  border-radius: 8px;
  padding: 4px 0;
  .dropdown-common {
    width: auto;
    border:none;
    padding: 0px 10.6px 0px 10.8px;
    min-width: 40px;
    .dropdown {

      .dropdown-toggle {

      }
      &.show {

        .dropdown-toggle {
          &:focus {

          }
        }
        .dropdown-menu {
          width: 25px !important;
          min-width: 25px;
          border: solid 1px #a4a4a4;
          inset: 22px auto auto 7px !important;
          .dropdown-item {
            padding: 0;
            
          }
          .disabled{
            background-color: #ccc;
            margin-bottom: 0px
          }
          
        }
      }
    }

    .dropdown-toggle::after {
      display: none;
    }
    .icon-arrow-down {
      display: none;
    }
  }

}