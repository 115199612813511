.wrapper-info-buy {
  padding-top: pxToVW(60);

  .line1 {
    text-align: center;
    width: 100%;
    font-size: pxToVW(14);
    line-height: pxToVW(16);
    color: $white;
    font-weight: $regular;
  }

  .line2 {
    margin-bottom: pxToVW(5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 pxToVW(20);
    font-weight: $black;

    .number {
      display: inline-block;
      font-size: pxToVW(40);
      line-height: pxToVW(48);
      font-weight: $black;
      color: $white;
      margin-right: pxToVW(3);
      font-family: $Roboto;
      vertical-align: middle;
    }

    span {
      font-size: pxToVW(20);
      vertical-align: middle;
      font-weight: bold;
      display: inline-block;
      color: $white;
      line-height: pxToVW(23);
    }
  }

  .btn-ac {
    padding: pxToVW(5) pxToVW(20);
    color: $white;
    display: inline-block;
    background-color: rgba($white, 0.1);
    border-radius: pxToVW(5);
    font-size: pxToVW(11);
    line-height: pxToVW(13);

    .number {
      font-size: pxToVW(16);
      font-family: $Roboto;
      font-weight: bold;
      margin-left: pxToVW(5);
      line-height: pxToVW(19);
      margin-right: pxToVW(2);
      display: inline-block;
      vertical-align: middle;
    }

    .mt-pst1 {
      position: relative;
      top: pxToVW(1);
      font-size: pxToVW(11);
      font-weight: $medium;
      opacity: 0.8;

      &.regular {
        font-weight: $regular;
      }
    }

    * {
      color: $white;
      display: inline-block;
    }
  }
}

.MainPage.history-buy-page {
  background-color: $governor-2;

  .page-store {
    padding-bottom: 0;
  }

  .page-store .setting-header {
    padding-bottom: 0;
    margin-bottom: 0;
    min-height: 100vh;
    position: fixed;
    width: 100%;
    background-color: $governor-2;
  }

  .page-store-content {
    background-color: $white;
    border-top-left-radius: pxToVW(30);
    padding: pxToVW(20) pxToVW(30);
    padding-bottom: pxToVW(80);
    border-top-right-radius: pxToVW(30);
    bottom: 0;
    width: 100%;
    height: 100%;
    top: pxToVW(185);
    position: fixed;
    overflow: hidden;
    box-shadow: 0 pxToVW(-5) pxToVW(15) 0 rgba($black-light-3, 0.2);

    &.back {
      animation: touchE 0.7s ease-in-out both;

      @keyframes touchE {
        0% {
          top: 0;
        }

        100% {
          top: pxToVW(185);
        }
      }
    }

    &.touchend {
      animation: touchS 0.7s ease-in-out both;

      @keyframes touchS {
        0% {
          top: pxToVW(185);
        }

        100% {
          top: 0;
        }
      }
    }

    // box-shadow: 0 pxToVW(-5) pxToVW(15) 0 rgba(0, 0, 0, 0.2);
    .content-history-buy {
      height: 100%;
      padding-bottom: pxToVW(80);
      overflow: auto;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }

    .select-month {
      &--item {
        width: 33.33%;
        text-align: center;
        border-radius: pxToVW(5);
        background-color: $bg-stability;
        color: rgba($black-light-3, 0.5);
        display: inline-block;
        line-height: pxToVW(31);
        font-size: pxToVW(12);
        margin-right: pxToVW(7);
        font-weight: 700;
        border: pxToVW(1) solid $bg-stability;

        &.active {
          border: pxToVW(1) solid rgba($governor-2, 0.5);
          color: $governor-2;
          font-weight: $black;
          background-color: $wild-sand;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.wrapper-datepicker {
  border: pxToVW(1) solid $gallery;
  border-radius: pxToVW(5);
  height: pxToVW(33);
  display: flex;
  position: relative;
  margin-top: pxToVW(5);

  &:before {
    content: '';
    position: absolute;
    width: pxToVW(8);
    height: pxToVW(1);
    background-color: $black-light-3;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  > div {
    width: 50%;
    text-align: center;
    line-height: pxToVW(31);
    font-size: pxToVW(12);
    color: $black-light-3;
    font-family: $Roboto;
  }
}

.wrapper-filter {
  margin-bottom: 0;
  display: flex;
  font-size: pxToVW(11);
  margin-top: pxToVW(15);
  line-height: pxToVW(12);

  img {
    width: pxToVW(8);
    margin-left: pxToVW(3);
    height: pxToVW(4);
  }
}

.content-history-buy {
  .non-data {
    margin-top: 0;
    position: relative;
    top: 30%;
  }

  .item {
    padding-top: pxToVW(15);
    padding-bottom: pxToVW(15);
    border-bottom: pxToVW(2) solid $bg-stability;

    &:first-child {
      padding-top: pxToVW(10);
    }

    .title {
      font-size: pxToVW(14);
      line-height: pxToVW(16);
      font-weight: bold;
    }

    .status {
      background-color: $green;
      color: $white;
      font-size: pxToVW(9);
      line-height: pxToVW(16);
      padding: pxToVW(3) pxToVW(5);
      display: inline-block;
      font-weight: $bold;
      white-space: nowrap;

      &.d1 {
        background-color: $governor-2;
      }

      &.d2 {
        background-color: $green;
      }

      &.d3 {
        background-color: $orange-2;
      }

      &.d4 {
        background-color: $yellow;
      }
      &.d5 {
        background-color: $box-kakao;
      }
      &.d6 {
        background-color: $box-payco;
      }
    }

    .count {
      font-size: pxToVW(20);
      margin-bottom: pxToVW(2);
      line-height: pxToVW(23);
      font-weight: bold;
      color: $black-light-3;

      span {
        font-size: pxToVW(14);
        line-height: pxToVW(16);
        font-weight: bold;
        position: relative;
        top: pxToVW(-2);
      }
    }

    .line3 {
      margin-bottom: pxToVW(2);
    }

    .line2 {
      margin-top: pxToVW(4);
    }

    .line4 {
      font-size: pxToVW(11);
      line-height: pxToVW(13);
      color: $color-reduction;
      font-family: $Roboto;

      &.red {
        color: $color-price;
      }
    }

    .date {
      color: $black-light-3;
      font-size: pxToVW(11);
      line-height: pxToVW(13);
      font-family: $Roboto;
      font-weight: 300;
    }
  }
}

.show-modal {
  .content-history-buy {
    .non-data {
      margin-top: 66%;
    }
  }
}

.datepicker.ios .datepicker-wheel {
  border: 0 !important;
}

.datepicker.ios {
  position: fixed;
  padding-bottom: pxToVW(58);
  background-color: $white !important;

  .datepicker-wheel {
    border: 0;
  }

  .datepicker-content {
    padding-top: pxToVW(8) !important;
    position: relative;
    padding-left: pxToVW(30);
    padding-right: pxToVW(30);

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      display: inline-block;
      height: pxToVW(42);
      background-color: rgba($wild-sand, 0.5);
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      content: '';
      position: fixed;
      bottom: pxToVW(64);
      left: 0;
      width: 100%;
      height: pxToVW(80);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
      z-index: 9;
    }
  }

  .datepicker-scroll {
    li {
      font-size: pxToVW(20);
      line-height: pxToVW(42);
      font-family: $Roboto;
      font-weight: 700;
      color: rgba($black-light-3, 0.3);
      font-size: pxToVW(18);

      &:nth-child(6) {
        color: $governor-2;
        font-size: pxToVW(20);
      }
    }
  }

  .datepicker-header {
    height: pxToVW(46);
    line-height: pxToVW(46);
    padding-left: pxToVW(20) !important;
    text-align: left;
    color: $black-light-3;
    font-size: pxToVW(14);
    font-weight: $black;
  }

  .datepicker-navbar {
    position: relative;
    height: pxToVW(46);
    border-bottom: pxToVW(2) solid $bg-stability !important;

    a {
      &:nth-child(1) {
        position: fixed;
        width: 100%;
        background-color: $orange-2;
        bottom: 0;
        left: 0;
        line-height: pxToVW(58);
        height: pxToVW(58);
        color: $black-light-3;
        font-size: pxToVW(16);
        font-weight: $black;
      }

      &:nth-child(2) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: pxToVW(12);
        height: pxToVW(12);
        right: pxToVW(20);
        font-size: 0;

        &:before {
          content: '';

          background-image: url('../../../assets/icons/pop_X.svg');
          font-size: 0;
          display: inline-block;
          width: pxToVW(12);
          height: pxToVW(12);
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.modal-select {
  height: 0;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: pxToVW(50);
  width: 100%;
  background-color: $white;
  z-index: 9999999;

  &.open {
    height: pxToVW(270);
  }

  &--header {
    height: pxToVW(46);
    line-height: pxToVW(46);
    padding-left: pxToVW(20);
    font-weight: 700;
    font-size: pxToVW(14);
    border-bottom: pxToVW(2) solid $bg-stability;
    position: relative;
    background-color: $white;
    z-index: 999;

    .modal-close {
      width: pxToVW(12);
      height: pxToVW(12);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      right: pxToVW(20);

      img {
        position: absolute;
        top: 0;
      }
    }
  }
}

body.show {
  overflow: hidden;
}

body.show-modal {
  overflow: hidden;
  position: fixed;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    display: inline-block;
    background-color: rgba($black-light-3, 0.5);
    z-index: 99999;
  }
}

.page-product-list {
  .non-data {
    margin-top: 60%;
    position: relative;
  }

  .modal-select {
    bottom: 0 !important;

    &.open {
      height: 88vw;
    }

    .modal-select-over {
      &::after {
        bottom: pxToVW(108);
      }
    }

    .swiper-container {
      height: pxToVW(300);
      bottom: pxToVW(65);
      position: relative;
    }
  }

  .modal-over::before {
    top: pxToVW(115);
  }
}

.datepicker-modal {
  z-index: 99999 !important;
}

.modal-select-over {
  &::after {
    content: '';
    position: fixed;
    bottom: pxToVW(80);
    left: 0;
    width: 100%;
    height: pxToVW(50);
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    z-index: 9;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      #ffffff,
      rgba(245, 245, 245, 0) 52%,
      rgba(245, 245, 245, 0) 48%,
      #f7f7f7
    ) !important;
  }
}

.modal-over {
  height: pxToVW(255);
  overflow-y: auto;
  margin-top: pxToVW(30);
  margin-bottom: pxToVW(40);
  position: relative;
  width: 100%;

  &::before {
    content: '';
    border-radius: 2.77778vw;
    width: calc(100% - 16.66667vw);
    right: pxToVW(31);
    position: fixed;
    display: inline-block;
    height: 11.66667vw;
    background-color: rgba(245, 235, 246, 0.5);
    top: 41%;
    transform: translateY(-50%);
  }

  // &::after {
  //   content: "";
  //   position: fixed;
  //   top: pxToVW(26);
  //   left: 0;
  //   width: 100%;
  //   height: pxToVW(50);
  //   background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  //   z-index: 9;
  // }

  .swiper-slide {
    width: 100%;
    height: pxToVW(42) !important;
    opacity: 0.3;

    &.swiper-slide-active {
      // background-color: rgba($wild-sand, 0.5);
      width: calc(100% - 16.66667vw);
      margin-left: pxToVW(30);
      border-radius: pxToVW(10);
      opacity: 1;

      .item {
        color: $governor-2;
        font-weight: $black;
      }
    }
  }

  .item {
    display: inline-block;
    width: 100%;
    font-size: pxToVW(18);
    line-height: pxToVW(42);
    font-weight: 700;
    color: rgba($black-light-3, 0.3);
    font-family: $Roboto;
    // &.active {
    //   background-color: rgba($wild-sand, 0.5);
    //   color: $governor-2;
    //   font-weight: $black;
    // }
  }
}

.datepicker.ios .datepicker-content:before {
  border-radius: pxToVW(10);
  background-color: rgba($wild-sand, 0.5);
  width: calc(100% - #{pxToVW(60)});
  right: pxToVW(30);
}

.datepicker.ios .datepicker-viewport::after {
  background: linear-gradient(
    $white,
    rgba(245, 245, 245, 0) 52%,
    rgba(245, 245, 245, 0) 48%,
    #f7f7f7
  ) !important;
}

.mh-70 {
  min-height: 75vh;
}

.history-buy-page {
  .modal-over::before {
    top: 54%;
  }
  .page-store {
    position: relative;
    .back-to-page {
      position: absolute;
      left: 20px;
      top: 15px;
      z-index: 999;
      img {
        width: 11px;
        height: 20px;
      }
    }
  }
}