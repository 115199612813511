.app-sub-header {
  width: 100%;
  height: pxToVW(49);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 9999;


  .icon_back {
    position: relative;
    z-index: 999;
    margin: 20px 20px 20px 5px;
  }

  &.font-roboto {
    * {
      font-family: $Roboto;
    }
  }

  &.level1 {
    * {
      background-color: $color-box1;
    }
  }

  a {
    font-size: pxToVW(20);
    line-height: 1.45;
    letter-spacing: pxToVW(-0.35);
    color: $black-active;
  }

  &.bg-main {
    * {
      color: $white;
    }
  }


  &__content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    font-weight: $regular;
    padding: pxToVW(15) 0 pxToVW(14);
    height: pxToVW(49);
    margin-bottom: pxToVW(-1);
    z-index: 99;
    background-color: $white;

    &--center {
      justify-content: center;
    }

    &--center>div .icon__wrapper {
      position: absolute;
      left: pxToVW(20);
      top: auto;
    }

    &__ico {
      position: absolute;
      z-index: 99;
      left: pxToVW(15);
      outline: none;
      margin-top: pxToVW(2);
      width: pxToVW(20);

      a {
        display: block;
      }

      .icon__wrapper {
        top: pxToVW(1);
      }

      .icon {
        width: pxToVW(14);
        height: pxToVW(14);
        margin-right: pxToVW(5);
      }

      p {
        img {
          height: pxToVW(20);
          width: pxToVW(20);
        }
      }
    }

    &__text {
      font-size: 18px;
      position: relative;
      font-weight: $bold;
      left: pxToVW(10);
      width: 88%;
      text-align: center;
      position: relative;
      line-height: 1.17;
      letter-spacing: normal;
      color: $black-light-3;
      padding-right: pxToVW(20);

      span {
        font-weight: $black;
      }

      &.customPadding {
        width: 100%;
      }

      &--100 {
        span {
          font-weight: $thin;
        }
      }

      &--200 {
        span {
          font-weight: $extraLight;
        }
      }

      &--300 {
        span {
          font-weight: $light;
        }
      }

      &--400 {
        span {
          font-weight: $regular;
        }
      }

      &--500 {
        span {
          font-weight: $medium;
        }
      }

      &--600 {
        span {
          font-weight: $semiBold;
        }
      }

      &--700 {
        span {
          font-weight: $bold;
        }
      }

      &--800 {
        span {
          font-weight: $extraBold;
        }
      }

      &--900 {
        span {
          font-weight: $black;
        }
      }
    }
  }
}

.border-bottom{
  .app-sub-header__content{
    border-bottom: 1px solid #e2e4e8;
  }
}

.btn-logout {
  text-align: center;
  height: pxToVW(20);
  align-items: center;
  justify-content: center;
  width: pxToVW(20);
  margin: 0;
  top: 0;
  position: absolute;
  right: pxToVW(10);
}