.management-qna-body {
  &.modal-open {
    position: static;
  }
}
.management-qna {
  padding-right: 0;
  padding-left: 0;
  .wrapper-main {
    margin-top: 20px;

    .quantity-qa {
      margin-bottom: 20px;
      padding: 0 5.55556vw;
      label {
        margin-bottom: 0px;
        opacity: 0.7;
        font-family: NanumSquareR;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }
      strong {
        color: #ff4040;
        font-family: NanumSquareR;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        padding: 0 2px;
      }
    }
    .list-qna {
      .item-qna {
        padding: 20px 5.55556vw 0;
        &:first-child {
          padding-top: 0;
        }
        .info-qna {
          &__product {
            display: flex;
            margin-bottom: 15px;
            &__img {
              width: 70px;
              height: 70px;
              margin-right: 7px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
              }
            }
          }
          .info-product-content {
            position: relative;
            width: calc(100% - 80px);
            &__name {
              font-family: NanumSquareR;
              font-size: 14px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.3;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-bottom: 6px;

              overflow: hidden;
              width: 100%;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

            }
            &__user-time {
              opacity: 0.5;
              font-family: NanumSquareL;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: normal;
              text-align: left;
              color: #000;
            }
            .is-private {
              position: absolute;
              bottom: -3px;
              left: 0;
              label {
                font-family: NanumSquareB;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                padding-left: 3px;
              }
              img {
                width: 12px;
                height: 16px;
                margin-bottom: -3px;
              }
            }
          }
          &__question {
            margin-bottom: 5px;
            p {
              font-family: NanumSquareR;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.36;
              letter-spacing: -0.14px;
              text-align: left;
              color: #000;
            }
          }
          &__action {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            &__answer {
              padding: 6px 12px;
              border-radius: 2px;
              border: solid 1px #e6e6e6;
              background: white;
              color: black;
              &:disabled {
                border-radius: 2px;
                border: solid 1px #e6e6e6;
                background-color: #f2f2f2;
                color: black;
              }
            }
            &__status {
              padding: 4px 6px;
              border-radius: 2px;
              background-color: #e6e6e6;
              font-family: NanumSquareR;
              font-size: 12px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: normal;
              text-align: right;
              color: #fc5d5d;
              display: flex;
              align-items: center;
              min-height: 27px;
              &.answer {
                color: #444444;
              }
            }
            &__has-answer {
              display: flex;
              align-items: center;
              &.show {
                img {
                  transform: rotate(180deg);
                }
              }
              span {
                font-family: NanumSquareB;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                text-align: left;
                color: var(--black);
                label {
                  font-family: NanumSquareR;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.08;
                  letter-spacing: normal;
                  text-align: left;
                  color: #ff4040;
                  padding:  0 3px;
                  vertical-align: bottom;
                  margin-bottom: -1px;
                }
              }
              img {
                width: 8px;
                height: 4px;
              }
            }
          }
        }
        .content-answer {
          background: #f5f5f5;
          padding:  4px 20px;
          margin: 0 -21px;
          &.show {
            padding:  13px 20px 5px;
          }
          .content-answer-form {
            padding-bottom: 10px;
          }
          .content-answer-create {
            padding: 10px 0;
            .form-answer {
              .content-main {
                position: relative;
                textarea {
                  //margin-bottom: 3px;
                  resize:none;
                  font-family: NanumSquareR;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.6;
                  letter-spacing: -0.14px;
                  text-align: left;
                  color: #000;
                  padding-right: 30px;
                  &::placeholder {
                    opacity: 0.5;
                    font-family: NanumSquareR;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: -0.14px;
                    text-align: left;
                    color: #000;
                  }
                }
                .action-answer {
                  position: absolute;
                  bottom: 1px;
                  right: 1px;
                  font-family: NanumSquareR;
                  font-size: 12px;
                  font-weight: 900;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.08;
                  letter-spacing: normal;
                  border: none;
                  padding: 0;
                  color: #111111;
                  background: white !important;
                  width: 35px;
                  text-align: right;
                  padding-right: 10px;
                  white-space: nowrap;
                  min-height: 30px;
                  z-index: 900;
                  display: block;
                  opacity: 0.5;
                  label {
                    font-family: NanumSquareR;
                    font-size: 12px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.08;
                    letter-spacing: normal;
                    z-index: 900;
                    background:transparent;
                    display: block;
                    opacity: 0.5;
                  }
                  &.active {
                    color: #5815a5;
                    opacity: 1;
                    label {
                      color: #5815a5;
                      opacity: 1;
                    }
                  }
                }
              }
              .count-character {
                text-align: right;
                margin-right: 1px;
                margin-top: 5px;
                span {
                  opacity: 0.8;
                  font-family: Pretendard;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.17;
                  letter-spacing: -0.6px;
                  text-align: right;
                  color: var(--black);
                  &:nth-child(2){
                    padding: 0 3px;
                  }
                  label {
                    color: #ff4040;
                  }
                }
              }
            }
          }
          .content-answer-form {
            display: flex;
            margin-top: 13px;
            &.content-answer-form-edit {
              flex-wrap: wrap;
              .content-answer-form-edit__action {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 7px;
                &__time {
                  label {
                    opacity: 0.5;
                    font-family: Roboto;
                    font-size: 11px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.18;
                    letter-spacing: -0.22px;
                    text-align: left;
                    color: var(--black);
                    margin-left: 5px;
                  }
                }
                button {
                  background: transparent;
                  border: none;
                  opacity: 0.5;
                  font-family: Roboto;
                  font-size: 11px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.18;
                  letter-spacing: -0.22px;
                  text-align: right;
                  color: var(--black);
                  padding-right: 0;
                }
              }
              .content-answer-edit {
                width: 100%;
              }
            }
            &__icon {
              width: 11px;
              height: 13px;
            }
            .content-answer-create {
              width: calc(100% - 12px);
              margin-left: 3px;
              padding: 0;
              textarea {
                font-family: NanumSquareR;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: -0.14px;
                text-align: justify;
                color: #000;
              }
            }
          }
          .content-answer-value {
            margin-bottom: 10px;
            .answer-value {
              &__time {
                position: relative;
                img {
                  width: 11px;
                  height: 13px;
                }
                label {
                  opacity: 0.5;
                  font-family: Roboto;
                  font-size: 11px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.18;
                  letter-spacing: -0.22px;
                  text-align: left;
                  color: var(--black);
                  padding-left: 5px;
                }
                &__action {
                  position: absolute;
                  right: -4px;
                  top: 0px;
                  label {
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background: black;
                    color: black;
                    opacity: 1;
                    display: block;
                    margin: 2px 0;
                  }
                  .icon-action {
                    width: 24px;
                  }
                }
                .dropdown-action {
                  position: absolute;
                  right: 10px;
                  top: 15px;
                  width: 87px;
                  min-height: 75px;
                  background: white;
                  border-radius: 5px;
                  border: solid 1px #e6e6e6;
                  z-index: 99;
                  ul {
                    li {
                      margin: 0px 5px;
                      padding: 13px 10px;
                      font-family: NanumSquareR;
                      font-size: 12px;
                      font-weight: 900;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.08;
                      letter-spacing: normal;
                      text-align: left;
                      color: var(--black);
                      &:first-child {
                        border-bottom: 1px solid #e6e6e6;
                      }
                    }
                  }
                }
              }
              &__content {
                padding-left: 15px;
                margin-bottom: 5px;
                margin-top: 5px;
                .text-value {
                  opacity: 0.8;
                  font-family: NanumSquareR;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.36;
                  letter-spacing: -0.14px;
                  //text-align: justify;
                  color: #000;
                }
                label {
                  opacity: 0.8;
                  font-family: NanumSquareR;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.36;
                  letter-spacing: -0.14px;
                  text-align: justify;
                  color: #000;
                }
              }
              &__more-action {
                padding: 6px 12px;
                border-radius: 2px;
                border: solid 1px #e6e6e6;
                background: white;
                margin-left: 15px;
                &:disabled {
                  background: transparent;
                  padding: 6px 12px;
                  border-radius: 2px;
                  border: solid 1px #e6e6e6;
                  background-color: #f2f2f2;
                  margin-left: 15px;
                  color: black;
                }
              }
            }
            
          }
        }
      }
    }
  }
}
.management-qna-history {
  .wrapper-main {
    margin-top: 20px;

    .quantity-qa {
      margin-bottom: 20px;
      padding: 0 5.55556vw;
      label {
        margin-bottom: 0px;
        opacity: 0.7;
        font-family: NanumSquareR;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }
      strong {
        color: #ff4040;
        padding: 0 2px;
      }
    }
    .list-qna {
      .item-qna {
        padding: 20px 5.55556vw 0;
        &:first-child {
          padding-top: 0;
        }
        .info-qna {
          &__product {
            display: flex;
            margin-bottom: 15px;
            &__img {
              width: 100px;
              height: 70px;
              margin-right: 7px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
              }
            }
          }
          .info-product-content {
            position: relative;
            width: calc(100% - 110px);
            &__name {
              font-family: NanumSquareR;
              font-size: 14px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.3;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-bottom: 6px;
            }
            &__user-time {
              opacity: 0.5;
              font-family: NanumSquareL;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: normal;
              text-align: left;
              color: #000;
            }
            .is-private {
              position: absolute;
              bottom: 0;
              left: 0;
              label {
                font-family: NanumSquareB;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                text-align: left;
                color: #000;
              }
              img {
                width: 18px;
                height: 12px;
              }
            }
          }
          &__question {
            margin-bottom: 20px;
            padding: 15px;
            border: solid 1px rgba(17, 17, 17, 0.1);
            border-radius: 5px;
            background-repeat: no-repeat;
            position: relative;
            .bg-triangle {
              width: 14px;
              height: 10px;
              position: absolute;
              top: -10px;
            }
            .count-answer-private {
              display: flex;
              align-items: center;
              margin-bottom: 7px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .box-to-page {
                width: 15px;
                text-align: center;
                margin-right: -5px;
              }
              .icon-to-page {
                width: 6px;
                height: 12px;
                margin-right: -5px;
              }
              .count {
                font-family: NanumSquareB;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                strong {
                  color: #ff4040;
                    font-size: 12px;
                }
              }
              .private {
                display: flex;
                align-items: center;
                .arrow_top {
                  width: 1px;
                  height: 7px;
                  margin: auto 7px;
                  background-color: #111;
                }
                .icon-action {
                  width: 12px;
                  height: 16px;
                  margin-top: -4px;
                  margin-left: 4px;
                }
                &__text {
                  font-family: NanumSquareB;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.08;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000;
                }
              }
            }
            .question {
              display: flex;
              //align-items: center;
              p,label {
                opacity: 0.8;
                font-family: NanumSquareR;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #000;
              }
            }

          }
          &__action {
            display: flex;
            justify-content: space-between;
            margin-bottom: 7px;
            &__answer {
              padding: 6px 12px;
              border-radius: 2px;
              border: solid 1px #e6e6e6;
              background: white;
            }
            &__status {
              padding: 4px 6px;
              border-radius: 2px;
              background-color: #e6e6e6;
              font-family: NanumSquareR;
              font-size: 12px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: normal;
              text-align: right;
              color: #fc5d5d;
              display: flex;
              align-items: center;
              &.answer {
                color: #444444;
              }
            }
            &__has-answer {
              display: flex;
              align-items: center;
              &.show {
                img {
                  transform: rotate(180deg);
                }
              }
              span {
                font-family: NanumSquareB;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                text-align: left;
                color: var(--black);
                label {
                  font-family: NanumSquareR;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.08;
                  letter-spacing: normal;
                  text-align: left;
                  color: #ff4040;
                  padding:  0 3px;
                }
              }
              img {
                width: 8px;
                height: 4px;
              }
            }
          }
        }
        .content-answer {
          background: #f5f5f5;
          padding:  4px 20px;
          margin: 0 -21px;
          &.show {
            padding:  3px 20px;
          }
          .content-answer-create {
            padding: 10px 0;
            .form-answer {
              .content-main {
                position: relative;
                textarea {
                  margin-bottom: 3px;
                }
                .action-answer {
                  position: absolute;
                  bottom: 11px;
                  right: 15px;
                  font-family: NanumSquareR;
                  font-size: 12px;
                  font-weight: 900;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.08;
                  letter-spacing: normal;
                  text-align: left;
                  background: transparent;
                  border: none;
                  padding: 0;
                  color: #111111;
                  opacity: 0.5;
                  &.active {
                    color: #5815a5;
                    opacity: 1;
                  }
                }
              }
              .count-character {
                text-align: right;
                margin-right: 1px;
                span {
                  opacity: 0.8;
                  font-family: Pretendard;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.17;
                  letter-spacing: -0.6px;
                  text-align: right;
                  color: var(--black);
                  &:nth-child(2){
                    padding: 0 3px;
                  }
                  label {
                    color: #ff4040;
                  }
                }
              }
            }
          }
          .content-answer-form {
            display: flex;
            margin-top: 13px;
            &__icon {
              width: 11px;
              height: 13px;
            }
            .content-answer-create {
              width: calc(100% - 12px);
              margin-left: 3px;
              padding: 0;
              textarea {
                font-family: NanumSquareR;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: -0.14px;
                text-align: justify;
                color: #000;
                resize:none
              }
            }
          }
          .content-answer-value {
            margin-bottom: 10px;
            .answer-value {
              &__time {
                position: relative;
                img {
                  width: 11px;
                  height: 13px;
                }
                label {
                  opacity: 0.5;
                  font-family: Roboto;
                  font-size: 11px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.18;
                  letter-spacing: -0.22px;
                  text-align: left;
                  color: var(--black);
                  padding-left: 5px;
                }
                &__action {
                  position: absolute;
                  right: 0px;
                  top: 0px;
                  label {
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background: black;
                    color: black;
                    opacity: 1;
                    display: block;
                    margin: 2px 0;
                  }
                  .icon-action {

                  }
                }
                .dropdown-action {
                  position: absolute;
                  right: 10px;
                  top: 15px;
                  width: 87px;
                  min-height: 75px;
                  background: white;
                  border-radius: 5px;
                  border: solid 1px #e6e6e6;
                  z-index: 99;
                  ul {
                    li {
                      margin: 0px 5px;
                      padding: 13px 10px;
                      font-family: NanumSquareR;
                      font-size: 12px;
                      font-weight: 900;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.08;
                      letter-spacing: normal;
                      text-align: left;
                      color: var(--black);
                      &:first-child {
                        border-bottom: 1px solid #e6e6e6;
                      }
                    }
                  }
                }
              }
              &__content {
                padding-left: 15px;
                margin-bottom: 5px;
                margin-top: 5px;
                label {
                  opacity: 0.8;
                  font-family: NanumSquareR;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.36;
                  letter-spacing: -0.14px;
                  text-align: justify;
                  color: #000;
                }
              }
              &__more-action {
                background: transparent;
                padding: 6px 12px;
                border-radius: 2px;
                border: solid 1px #e6e6e6;
                background-color: #f2f2f2;
                margin-left: 15px;
              }
            }

          }
        }
      }
    }
  }
}