.purchase-history{
  padding-top: 0;
  z-index: 0;
  .page-store .setting-header {
    z-index: 100;
  }
  .setting-header {
    

    .icon_top{
      width: 11px;
      height: 20px;
      .icon_top_arrow{
        height: 20px;
        margin-left: 23px;
      }
    }
    .header-title-page {
      margin-bottom: 0;
    }
    .content-select {
      padding-left: 0;
      padding-right: 0;
      padding-top: 18.66667vw;

      &__item {
        position: relative;
        width: 100%;
        padding-left: 5.55556vw;
        padding-right: 5.55556vw;
        min-height: 42px;
        .history-order {
          padding: 13px 19px 13px 20px;
          opacity: .15;
        }
        .order-select {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 13px 5px;
          height: 42px;
          flex-direction: row;
          .text-default-history {
            font-family: SaffronColdWar;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            //letter-spacing: -0.78px;
            text-align: left;
            color: var(--white);
          }
          .icon_location {
            width: 12px;
            height: 14px;
            display: block;
            object-fit: cover;
            margin: auto 5px;
          }

          .icon_arrowBB {
            width: 12px;
            height: 7px;
            display: block;
            object-fit: cover;
            margin: auto 3px;
          }
        }
      }
    }

  }

  .page-store {
    position: relative;
    text-align: center;
    justify-content: center;
    .text-place {
      color: white;
      z-index: 20;
      font-family: NanumSquareEB;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: -0.49px;
      color: $white;
    }
    .text-place-detail{
      z-index: 20;
      margin: 0 0 0 2px;
      font-family: SaffronColdWar;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: -0.78px;
      color: var(--white);
    }
    .history-order {
      width: pxToVW(320);
      height: 100%;
      padding: 10px 19px 10px 20px;
      opacity: 0.2;
      border-radius: 21px;
      background-color: #080011;
      position: absolute;
      z-index: 10;
    }
    .icon_guya_image {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 90;
    }
  }

  .wrapper-header {
    min-height: 21px;
    margin-bottom: 0px;
    .app-sub-header {
      &__content {
        background-color: white;
        &__text {
          span {
            font-family: Pretendard;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: #0e0f11;
          }
        }
      }
    }
  }
  .box-title-history {
    margin-bottom: 30px;
    padding-top: 80px;
    h3 {
      font-family: Pretendard;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #363b45;
      label {
        font-weight: 600;
        position: relative;
        z-index: 99;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 12px;
          background-color: #ffe76d;
          position: absolute;
          bottom: 0px;
          left: 0;
          z-index: -1;
        }
      }
      strong {

      }
    }
  }
  .body-main {
    //margin-top: 31.66667vw;

    .box-event-order {
      padding-bottom: 0px;
      margin-bottom: 24px;
      //border-bottom: 2px solid #f5f5f5;
      .bg-dark-box {
        position: absolute;
        background: rgba(14, 15, 17, 0.5);
        width: 100%;
        height: 100%;
        z-index: 999;
        border-radius: 20px;
      }
      &:last-child{
        border-bottom: none;
      }
      &.none-border {
        border: none;
      }
    }
    .info-event-box {
      padding: 19px 20px 18px 20px;
      border-radius: 20px;
      box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.1);
      background-color: var(--white);
      margin: 10px 3px;
      .name-event {

        .reservation-complete {
          padding: 4px 6px 3px 7px;
          border-radius: 6px;
          border: solid 1px #295bff;
          background-color: #e9eeff;
          display: inline-block;
          margin-right: 10px;
          .text-reservation-complete {
            white-space: nowrap;
            font-family: Pretendard;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.24px;
            text-align: left;
            color: #295bff;
          }
        }
      }
    }


    .text-product-information{
      margin: 0 0 0 0;
      font-family: NanumSquareEB;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      color: #111;
      margin-bottom: pxToVW(15);
    }
    .image-box {
      width: 100%;
      height: 197px;
      position: relative;
      object-fit: contain;
      border-radius: 5px;
      .image-box-card{
        border-radius: 20px;
        height: 100%;
        overflow: hidden;
        .img_card {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .reservation-complete {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        //width: 70px;
        height: 30px;
        padding: 6px 13px 9px 14px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0px 5px 0px 5px;
        .text-reservation-complete{
          font-family: NanumSquareB;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          color:$white;
        }
      }
    }
    .image-noti {
      position: absolute;
      background-color: transparent;
      content: '';
      width: pxToVW(320);
      height: pxToVW(160);
      top: 0;
      left: 0;
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        font-family: NanumSquareB;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $white;
      }

    }

    .image-cover {
      position: absolute;
      background-color: #111;
      opacity: 0.7;
      content: '';
      width: pxToVW(320);
      height: pxToVW(160);
      top: 0;
      left: 0;
      border-radius: 5px;
      z-index: 10;
      display: none;
    }

    .Random-card {
      padding-top: 0;
      .title-card{
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #0e0f11;
      }
    }

    .text-box {

      position: relative;
      margin-top: -5px;
      .notification {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: palevioletred;
        text-align: center;
        position: absolute;

        .text-notification {
          font-family: NanumSquareEB;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          color: $white;
        }

      }

      .text-price {
        margin-top: 10px;

        .Pick-up-date {
          width: 30px;
          height: 12px;
          margin: 12px 3px 6px 0px;
          font-family: NanumSquareR;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          text-align: left;
        }

        .specific-time{
          font-family: NanumSquareEB;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.63px;
          text-align: left;
          color: #c52321;
          margin-top: pxToVW(25);
        }
      }
      .store-time-history {
        display: flex;
        align-items: center;
        margin-top: 10px;
        &__item {
          display: flex;
          align-items: center;
          padding-left: 8px;
          padding-right: 8px;
          .icon-img {
            width: 15px;
            height: 15px;
          }
          .store-name {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #363b45;
          }
          .label-time {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #363b45;
            padding-right: 5px;
          }
          .value-time {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.14;
            letter-spacing: normal;
            text-align: left;
            color: #ff4e4e;
            &__time {
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #ff4e4e;
            }
          }
          &.location {
            position: relative;
            padding-left: 0;
            &::after {
              content: "";
              display: block;
              width: 1px;
              height: 12px;
              background-color: #dedada;
              position: absolute;
              right: -1px;
            }
            .icon-img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

    }
    .out-of-stock{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .icon_listno-arow{
        width: 105px;
        height: 112px;
        margin: 0 30px 16px 32px;
      }
      span{
        margin: 16px 0 0;
        opacity: 0.5;
        font-family: NanumSquareR;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #111;
      }
    }

  }
  .empty-data-history {
    padding-top: 90px;
    .icon-empty {
      width: 78px;
      height: 78px;
      display: block;
      margin: auto;
    }
    h3 {
      margin-top: 23px;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #0e0f11;
      strong {

      }
      label {
        position: relative;
        z-index: 999;
        font-family: Pretendard;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #0e0f11;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 12px;
          background-color: #ffe76d;
          position: absolute;
          bottom: 0px;
          left: 0;
          z-index: -1;
        }
      }
    }
  }
}

.order-history {
  .info-box-item__store-location {
    display: flex;
    align-items: center;
    .icon-img {
      width: 14px;
      height: 14px;
    }
    .store-name {
      font-family: NanumSquareB;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: -0.39px;
      text-align: left;
      color: var(--black);
      white-space: nowrap;
    }
  }
  .info-box-item__time {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #ff4e4e;
  }
  .wrapper-order-complete__info-box {
    padding-bottom: 18px !important;
  }
  .store-time-history {
    display: flex;
    align-items: center;
    margin-top: 0px;
    &__item {
      display: flex;
      align-items: center;
      padding-left: 8px;
      padding-right: 8px;
      .icon-img {
        width: 15px;
        height: 15px;
      }
      .store-name {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
      }
      .label-time {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
        padding-right: 5px;
      }
      .value-time {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #ff4e4e;
      }
      &.location {
        position: relative;
        padding-left: 0;
        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 12px;
          background-color: #dedada;
          position: absolute;
          right: -1px;
        }
        .icon-img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .info-box-item {
    &__name {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #0e0f11;
      padding: 2px 0 3px;
    }
  }
  .box-info-event {
    padding-bottom: 15px;
    border-bottom: 1px solid #dfe2e6;
    margin-bottom: 16px;
    .text-random {
      font-family: Pretendard;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #363b45;
    }
    .info-box-item {

    }
  }
  .guide-use-product {
    .product-not-order {
      padding: 14px 25px 12px 25px;
      border-radius: 10px;
      background-color: #fff3f3;
      text-align: center;
      margin-bottom: 20px;
      &__title {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: #ff4e4e;
      }
    }
    .guide-use-title {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      color: #0e0f11;
      margin-bottom: 18px;
    }
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ff4e4e;
  }
  .guide-use-content {
    h3 {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      width: 100%;
      margin-bottom: 5px;
    }
    p {
      margin: 0 !important;
      &::before {
        display: none !important;
      }
    }
    img {
      margin:  10px 0;
    }
    ul {
      margin-bottom: 17px;
      li {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #8f949c;
        position: relative;
        padding-left: 10px;
        z-index: 10;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          content: "";
          display: inline-block;
          //border-radius: 50%;
          width: 4px;
          height: 1px;
          margin: 15px 3px 16px 0;
          background-color: #8f949c;
          position: absolute;
          left: 0px;
          top: 10px;
          margin: auto;
        }
      }
    }
    &__item{
      iframe{
        width: 100%;
        height: calc(100vh - 530px);
      }
    }
  }
}
.header-fixed-box {
  position: fixed;
  left: 0;
  width: 100%;
  min-height: 40px;
  padding-top: 10px;
  background-color: white;
  z-index: 9999;
  .setting-header {
    text-align: center;
    position: relative;
    .icon-back {
      position: absolute;
      left: 20px;
      top: 2px;
      padding: 0px 20px 20px 0px;
      &__img {
        width: 8px;
        height: 14px;
      }
    }
    .header-title-page {
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #0e0f11;
    }
  }
}
//.guide-use-content {
//  h3 {
//    margin: 21px 222px 10px 4px;
//    opacity: 0.8;
//    font-family: NanumSquareB;
//    font-size: 14px;
//    font-weight: normal;
//    font-stretch: normal;
//    font-style: normal;
//    line-height: 1.43;
//    letter-spacing: -1.05px;
//    text-align: left;
//    color: var(--black);
//    display: flex;
//    align-items: center;
//    width: 100%;
//    &::before {
//      content: "";
//      background-image: url('../../../assets/images/icon_pencil.svg');
//      width: 18px;
//      height: 18px;
//      display: block;
//      margin-bottom: 5px;
//      margin-right: 5px;
//    }
//  }
//  p {
//    margin: 0 !important;
//    &::before {
//      display: none !important;
//    }
//  }
//  img {
//    margin:  10px 0;
//  }
//  ul {
//    li {
//      opacity: 0.8;
//      font-family: NanumSquareR;
//      font-size: 12px;
//      font-weight: normal;
//      font-stretch: normal;
//      font-style: normal;
//      line-height: 1.67;
//      letter-spacing: -0.9px;
//      text-align: left;
//      color: #111;
//      position: relative;
//      margin-left: 5px;
//      z-index: 10;
//      &::before {
//        content: "";
//        display: inline-block;
//        border-radius: 50%;
//        width: 2px;
//        height: 2px;
//        margin: 15px 3px 16px 0;
//        background-color: #111;
//        position: absolute;
//        left: -5px;
//        top: 10px;
//        margin: auto;
//      }
//    }
//  }
//}