.checkbox {
  .checkbox__input {
    display: none;
  }

  .checkbox__label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: pxToVW(12);
    line-height: pxToVW(16);
    padding-left: pxToVW(20);
    color: #000000;

    span {
      font-size: pxToVW(14);
      font-weight: bold;
      line-height: 1.14;
      letter-spacing: normal;
      display: inline-block;
      margin-top: pxToVW(4);
      margin-left: pxToVW(4);
    }

    &::before {
      height: pxToVW(20);
      width: pxToVW(20);
      border: pxToVW(1) solid $black-light-3;
      left: pxToVW(-1);
      top: 0;
      position: absolute;
      content: "";
      display: inline-block;
      background-color: $white;
    }

    &::after {
      position: absolute;
      display: inline-block;
      height: pxToVW(7);
      width: pxToVW(14);
      border-left: pxToVW(2) solid;
      border-bottom: pxToVW(2) solid;
      color: $white;
      transform: rotate(-45deg);
      content: none;
      left: pxToVW(3);
      top: pxToVW(4);
    }
  }

  &.checkbox--checked {
    .checkbox__label::after {
      content: "";
    }

    .checkbox__label::before {
      background-color: $governor-2;
      border: 0;
    }
  }
}