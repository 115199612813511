.footer {
  padding: pxToVW(20) 0;
  height: pxToVW(72);
  position: fixed;
  bottom: 0;
  z-index: 999;
  box-shadow: 0 pxToVW(-3) pxToVW(8) 0 rgba(0, 0, 0, 0.1);
  background-color: $color-black;
  border-top-left-radius: pxToVW(30);
  border-top-right-radius: pxToVW(30);
  display: flex;
  justify-content: space-around;
  left: 0;
  width: 100%;

  .disable-tab {
    opacity: 0.4;
    pointer-events: none;
  }

  &__tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    outline: none;
    font-weight: $regular;
    opacity: 0.4;

    a,
    .footer-link {
      text-align: center;
      text-decoration: none;
      height: pxToVW(38);
      width: 100%;

      img {
        width: pxToVW(30);
        height: pxToVW(30);
      }
    }
  }

  &__tab--active {
    opacity: 1;

    .footer__title-icon {
      font-weight: $bold;
      color: $text-footer-active;

      a::hover {
        text-decoration: none;
      }
    }
  }
}
