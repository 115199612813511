.setting-page {
  margin-bottom: pxToVW(40);

  .setting-header {
    background-color: $governor-2;
    box-shadow: 0 pxToVW(3) pxToVW(15) 0 rgba(88, 21, 165, 0.25);
    text-align: center;
    padding-bottom: pxToVW(30);
    border-bottom-right-radius: pxToVW(30);
    border-bottom-left-radius: pxToVW(30);

    img {
      max-width: pxToVW(100);
      height: pxToVW(50);
      margin-top: pxToVW(30);
    }

    .setting-header-intro {
      margin-top: pxToVW(10);
      opacity: 0.8;
      font-size: pxToVW(14);
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
  }

  .setting-content {
    padding: pxToVW(40) pxToVW(30) pxToVW(40) pxToVW(30);

    .title {
      font-size: pxToVW(14);
      font-weight: $black;
      line-height: 1.14;
      text-align: left;
      color: $black-light-3;
    }

    .title-sub {
      opacity: 0.8;
      font-size: pxToVW(12);
      line-height: 1.67;
      text-align: left;
      color: $black-light-3;
      margin-bottom: pxToVW(20);
      padding-top: pxToVW(5);
    }

    .list-info {
      margin-top: pxToVW(20);
      .custom-padding{
        padding-bottom: pxToVW(30);
      }
      .custom-margin-20{
        margin-bottom: pxToVW(20);
      }
      .logo {
        width: pxToVW(50);
        height: pxToVW(50);
        border-radius: 50%;
        padding: pxToVW(13);
        background-color: $wild-sand;
        margin-right: pxToVW(10);
      }

      .setting-title-info {
        font-size: pxToVW(12);
        font-weight: $black;
        line-height: 1.08;
        text-align: left;
        color: $black-light-3;
        margin-bottom: pxToVW(5);
      }

      .setting-title-sub {
        opacity: 0.8;
        font-size: pxToVW(12);
        line-height: 1.5;
        text-align: left;
        color: $black-light-3;
      }
    }

    .desc-footer {
      opacity: 0.8;
      font-size: pxToVW(12);
      line-height: 1.33;
      text-align: left;
      color: $black-light-3;
    }
  }
}