$font-size-base: 16;
$border-width-base: 2;
$viewPort: 360;

/**
 * Variable for colors
*/
$white: #fff;
$white-dark: #e4e5e6;
$white-opacity-2: rgba(255, 255, 255, 0.2);
$white-opacity-4: rgba(255, 255, 255, 0.4);
$brown: #4a4a4a;
$black-opacity-7: rgba(0, 0, 0, 0.7);
$black-opacity-7: rgba(0, 0, 0, 0.7);
$bgLoadingIndicator: rgba(255, 255, 255, 1.8);
$black-opacity-24: rgba(0, 0, 0, 0.24);
$black-opacity-3: rgba(0, 0, 0, 0.3);
$btn-login: #0a0e2a;
$black-light-3: #111111;
$wild-sand: #f5ebf6;
$brown: #4a4a4a;
$gallery: #e6e6e6;
$mercury: #e4e4e4;
$alto: #e0e0e0;
$steal: #d7d7de;
$hummer: #ccc;
$white-opacity-3: #d5d7f1;
$dusty-gray: #9b9b9b;
$dusty-gray-secondary: #979797;
$bgLoadingIndicator: rgba(255, 255, 255, 1.8);
$dusty-gray: #9b9b9b;
$dusty-gray-secondary: #979797;
$gray-sec: #495057;
$gray: #919191;
$gray-light: #d7d8df;
$dove-gray: #666666;
$dove-gray-light: #6a6b86;
$btn-secondary-bg2: #6c757d;
$iron: #393939;
$steal-transparent: #212529;
$dove-transparent: #0000001f;
$cove-transparent: #0000002b;
$stone-transparent: #05083299;
$alto-transparent: rgba(156, 156, 156, 0.7);
$bg-transparent: rgba(156, 156, 156, 0.2);
$deep-cove: #060834;
$deep-cove-transparent: #787991;
$color-black: #000000;
$silver: #ededed;
$silver-2: #f4f1fc;
$silver-3: #fcfbff;
$silver-4: #f9f3d4;
$silver-5: #fffeee;
$orange: #fc6915;
$orange-2: #fed400;
$black-light: #222222;
$black-opacity-light: rgba(#222222, 0.2);
$black-light-2: #212020;
$orange-transparent: #fc69153d;
$orange-transparent-weight: rgba(252, 105, 21, 0.5);
$blue-darker: #80bdff;
$black-transparent: #0000003d;
$black-pearl-opacity-1: rgba(3, 13, 39, 0.1);
$black-pearl-opacity-32: rgba(3, 13, 39, 0.32);
$black-opacity-3: rgba(0, 0, 0, 0.3);
$bg-popup-inventory: #f3f3f3;

$governor: #3941bf;
$governor-2: #5815a5;
$bg-box: #9ca0df;
$bg-box-overlay: #06b4ea;
$black-opacity-6: rgba(0, 0, 0, 0.6);
$black-opacity-5: rgba(0, 0, 0, 0.5);
$black-opacity-4: rgba(0, 0, 0, 0.4);
$black-light-2: #212020;
$bor-input: #edf0f3;
$blue-2: #0a0e2a;
$color-input: #999999;
$black-opacity-2: rgba(0, 0, 0, 0.2);
$governor: #3941bf;
$wistful: rgba(0, 123, 255, 0.25);
$blue: #0926bc;
$cherry: #e52222;
$red: #e00000;
$color-price: #f81c3b;
$color-price-detail: #828282;
$boder-table: #dee2e6;
$alto-ess: #eee;
$gray-2: #5c6873;
$gray-3: #e4e7ea;
$gray-4: #5d2936;
$gray-5: #958281;
$blue-linght: #1b8eb7;
$green: #56bf5c;
$btn-primary: #007bff;
$yellow: #ff8534;
$d1: #76dc4a;
$d2: #4ec6e8;
$d3: #ff9044;
$d4: #41c7c8;
$boder-table: #dee2e6;
$alto-ess: #eee;
$green-2: #86d637;
$blue-linght: #1b8eb7;
$bg-button: #5a6268;

$bg-sidebar: #2f353a;
$input-error: $cherry;
$textBody: #23282c;
$bg-disabled: #e0e0e0;
$color-disabled: hsl(0, 0%, 40%);
$bg-disabled: #f2f2f2;
$color-disabled: hsl(0, 0%, 60%);
$bg-box1: #588ebc;
$bg-box2: #44b6ae;
$bg-box3: #8676a7;
$color-box1: #41c77a;
$color-box2: #258e87;
$color-box3: #6b5696;
$gray-2: #5c6873;
$gray-3: #e4e7ea;
$btn-primary: #007bff;
$black-shadow: #0a0e2a;
$color-light: #999999;
$gray-2: #a2a2a2;
$gray-3: #878a9f;
$black-active: #000000;
$color-black-3: #333333;
$color-purple: #973edb;
$bunting: #0f153a;
$athens-gray: #eff2f5;
$athens-gray-2: #fafbfc;
$manatee: #878a9f;
$bg-level1: rgba(10, 14, 42, 0.6);
$bg-level2: rgba(10, 14, 42, 0.8);
$bg-level3: #0a0e2a;
$bg-level4: rgba(10, 14, 42, 0.4);
$color-navy: #314aaf;
$bg-level5: #314aaf;
/**
 * Variables for button
*/
$btn-primary-bg: $orange;
$btn-transparent-bg: $white;
$btn-secondary-bg: $silver;
$btn-outline-bg: $white;
$btn-color-primary: $white;
$btn-outline-color: $orange;
$btn-transparent-color: $dove-gray-light;
$btn-outline-border: $orange;
$btn-transparent-border: $gray-light;
$bg-black-2: rgba(0, 0, 0, 0.2);
$bg-black-07: rgba(0, 0, 0, 0.07);


/**
* Font
*/
$Roboto: 'Roboto',
sans-serif;
$NanumSquare-Font: 'NanumSquare',
sans-serif;
$thin: 100;
$extraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;
$black: 900;

/* Variable for input
*/
$input-default-border: $gray;
$input-color-default: $gray-sec;

$background-color: #e4e5e6;
$bg-button-primary: #ea5979;
$bg-opacity-primary: rgba(#be5050, 0.05);
$bg-opacity-primary-5: rgba(#be5050, 0.5);
$bg-opacity-primary-15: rgba(#be5050, 0.15);
$bg-opacity-primary-1: rgba(#be5050, 0.1);

$border-box: #eff2f5;
$bg-box: #fafbfc;
$bg-box-accordion: rgba(#edf0f3, 0.3);
$bg-rank: rgba(#dfe0e8, 0.16);
$border-list-order: #33374e;
$border-list-order2: rgba(#33374e, 0.4);
$bg-reduction: #eef7ff;
$bg-increase: #ffefef;
$bg-stability: #f5f5f5;
$color-reduction: #2181ff;
$color-increase: #e52222;
$color-stability: #0f153a;
$black-opacity-7: rgba(0, 0, 0, 0.7);
$white-opacity-5: rgba(255, 255, 255, 0.5);
$manatee-opacity-7: rgba(#878a9f, 0.7);
$manatee-opacity-2: rgba(#878a9f, 0.2);
$manatee-opacity-6: rgba(#878a9f, 0.3);
$bg-price-opacity-2: rgba(#0a0e2a, 0.2);
$color-number-increase: #8699fe;
$color-icon: #a3a3a3;
$color-icon-2: #b5b4c2;
$bor-right-payment: rgba(#c4ced8, 0.3);
$bg-deposit: #ff5454;
$border-right: #cccccc;
$bg-active: #f5f7fa;
$bg-box-focus: rgba(#fafbfc, 0.2);
$white-focus: rgba(#ffffff, 0.2);
$white:#ffffff;
$white-opacity-8: rgba(#ffffff, 0.8);
$color-logout-focus: #dfdfdf;
$color-alert: #ff0000;
$color-icon-arrow: #878a9f;
$bd-transparent: rgba(193, 203, 215, 0.65);
$bg-content: #f6f7f9;
$silver2: #ebeef2;
$color-black-opacity3: rgba(#333333, 0.7);

// add css date 02/11/2020
$popup-txt: #222222;
$popup-btn-left: #b8b8b8;
$popup-btn-right: #222222;
$popup-border: #eeeeee;
$color-placeholder: #b8b8b8;
$btn-bg: #be5050;
$btn-off: #ececec;
$border-footer: #e2e2e2;
$text-footer-active:#824b4b;
$box-age: #46bcb1;
$box-age2: #ffc233;
$box-age3: #7944b7;
$box-age4: #5067ff;
$box-active: rgba(#ff6161, 0.5);
$text-box-active: #c52321;
$border-button: #ebebeb;
$color-after: #707070;
$color-after-3: rgba(#707070, 0.03);
$color-after-2: rgba(#707070, 0.2);
$border-category: #e5e5e5;
$border-category-5: rgba(#e5e5e5, 0.5);
$white-opacity-1: rgba(255, 255, 255, 0.1);
$white-opacity-2: rgba(255, 255, 255, 0.2);
$white-opacity-3: rgba(255, 255, 255, 0.3);
$white-opacity-4: rgba(255, 255, 255, 0.4);
$white-opacity-5: rgba(255, 255, 255, 0.5);
$white-opacity-6: rgba(255, 255, 255, 0.6);
$white-opacity-7: rgba(255, 255, 255, 0.7);
$white-opacity-8: rgba(255, 255, 255, 0.8);
$white-opacity-9: rgba(255, 255, 255, 0.9);
$bg-active-tab: rgba(#be5050, 0.15);
$black-opacity-2: rgba(0, 0, 0, 0.2);
$black-opacity-3: rgba(0, 0, 0, 0.3);
$black-opacity-4: rgba(0, 0, 0, 0.4);
$black-opacity-5: rgba(0, 0, 0, 0.5);
$black-opacity-6: rgba(0, 0, 0, 0.6);
$black-opacity-7: rgba(0, 0, 0, 0.7);
$black-opacity-05: rgba(0, 0, 0, 0.05);
$black-opacity-07: rgba(0, 0, 0, 0.07);
$black-opacity-09: rgba(0, 0, 0, 0.09);
$bg-active-tab: rgba(#be5050, 0.15);
$bg-active-tab-5: rgba(#be5050, 0.05);
$bg-red: #fff8f8;
$border-red: #fbeaea;
$color-topic: #e53939;
$color-active-pagination: #818181;
$border-btn-edit: #444444;
$border-like-comment: rgba(#895abf, 0.5);
$box-kakao: #874300;
$box-payco: #fa2828;

