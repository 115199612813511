.info-header {
  margin-bottom: pxToVW(15);
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  padding: pxToVW(20);
  padding-bottom: pxToVW(10);
  z-index: 9999;
  background-color: $governor-2;

  .bell {
    width: pxToVW(20);
    height: pxToVW(22);
    position: relative;

    span {
      position: absolute;
      font-family: $Roboto;
      font-size: pxToVW(8);
      font-weight: $black;
      line-height: 1.25;
      color: #ffffff;
      display: block;
      width: pxToVW(10);
      height: pxToVW(10);
      right: 0;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      top: 0;
      background-color: $text-box-active;
    }
  }
}

.text-history-push {
  border-radius: pxToVW(10);
  background-color: $white-opacity-2;
  color: $white;
  height: pxToVW(37);
  padding: pxToVW(12) pxToVW(15);
  margin-top: pxToVW(35);
  // display: flex;
  overflow: hidden;

  // .swiper-container {
  //   .swiper-wrapper {
  //     display: flex;
  //   }
  // }

  * {
    font-size: pxToVW(12);
    line-height: pxToVW(13);
    color: $white;
  }
}
