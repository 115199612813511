.button {
  border: 0 solid;
  background-color: $orange-2;
  border-color: $orange-2;
  font-size: pxToVW(17);
  line-height: pxToVW(25);
  color: $white;
  padding: pxToVW(14) pxToVW(10);
  margin: 0;
  height: pxToVW(57);
  font-weight: $black;
  width: 100%;
  color: $black-light-3;

  &.fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
  }

  &.index9999 {
    z-index: 9999;
  }

  &.absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &--secondary {
    background-color: $silver;
    color: $black-light-3;
  }

  &--disabled,
  &:disabled {
    background-color: $border-button;
    border: none;
    opacity: 0.5;
  }

  &--left {
    background-color: $gallery;
    color: $black-light-3;
  }

  &--right {
    &--blue {
      background-color: $blue;
      color: $white;
    }

    &--red {
      background-color: $cherry;
    }
  }

  &--mw-100 {
    width: pxToVW(100);
  }

  &--w-100 {
    width: calc(100% - #{pxToVW(100)});
  }

  &__icon {
    height: pxToVW(25);
    border-radius: pxToVW(3);
    padding-right: pxToVW(15);

    &:after {
      content: "";
      border: solid $white;
      border-width: 0 pxToVW(0.5) pxToVW(0.5) 0;
      display: inline-block;
      padding: pxToVW(2);
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      position: relative;
      top: pxToVW(-4);
      right: pxToVW(-5);
    }
  }
}