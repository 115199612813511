.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  @media screen and (min-width: 767px) {
    height: calc(100vh - 360px);
  }
}

body {
  font-size: pxToVW(13);
  font-weight: $regular;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  img {
    width: 100%;
  }

  a {

    &:active,
    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
      color: initial;
    }
  }
}

div,
p,
img,
h2,
h3,
h1,
button {
  &:focus {
    outline: none;
  }
}

#root,
main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.position-fix {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;
  right: 0;
  left: 0;
}

.position-absolute {
  width: 100%;
  padding-top: pxToVW(80);
  left: 0;
  right: 0;
  bottom: 0;
}

input:disabled {
  background-color: $bor-input;

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    background-color: $bor-input;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // -webkit-text-fill-color: $black-active;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
}

// scroll beautiful
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 pxToVW(6) $black-opacity-3;
  border-radius: pxToVW(10);
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: pxToVW(3);
  background-color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  border-radius: pxToVW(10);
  -webkit-box-shadow: none;
  box-shadow: none;
}