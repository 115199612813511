.wrapper-page-notice {
  .item {
    padding-bottom: pxToVW(20);
    padding-top: pxToVW(20);
    border-bottom: pxToVW(2) solid $bg-stability;
    padding-right: pxToVW(30);
    position: relative;

    .btn-detail {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: pxToVW(6);
      height: pxToVW(12);
      right: 0;
      padding-top: pxToVW(9);
    }

    .time {
      font-family: $Roboto;
      font-size: pxToVW(11);
      line-height: pxToVW(13);
      margin-bottom: pxToVW(3);
      font-weight: 300;
      color: rgba($black-light-3, 0.5);
    }

    .title {
      font-size: pxToVW(14);
      line-height: pxToVW(20);
      color: $black-light-3;
      font-weight: 700;
    }
  }

  &.detail {
    .content {
      font-size: pxToVW(14);
      line-height: pxToVW(20);
      color: $black-light-3;
    }

    img {
      margin-top: pxToVW(10);
      margin-bottom: pxToVW(10);
    }
  }
}

.wrapper-no-data {
  padding-top: pxToVW(220);
  text-align: center;

  img {
    width: pxToVW(105);
    margin-bottom: pxToVW(15);
  }

  .text {
    font-size: pxToVW(14);
    line-height: pxToVW(20);
    color: rgba($black-light-3, 0.5);
  }
}

.wrapper-page-policy {
  font-size: pxToVW(14);
  line-height: pxToVW(20);
  color: $black-light-3;
  margin-top: pxToVW(20);
}

.mt-20 {
  margin-top: pxToVW(20);
}

.mb-10 {
  margin-bottom: pxToVW(10);
}

.faq-detail-page{
  padding: 0px !important;
  .faq-detail{
    margin-top: 51px;
    .title{
      // border-top: 1px solid #e2e4e8;
      border-bottom: 1px solid #e2e4e8;

      padding: 18px 30px;

      .title-text{
        padding: 0px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
      }
      .time{
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: normal;
        text-align: left;
        color: #8f949c;
      }
    }
    .reply{
      padding: 21px 30px;
      .img-detail {
        margin-top: 20px;
        width: 100%;
        height: 100%;
        img{
          border-radius: 12px;
          margin-bottom: 10px;
          width: 100%;
          height: 100%;
        }
      }

      .reply-text{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #767b84;
      }
    }
  }
}

.no-data-annoucement{
  .modal-dialog{
    .modal-content{
      border-radius: 20px;
      box-shadow: 0 0 28px 0 rgba(14, 15, 17, 0.12);
      .modal-body{
        padding-top: 36px;
        .content{
          h3{
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #363b45;
            margin-bottom: 34px;
            white-space: break-spaces;
          }
          button{
            border-radius: 10px;
            background-image: linear-gradient(to left, #fed400, #ffe356);
          }
        }
      }
    }
  }
}