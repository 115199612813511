.input__box {
  text-align: left;

  .css-1pahdxg-control {
    min-height: auto;
    max-height: initial;
    box-shadow: none;
    border-color: transparent;
    background-color: transparent;

    * {
      background-color: transparent;
    }
  }
}

.custom-text {
  .css-b8ldur-Input {
    margin: 0 !important;
    padding: 0 !important;

    input {
      min-height: pxToVW(35);
      max-height: pxToVW(35);
    }

    >div>div {
      padding: 0 !important;
      min-height: pxToVW(35) !important;
      max-height: pxToVW(35) !important;
      overflow: hidden !important;
      text-decoration: none !important;
    }
  }
}

.react-datepicker__header {
  padding-bottom: pxToVW(8);
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
}

.react-datepicker__month-select {
  padding: pxToVW(5);
}

.react-datepicker__year-select {
  padding: pxToVW(5);
}

.customer-select {
  line-height: pxToVW(30);

  .error-msg {
    margin-top: pxToVW(5);
    margin-bottom: pxToVW(5);
    line-height: pxToVW(16);
  }

  .input__box {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      background-image: url('../../assets/images/icon_arrowBB.svg');
      background-repeat: no-repeat;
      width: pxToVW(12);
      height: pxToVW(7);
      top: 67%;
      @include transform-translateY(-50%);
      right: pxToVW(15);
      left: auto;
      background-size: cover;
      background-size: pxToVW(12);
      @include transition(0.3s)
    }

    &.down {
      height: 52px;
      border-radius: 10px;
      background-color: #f5f6f8;
      .css-2b097c-container{
        height: 52px !important;
        border: none !important;
        .css-1pahdxg-control{
          height: 52px !important;
          border: none !important;
          outline: none !important;
          .css-g1d714-ValueContainer{
            height: 52px !important;
            display: flex;
            align-items: center;
            outline: none !important;
            .css-1wa3eu0-placeholder{
              height: 52px !important;
            }
          }
        }
      }
      
      &::before {
        @include rotate(180deg);
        top: calc(50% - #{pxToVW(4)});
      }
    }
  }
}

.dropdown-store{
  height: 52px;
  border-radius: 10px;
  background-color: #f5f6f8;
  .css-yk16xz-control{
    height: 52px !important;
    border: none !important;
    .css-g1d714-ValueContainer{
      height: 52px !important;
      display: flex;
      align-items: center;
      .css-1wa3eu0-placeholder{
        height: 52px !important;
      }
    }
  }
}

.css-tlfecz-indicatorContainer {
  max-height: pxToVW(35);
}

body .customer-select {
  .input__label {
    font-size: 3.88889vw;
    font-weight: 900;
    line-height: 1.4;
    margin-bottom: 2.77778vw;
    display: flex;
  }

  .css-1uccc91-singleValue {
    color: #111111;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: pxToVW(30);
    padding-right: pxToVW(30);
    padding-left: 0 !important;
    font-weight: $black;
    font-family: $NanumSquare-Font;
    font-size: pxToVW(14);
  }

  .css-yk16xz-control {
    min-height: pxToVW(35);
    max-height: pxToVW(35);
    border: pxToVW(1) solid $silver;
    background-color: transparent;

    .css-g1d714-ValueContainer,
    .css-1uccc91-singleValue {
      padding-top: 0 !important;
      padding-left: pxToVW(10);
    }
  }

  .input__box {
    position: relative;
    background: transparent;

    input {
      font-size: pxToVW(14);
      color: $black-light-3;
      font-weight: $black;

      &::-webkit-input-placeholder {
        color: $color-placeholder;
        font-weight: $light;
        font-size: pxToVW(14);
        line-height: pxToVW(20);
        letter-spacing: pxToVW(-0.28);
      }

      &::placeholder {
        color: $color-placeholder;
        font-weight: $light;
        font-size: pxToVW(14);
        line-height: pxToVW(20);
        letter-spacing: pxToVW(-0.28);
      }

      &::-moz-placeholder {
        color: $color-placeholder;
        font-weight: $light;
        font-size: pxToVW(14);
        line-height: pxToVW(20);
        letter-spacing: pxToVW(-0.28);
      }

      &:-ms-input-placeholder {
        color: $color-placeholder;
        font-weight: $light;
        font-size: pxToVW(14);
        line-height: pxToVW(20);
        letter-spacing: pxToVW(-0.28);
      }
    }

    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }

    .icon__wrapper {
      position: absolute;
      top: 50%;
      right: pxToVW(15);
      z-index: -1;
      @include transform-translateY(-50%);

      .icon {
        width: pxToVW(13);
        height: pxToVW(13);
      }
    }
  }

  .css-1uccc91-singleValue {
    top: 50%;
    @include transform-translateY(-50%);
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1pahdxg-control {
    min-height: pxToVW(35);
    max-height: pxToVW(35);
    box-shadow: none;
    // border-color: $bor-input;
    .css-g1d714-ValueContainer,
    .css-1uccc91-singleValue {
      padding-left: pxToVW(10);
      
    }
    .css-62g3xt-dummyInput{
      outline: none !important;
      border: red !important;
      &:focus{
        outline: none !important;
        border: red;
      }
    }

  }

  .css-tlfecz-indicatorContainer {
    padding: pxToVW(7) pxToVW(8);
  }

  .css-26l3qy-menu {
    z-index: 10;
    margin-bottom: pxToVW(60);
  }
}

.customer-select.selectFilter {
  min-width: pxToVW(80);
  width: 100%;
  position: relative;

  .input__box {
    div:nth-child(1) {
      svg {
        display: none;
      }
    }
  }

  .css-2b097c-container {
    min-height: pxToVW(35);
    max-height: pxToVW(35);

    .css-26l3qy-menu {
      min-height: pxToVW(35);
      max-height: pxToVW(35);

      >div>div {
        line-height: 1.4;
      }
    }
  }

  &.selectStatus {
    min-width: pxToVW(45);
    padding-left: pxToVW(5);

    &:before {
      content: "";
      width: pxToVW(1);
      height: pxToVW(10);
      background-color: $hummer;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .css-g1d714-ValueContainer,
    .css-1uccc91-singleValue {
      position: relative;
      min-width: pxToVW(35);
      max-width: 100%;
      text-align: right;
      margin: 0;
      line-height: 1;
      height: pxToVW(18);
      vertical-align: bottom;
      display: flex;
      align-items: flex-end;
    }

    .css-2b097c-container {
      .css-26l3qy-menu {
        right: 0;
        min-width: pxToVW(100);
      }
    }
  }

  .css-g1d714-ValueContainer,
  .css-1uccc91-singleValue {
    height: pxToVW(18);
    line-height: pxToVW(16);
    font-weight: $black;
    font-family: $NanumSquare-Font;
    right: 0;
    padding-right: pxToVW(10);
    font-size: pxToVW(14);
    display: flex;
    align-items: flex-end;
  }

  .input__box {
    white-space: nowrap;

    .icon__wrapper {
      right: 0;

      .icon {
        width: pxToVW(10);
        height: pxToVW(10);
        position: relative;
        top: pxToVW(2);
      }
    }

    .css-yk16xz-control {
      border-color: transparent;
      min-height: auto;
      max-height: inherit;
      margin: 0;
      border: 1px solid red;
      outline: red;
      box-shadow: none;

    }

    .css-1pahdxg-control {
      min-height: auto;
      max-height: initial;
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
      border: 1px solid red;
      outline: red;
      box-shadow: none;

      * {
        background-color: transparent;

      }
    }

    .css-g1d714-ValueContainer {
      padding: 0;
    }

    .css-g1d714-ValueContainer {
      padding: 0;
    }

    .css-tlfecz-indicatorContainer {
      padding: pxToVW(0);
    }
  }

  &.red {

    .css-g1d714-ValueContainer,
    .css-1uccc91-singleValue {

      color: $red;
    }
  }

  &.blue {

    .css-g1d714-ValueContainer,
    .css-1uccc91-singleValue {

      color: $blue;
    }
  }

  &.styleColor {
    .css-2b097c-container {
      .css-26l3qy-menu {
        >div>div {

          &:nth-child(2) {
            color: $red;
          }

          &:nth-child(3) {
            color: $blue;
          }
        }
      }
    }
  }
}

.css-4ljt47-MenuList {
  padding: 0 !important;
}

.css-9gakcf-option {
  background-color: $athens-gray-2 !important;
  color: $black-light-3 !important;
  border-top: pxToVW(1) solid $bor-input;
  border-bottom: pxToVW(1) solid $bor-input;
  font-weight: $medium;
  padding: pxToVW(8) pxToVW(17) !important;
  letter-spacing: pxToVW(-0.33);

  &:nth-child(1) {
    border-top: 0;
  }
}

.css-9gakcf-option:active {
  background-color: $athens-gray-2;
}

.css-yt9ioa-option,
.css-1n7v3ny-option {
  padding: pxToVW(8) pxToVW(17) !important;
  letter-spacing: pxToVW(-0.33);
}

.css-1n7v3ny-option {
  background-color: transparent !important;
}

.css-4ljt47-MenuList,
.css-1m4j08g-MenuList {
  max-height: pxToVW(250) !important;
}

.css-1wa3eu0-placeholder {
  color: $color-placeholder !important;
  font-weight: normal;
  font-size: pxToVW(14);
  max-height: pxToVW(35);
  letter-spacing: pxToVW(-0.38);
  height: 100%;
  line-height: pxToVW(35);
  top: 16% !important;
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      padding-left: pxToVW(5);
    }
  }
}

.customer-select {
  .css-g1d714-ValueContainer {
    padding-top: 0 !important;
  }

  .css-1wa3eu0-placeholder {
    @include transform-translateX(0);
  }
}