.layout-main.MainPage {
  background-color: $white;
  padding: 0;
  width: 100%;

  .header-main {
    padding: pxToVW(20);
    background-color: $governor-2;
    height: pxToVW(200);
    border-bottom-right-radius: pxToVW(60);
  }

  .box-point {
    padding: pxToVW(20);
    background-color: $orange-2;
    // height: pxToVW(255);
    height: pxToVW(188);
    width: calc(100% - #{pxToVW(40)});
    left: 0;
    border-top-left-radius: pxToVW(40);
    border-top-right-radius: pxToVW(40);
    border-bottom-right-radius: pxToVW(40);
    position: absolute;
    top: pxToVW(102);

    &::after {
      content: '';
      position: absolute;
      height: pxToVW(60);
      bottom: pxToVW(-40);
      width: 80%;
      left: 0;
      background-color: $orange-2;
    }

    .icon-tooltip {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      p {
        font-size: pxToVW(11);
        line-height: pxToVW(12);
        color: $black-light-3;
        font-weight: $regular;
      }

      img {
        width: pxToVW(15);
        height: pxToVW(15);
        margin-left: pxToVW(5);
      }
    }

    .group-level {
      .icon {
        text-align: center;
        width: pxToVW(45);
        height: pxToVW(45);
        background-color: $white;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: pxToVW(10);

        img {
          width: pxToVW(40);
          height: pxToVW(28);
        }

        span {
          position: absolute;
          bottom: pxToVW(-6);
          font-size: pxToVW(9);
          font-weight: $black;
          color: $white;
          line-height: 1.11;
          letter-spacing: pxToVW(-0.27);
          text-align: center;
          padding: pxToVW(2) pxToVW(4);
          border-radius: pxToVW(10);
          background-color: $color-black;
          min-width: pxToVW(28);
          left: 50%;
          @include transform-translateX(-50%);
        }
      }

      .desc-level {
        padding-top: pxToVW(7);

        .well {
          font-size: pxToVW(11);
          font-weight: normal;
          line-height: 1.09;
          margin-bottom: pxToVW(2);
        }

        .name {
          font-size: pxToVW(14);
          font-weight: $black;
          line-height: 1.14;
        }
      }
    }

    .point-policy {
      p {
        font-size: pxToVW(12);
        line-height: pxToVW(13);
        font-weight: normal;
        text-align: center;
        color: $black-light-3;
      }
    }

    .point-number {
      text-align: center;
      // margin-top: pxToVW(15);
      font-family: $Roboto;
      font-size: pxToVW(40);
      font-weight: 900;
      line-height: pxToVW(53);
      display: flex;
      align-items: center;
      justify-content: center;

      &.small {
        font-size: pxToVW(30);
        margin-top: pxToVW(25);

        span {
          font-size: pxToVW(15);
        }
      }
      .icon-arrow-right-to-page {
        width: 9px;
        height: 16px;
        margin-left: 10px;
        margin-top: 2px;
      }
      span {
        margin-left: pxToVW(5);
        font-size: pxToVW(20);
        font-weight: $black;
        line-height: 1.2;
        font-family: $Roboto;
      }
    }
  }

  .main-content {
    margin-top: pxToVW(90);
    position: relative;
    z-index: 2;
    background-color: $white;
    padding: pxToVW(40) pxToVW(20) pxToVW(0) pxToVW(20);
    border-top-left-radius: pxToVW(50);

    .title {
      margin-bottom: pxToVW(10);
      font-size: pxToVW(14);
      font-weight: $black;
      line-height: 1.14;
    }

    .action-add {
      position: absolute;
      right: pxToVW(20);
      top: pxToVW(-35);
      width: pxToVW(131);
      height: pxToVW(50);
      border-radius: pxToVW(15);
      box-shadow: 0 pxToVW(3) pxToVW(6) 0 rgba(0, 0, 0, 0.16);
      background-color: $color-black;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: pxToVW(18) pxToVW(15);

      img {
        width: pxToVW(22);
        height: pxToVW(14);
        margin-right: pxToVW(5);
      }

      // div {
      //   padding: pxToVW(10);
      // }

      * {
        font-size: pxToVW(14);
        font-weight: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: $white;
        cursor: pointer;
      }
    }

    .group-btn-action {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: pxToVW(20);

      .btn {
        cursor: pointer;
        font-size: pxToVW(14);
        width: pxToVW(100);
        height: pxToVW(36);
        font-weight: $black;
        margin-right: pxToVW(10);
        padding: pxToVW(8) pxToVW(5);
        border-radius: pxToVW(20);
        background-color: $wild-sand;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.add {
          padding-top: pxToVW(10);
          font-size: pxToVW(11);
          background-color: $border-button;
          color: rgba($black-light-3, 0.5);
          margin-right: 0;

          img {
            width: pxToVW(9);
            height: pxToVW(9);
            margin: pxToVW(3) 4px 0 0;
          }
        }
      }
    }

    .list-product {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-left: pxToVW(-5);
      margin-right: pxToVW(-5);

      position: relative;
      margin-bottom: 0;
      position: relative;
      min-height: pxToRem(150);

      &.mb-160 {
        margin-bottom: pxToRem(160);
      }

      .non-data {
        img {
          width: pxToRem(50);
          height: pxToRem(54);
        }
      }

      .item-product {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding: 0 pxToVW(5);
        margin-bottom: pxToVW(15);
        position: relative;

        .icon-delete {
          position: absolute;
          width: pxToVW(26);
          height: pxToVW(26);
          padding: pxToVW(7);
          background-color: $color-black;
          top: 0;
          right: pxToVW(5);
          cursor: pointer;
        }

        .product-img {
          img {
            width: pxToVW(100);
            height: pxToVW(100);
            object-fit: cover;
          }

          margin-bottom: pxToVW(5);
        }

        .status {
          font-size: pxToVW(9);
          min-width: pxToVW(39);
          height: pxToVW(16);
          padding: pxToVW(3) pxToVW(7);
          border-radius: pxToVW(9);
          display: inline-block;
          margin-bottom: pxToVW(5);
          background-color: $text-box-active;
          line-height: 1.2;
          color: $white;
          max-width: pxToVW(90);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .product-name {
          font-size: pxToVW(12);
          font-weight: $medium;
          line-height: 1.08;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .popup-barcode {
    &.overlay {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      z-index: 9999;
      background-color: rgba($color: $color-black, $alpha: 0.5);
    }
  }
}

.list-store-like {
  overflow: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
    background-color: $btn-bg;
    height: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
    background-color: $btn-bg;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $btn-bg;
    border: 0 solid $btn-bg;
    height: 0;
  }
}

.wrapper-main {
  position: relative;

  &.popup {
    height: 100vh;
  }

  .popup-tooltip {
    width: pxToVW(320);
    height: pxToVW(230);
    z-index: 100000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;

    img {
      width: pxToVW(15);
      height: pxToVW(15);
      position: absolute;
      right: pxToVW(20);
      top: pxToVW(32);
    }
  }

  .barcode {
    background-color: $white;
    padding: pxToVW(40) pxToVW(30) pxToVW(52) pxToVW(30);
    box-shadow: 0 pxToVW(-5) pxToVW(15) 0 rgba(0, 0, 0, 0.2);
    border-top-left-radius: pxToVW(30);
    border-top-right-radius: pxToVW(30);
    position: fixed;
    bottom: 0;
    // top: pxToVW(450);
    top: pxToVW(100);
    width: 100%;
    z-index: 100000;

    &::after {
      content: '';
      position: absolute;
      top: pxToVW(10);
      left: 50%;
      transform: translateX(-50%);
      width: pxToVW(100);
      height: pxToVW(8);
      background-color: $black-light-3;
      border-radius: pxToVW(5);
    }

    .overlay-barcode {
      position: fixed;
      top: calc(100vh - #{pxToVW(466)});
      left: 0;
      z-index: 100001;
      width: 100%;
      height: pxToVW(380);
      border-top-left-radius: pxToVW(30);
      border-top-right-radius: pxToVW(30);
    }

    .image-barcode {
      width: pxToVW(300);

      img {
        width: pxToVW(300);
        height: pxToVW(200);
      }

      p {
        font-size: pxToVW(14);
        line-height: pxToVW(19);
        font-family: Roboto;
        font-weight: $medium;
        color: $color-black-3;
        text-align: center;
      }
    }

    .barcode-validity {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: pxToVW(25);

      p,
      span {
        font-size: pxToVW(14);
        line-height: pxToVW(16);
        font-family: NanumSquareB;
        color: $text-box-active;
      }

      &--icon {
        width: pxToVW(40);
        height: pxToVW(40);
        // margin-left: pxToVW(10);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: pxToVW(20);
          height: pxToVW(20);
        }
      }
    }

    &.back {
      animation: touchDownBarcode 0.6s ease-in-out both;

      @keyframes touchDownBarcode {
        0% {
          top: calc(100vh - #{pxToVW(466)});
        }

        100% {
          top: pxToVW(1000);
        }
      }
    }

    &.touchend {
      animation: touchUpBarcode 0.6s ease-in-out both;

      @keyframes touchUpBarcode {
        0% {
          top: pxToVW(1000);
        }

        100% {
          top: calc(100vh - #{pxToVW(466)});
        }
      }
    }

    // @media screen and (max-height: 640px) {
    //   &.back {
    //     animation: touchDownBarcode 0.6s ease-in-out both;

    //     @keyframes touchDownBarcode {
    //       0% {
    //         top: pxToVW(100);
    //       }

    //       100% {
    //         top: pxToVW(1000);
    //       }
    //     }
    //   }

    //   &.touchend {
    //     animation: touchUpBarcode 0.6s ease-in-out both;

    //     @keyframes touchUpBarcode {
    //       0% {
    //         top: pxToVW(1000);
    //       }

    //       100% {
    //         top: pxToVW(100);
    //       }
    //     }
    //   }

    //   .overlay-barcode {
    //     top: pxToVW(100);
    //   }
    // }

    // @media screen and (min-height: 640px) and (min-width: 400px) {
    //   &.back {
    //     animation: touchDownBarcode 0.6s ease-in-out both;

    //     @keyframes touchDownBarcode {
    //       0% {
    //         top: pxToVW(150);
    //       }

    //       100% {
    //         top: pxToVW(1000);
    //       }
    //     }
    //   }

    //   &.touchend {
    //     animation: touchUpBarcode 0.6s ease-in-out both;

    //     @keyframes touchUpBarcode {
    //       0% {
    //         top: pxToVW(1000);
    //       }

    //       100% {
    //         top: pxToVW(150);
    //       }
    //     }
    //   }

    //   .overlay-barcode {
    //     top: pxToVW(100);
    //   }
    // }

    .number-point {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: pxToVW(12) pxToVW(20);
      border-radius: pxToVW(10);
      background-color: $bg-stability;
      margin-bottom: pxToVW(25);

      label {
        font-size: pxToVW(14);
        font-weight: normal;
        line-height: 1.14;
        text-align: left;
        color: $black-light-3;
        font-family: NanumSquareEB;
      }

      .price {
        margin-left: pxToVW(20);
        font-family: $Roboto;
        font-size: pxToVW(23);
        font-weight: 900;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: right;
        color: $governor-2;
      }
    }

    .loading-barcode {
      width: 100%;
      height: pxToVW(200);
      justify-content: center;
    }

    .text-desc {
      font-size: pxToVW(14);
      font-weight: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      // margin-bottom: pxToVW(15);
      color: $black-light-3;
      text-align: center;
    }
  }
  .purchase-reservation-history {
    margin-bottom: 30px;
    &__title {
      margin-bottom: 10px;
      font-family: NanumSquareEB;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #111;
      position: relative;
      display: inline-block;
      .quantity-product {
        margin: 0;
        width: 20px;
          height: 20px;
          background-color: pink;
          border-radius: 50%;
          display: block;
          position: absolute;
          right: -15px;
          top: 50%;
          transform: translateY(-60%);
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 12px;
      }
      
    }
    &__card {
      position: relative;
      margin-right: 10px;
      .card-item {
        padding: 15px 0px 15px 15px;
        border-radius: 15px;
        box-shadow: 0 1px 5px 0 #f3f3f3;
        border: solid 1px rgba(245, 235, 246, 0.5);
        background-color: var(--white);

        margin-right: 10px;
        display: flex;
        align-items: center;
        &__avatar {
          width: 55px;
          height: 55px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: auto;
            display: block;
          }
        }
        &__info {
          width: calc(100% - 65px);
          &__name {
            font-family: NanumSquareEB;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #111;
            padding-bottom: 8px;
            width: calc(100% - 45px);
          }
          &__store {
            &__label {
              font-family: NanumSquareR;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: -0.36px;
              text-align: left;
              color: #111;
              padding-right: 5px;
            }
            &__value {
              font-family: NanumSquareB;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: -0.24px;
              text-align: left;
              color: #111;
            }
          }
          &__date {
            &__label {
              font-family: NanumSquareR;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: -0.36px;
              text-align: left;
              color: #111;
              padding-right: 5px;
            }
            &__value {
              font-family: NanumSquareB;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: -0.24px;
              text-align: left;
              color: #111;
            }
          }
        }
      }
      .icon-card {
        width: 73px;
        object-fit: cover;
        height: 118px;
        position: absolute;
        right: -23px;
        top: -28px;
      }
    }
    &__data-empty {
      text-align: center;
      padding-top: 10px;
      label{
        font-family: NanumSquareB;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        text-align: left;
        color: #111;
      }
    }
  }
}
.modal-notice-home-page {
  .content {
    //overflow-y: scroll;
    position: relative;
    .close-popup-img {
      width: 22px;
      height: 22px;
      position: absolute;
      right: 0;
      top: -25px;
    }
  }
  .content-img-popup {
    position: relative;
    .img-btn-to-order {
      position: absolute;
      left: 50%;
      bottom: 15px;
      transform: translateX(-50%);
      //min-width: 130px;
      //min-height: 35px;
      background-color: transparent;
    }
  }
  .image-content {
    //height: 440px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .modal-dialog {
    max-width: 100vw;
    padding: 0;
    margin: auto;
    width: calc(100vw - 40px);
  }
  .modal-content {
    padding-bottom: 80px;
    background: transparent;
    .modal-body {
      background: #fff;
    }
  }
  .modal-body {
    padding: 0;
    .choose-hide-notice {
      //height: 38px;
      background: #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //padding: 0 20px;
      background: #fff;
      &__item {
        width: calc(100%/2);
        text-align: center !important;
        padding: 8px;
        &:first-child {
          border-right: 1px solid #80808059;
        }
      }
      .choose-item {
        .form-check {
          .form-check-label {
            white-space: nowrap;
          }
          .form-check-input {
            width: 11px;
            height: 11px;
            background-color: #000;
            border: 1px solid #fff;
            border-radius: 2px;
            &:checked[type=checkbox] {
              //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
            }
          }
        }
        img {
          width: 11px;
          height: 11px;
          object-fit: cover;
          margin-right: 5px;
        }
        label {
          color: #fff;
          font-family: NanumSquareR;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.75;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          opacity: .9;
        }
      }
      .close-modal-notice {
        font-family: NanumSquareR;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        opacity: .9;
      }
    }
    
  }
  @media only screen and (max-width: 360px) {
    .image-content {
      //height: 370px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .image-content {
      //height: 370px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.update-event-modal {
    .modal-body {
      background-color: transparent;
      .content {
        .choose-hide-notice {
          padding: 0 30px;
          &__item {
            padding: 12px 0;
            text-align: right !important;
          }
        }

        .choose-item {
          text-align: left !important;
          border-right: 0;
          .form-check {
            padding-left: 0;
            .form-check-label {
              white-space: nowrap;
              vertical-align: text-top;
            }
            .form-check-input {
              width: 16px;
              height: 16px;
              background-color: #fff;
              border: 1px solid #d5d5d5;
              border-radius: 2px;
              &:checked[type=checkbox] {
                //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
              }
            }
            .checkbox {
              .checkbox__label::before {
                height: 16px;
                width: 16px;
                border: 1px solid #d5d5d5;
                left: -1px;
                border-radius: 2px;
                top: 50%;
                transform: translateY(-50%);
              }
              .checkbox__label::after {
                height: 6px;
                width: 9px;
                top: 6px;
              }
              .checkbox__label {
                span {
                  font-family: NanumSquareR;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.75;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000;
                  opacity: .9;
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
      .content-img-popup {
        height: auto !important;

        img {
          margin: auto;
          display: block;
        }
      }
      .swiper-container {
        .img-btn-to-order {
          position: absolute;
          left: 50%;
          bottom: 15px;
          transform: translateX(-50%);
          //min-width: 130px;
          //min-height: 35px;
          background-color: transparent;
          z-index: 9999;
        }
        .swiper-pagination {
          position: absolute;
          bottom: 5px;
          opacity: 1;
          z-index: 9999999;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            opacity: .8;
            box-shadow: 0 2px 2px 0 var(--black-16);
            background-color: var(--white);
            display: inline-block;
            border-radius: 50%;
            margin: 0 7px 0 0;
            &.swiper-pagination-bullet-active {
              width: 30px;
              height: 7px;
              border-radius: 3px;
              background-color: var(--white);
            }
          }
        }
      }
      .swiper-slide {
        .item {
          position: relative;
          background: white;
          border-radius: 32px;
          img {
            margin: auto;
            display: block;
          }
        }
      }
    }
    .modal-content {
      padding-bottom: 0;
      position: absolute;
      bottom: 0;
      transform: initial;
      top: auto;
    }
    .modal-dialog {
      width: 100%;
    }
  }
  &.modal-maintenance-home-page {
    .choose-hide-notice {
      background-color:#f5f5f5;
    }
  }
}

.note-notice-bottom {
  position:fixed;
  bottom: 0;
  width: 100%;
  z-index: 999999;
  opacity: 1;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    &__icon {
      width: 82px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    label {
      font-family: NanumSquareB;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: var(--white);
      margin-left: -40px;
    }
  }
  .tap-icon {
    width: 72px;
    height: 72px;
    margin-left: 20%;
    height: 20vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 375px) {
    &__item {
      margin-left: 10px;
      &__icon {
        width: 70px;
        height: 46px;
        img {

        }
      }
      label {
        margin-left: -35px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {

  .modal-notice-home-page .modal-content {
    padding-bottom: 80px;
  }
}
@media only screen and (max-height: 575px) {
  .note-notice-bottom__item label {
    font-size: 13px;
  }
  .note-notice-bottom__item__icon {
    width: 60px;
  }
  .note-notice-bottom__item {
    margin-bottom: -8px;
  }
  .modal-notice-home-page .modal-content {
    padding-bottom: 135px;
  }

  #popup-wrapper {
    max-height: 432px !important;
  }
}