.wrapper-header{
  &--playground{
    .app-sub-header{
      &__content{
        &__text{
          span{
            font-family: Pretendard;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            text-align: center;
            color: #0e0f11;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.modal-playground {
  &__content{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 9999;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    ul{
      text-align: center;
      
      li{
        padding: 15px 20px;
        span{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.4px;
          text-align: center;
          color: #0e0f11;
        }
        &.default{
          border-bottom: 1px solid #f2f2f2;
          .item-selected{
            position: relative;
            .selected{
              margin-right: 4px;
              font-family: Pretendard;
              font-size: 18px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.17;
              letter-spacing: -0.45px;
              text-align: center;
              color: #0e0f11;
            }
            .img-close{
              position: absolute;
              right: 6px;
              top: 3px; 
            }
          }
        }
        &.playground{
          padding-bottom: 0px;
          span{
          }
          .line{
            // width: calc(100% - 40px);
            margin-top: 16px;
            height: 1px;
            background-color: #f2f2f2;
          }
        }
        &.active{
          span{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: -0.4px;
            text-align: center;
            color: #fed400;
          }
        }
        &:last-child{
          margin-bottom: 10px;
        }
      }
    }
  }
  &.overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(14, 15, 17, 0.4);
  }
}


.wrapper-header{
  &--detail{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    z-index: 9999;
    .content{
      position: relative;
      padding: 0px 20px;
      .app-sub-header{
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 43px;
        position: relative;
        &__left{
          display: flex;
          justify-content: left;
          align-items: center;
          .icon-back{
            margin-right: 19px;
          }
        }
        &_right{
          // display: flex;
        }
      }
      .action-detail{
        position: absolute;
        right: 13px;
        top: 32px;
        background: #fff;
        ul{
          width: 68px;
          border-radius: 7px;
          box-shadow: 0 2px 8px 0 rgba(160, 168, 180, 0.1);
          border: solid 1px #dfe2e6;
          li{
            padding: 9px 20px;
            &:first-child{
              border-bottom: 1px solid #f2f2f2;
            }
            span{
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: left;
              color: #0e0f11;
            }
          }
        }
      }
      .box-dropdown-action-detail {
        .action-detail {
          right: -3px;
        }
      }
    }
  }
}