.main-order {
  padding-top: 0;
  z-index: 0;
  .page-store .setting-header {
    z-index: 100;
  }
  .setting-header {
    .header-title {
      margin-bottom: 0;
    }

    .content-select {
      padding-left: 0;
      padding-right: 0;
      padding-top: 14.66667vw;

      &__item {
        position: relative;
        width: 100%;
        padding-left: 5.55556vw;
        padding-right: 5.55556vw;
        min-height: 42px;

        .order-select {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px 20px;
          height: 42px;

          .icon_location {
            width: 20px;
            height: 22px;
            display: block;
            object-fit: cover;
            margin: auto 5px;
            z-index: 111;
          }

          .icon_arrowBB {
            width: 12px;
            height: 7px;
            display: block;
            object-fit: cover;
            margin: auto 5px;
            z-index: 111;
          }
        }
      }
    }

  }

  .page-store {
    position: relative;
    text-align: center;
    justify-content: center;

    .text-order {
      color: white;
      z-index: 20;
      font-family: NanumSquareB;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: -0.36px;
      text-align: center;
      color: $white;
      min-width: 245px;
      margin-top: 3px;
      &.have-store {
        min-width: auto;
      }
    }

    .history-order {
      width: pxToVW(320);
      height: 100%;
      padding: 10px 19px 10px 20px;
      opacity: 0.2;
      border-radius: 21px;
      background-color: #080011;
      position: absolute;
      z-index: 10;
    }

    .icon_guya_image {
      position: absolute;
      top: -10px;
      right: 0;
      z-index: 90;
    }
  }

  .body-main {
    margin-bottom: 24px;
    //border-bottom: 2px solid #f5f5f5;
    padding-bottom: 20px;
    &:first-child{
      margin-top: 105px;
    }
    &:last-child{
      border-bottom: none;
    }
    // height: pxToVW(580);
    .image-box {
      width: pxToVW(320);
      height: pxToVW(160);
      position: relative;
      object-fit: contain;
      border-radius: 5px;

      .image-box-card {
        width: pxToVW(320);
        height: pxToVW(160);

        .img_card {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .time-style {
        position: absolute;
        top: 0;
        right: 0;
        //width: 100px;
        height: 34px;
        z-index: 5;
        padding: 8px 4px 8px 9px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0px 5px 0px 5px;

        .time-style-number {
          width: 5px;
          height: 18px;
          margin: 0 3px 0 0;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 0.7px;
          text-align: center;
          color: #ff4040;
        }

        .time-style-letter {
          width: 22px;
          height: 13px;
          margin: 3px 0 2px 3px;
          font-family: NanumSquareB;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: $white;
        }
      }
    }

    // .image-order:before {
    //   position: absolute;
    //   background-color: #111;
    //   opacity: 0.5;
    //   content: '';
    //   width: pxToVW(320);
    //   height: pxToVW(160);
    //   top: 0;
    //   left: 0;
    //   z-index: 10;
    // } 
    .image-noti {
      position: absolute;
      background-color: transparent;
      content: '';
      width: pxToVW(320);
      height: pxToVW(160);
      top: 0;
      left: 0;
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        font-family: NanumSquareB;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $white;
      }

    }

    .image-cover {
      position: absolute;
      background-color: rgba(14, 15, 17, 0.5);
      opacity: 0.7;
      content: '';
      width: pxToVW(320);
      height: pxToVW(160);
      top: 0;
      left: 0;
      border-radius: 5px;
      z-index: 10;
    }

    .Random-card {
      padding-top: pxToVW(10);

      .title-card {
        font-family: NanumSquareEB;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #111;
      }
    }

    .text-box {

      position: relative;

      .notification {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: palevioletred;
        text-align: center;
        position: absolute;
        margin-top: -5px;

        .text-notification {
          font-family: NanumSquareEB;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          color: $white;



        }

      }

      .text-price {
        margin-top: 10px;

        .text-Pick-up-date {
          width: 30px;
          height: 12px;
          margin: 12px 3px 6px 0px;
          font-family: NanumSquareR;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          text-align: left;
        }

        .text-money {
          font-family: NanumSquareEB;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #5815a5;
          margin-top: pxToVW(25);
        }
      }

      .text-third {
        position: relative;

        .icon-image {
          margin-top: 2%;
          border-radius: 5px;

          .yellowbubble {
            width: pxToVW(237);
            height: pxToVW(38);
          }
          @media screen and (max-width: 362px) {
            .yellowbubble {
              width: pxToVW(246);
            }
          }
          @media screen and (max-width: 280px) {
            .yellowbubble {
              width: 100%;
              min-height: 38px;
            }
          }
        }

        .text-notification {
          position: absolute;
          margin-top: 15px;
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.82;
          letter-spacing: normal;
          text-align: left;
          color: #111;
          @media screen and (max-width: 362px) {
            margin-top: 14px;
          }
          .text-total {
            font-family: NanumSquareEB;
            margin-right: 5px;
          }

          .text-multi-sale {
            font-family: NanumSquareEB;
            color: #ff4040;
            margin-right: 5px;
            margin-left: 5px;
          }

          .icon-bboki {
            width: 17px;
            height: 19px;
            margin-left: 8px;
            margin-right: 2px;
          }

        }
      }

    }
  }
}
.detail-book {
  background-color: #fffbec;
  //background-size: contain;
  background-image: url('../../../assets/images/bg_order_list.svg') !important;

}
.detail-book {
  padding: 0 !important;
  z-index: 0;
  .icon-back {
    position: fixed;
    left: 10px;
    top: 15px;
    z-index: 9999;
    width: 25px;
    text-align: center;
    &__img {
      width: 8px;
      height: 14px;
      object-fit: cover;
    }
  }
  .wrapper-header {
    min-height: 21px;
    margin-bottom: 70px;
    .app-sub-header {
      &__content {
        background-color: transparent;
        &__text {
          span {
            font-family: Pretendard;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: #0e0f11;
          }
        }
      }
    }
  }

  .setting-header {
    background-color: transparent;

    .icon_top {
      width: 11px;
      height: 20px;

      .icon_top_arrow {
        height: 20px;
        margin-left: 23px;
      }
    }

    .header-title {
      margin-bottom: 0;
      background-color: transparent;
    }

    .content-select {
      padding-left: 0;
      padding-right: 0;
      padding-top: 18.66667vw;

      &__item {
        position: relative;
        width: 100%;
        padding-left: 5.55556vw;
        padding-right: 5.55556vw;
        min-height: 42px;

        .order-select {
          display: flex;
          justify-content: space-between;

          .location {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 7px 5px;
            height: 42px;
            flex-direction: row;

            .icon_location {
              width: 12px;
              height: 14px;
              display: block;
              object-fit: cover;
              margin: auto 5px;
              margin: 5px 3px auto 5px;
              z-index: 111;
            }

            .text-location {
              color: white;
              z-index: 20;
              font-family: NanumSquareEB;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: -0.49px;
              color: $white;
            }

            .text-get-from {
              z-index: 20;
              margin: 0 0 0 2px;
              font-family: SaffronColdWar;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: -0.78px;
              color: var(--white);
            }
          }

          .change-store {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 7px 5px;
            height: 42px;
            flex-direction: row;
            z-index: 99;
            .icon_location {
              width: 12px;
              height: 14px;
              display: block;
              object-fit: cover;
              margin: auto 5px;
            }

            .text-change-store {
              font-family: NanumSquareB;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: -0.36px;
              text-align: left;
              color: var(--white);
            }

            .icon_arrow_right {
              width: 8px;
              height: 12px;
              margin: 1px 0 0 3px;
              margin: auto 5px;
              z-index: 111;
            }
          }


        }
      }
    }

  }

  .page-store {
    position: relative;
    text-align: center;
    justify-content: center;

    .history-order {
      width: pxToVW(320);
      height: 100%;
      padding: 6px 18px 10px 6px;
      opacity: 0.2;
      border-radius: 21px;
      background-color: #080011;
      position: absolute;
      z-index: 10;
    }

    .icon_guya_image {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 90;
    }
  }
  .box-info-store {
    margin-bottom: 25px;
    &__icon {
      width: 65px;
      height: 65px;
      display: block;
      margin: auto;
    }
    &__store-name {
      font-family: Pretendard;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #363b45;
      margin-top: 25px;
      margin-bottom: 15px;
      span {
        font-weight: 600;
      }
    }
    &__change-store {
      margin: auto;
      display: block;
      padding: 9px 18px 10px;
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 rgba(164, 149, 75, 0.56);
      background-color: #ff4e4e;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: var(--white);
      border: none;
    }
  }
  .body-main {
    //margin-top: 31.66667vw;
    height: 65vh;
    background-color: white;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    .info-card-product {
      padding-top: 10px;
      .item-info {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        &__img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 4px;
          border-radius: 50%;
        }
        &__value {
          margin-left: 10px;
          &__name {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #0e0f11;
          }
          &__price {
            .price-content {
              opacity: 0.8;
              font-family: NanumSquareR;
              font-size: 14px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
            .label-content {
              opacity: 0.8;
              font-family: NanumSquareR;
              font-size: 11px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.09;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
        }
      }
      .item-quantity {
        padding: 11px 0px;
        border-radius: 10px;
        background-color: #fff;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #dfe2e6;
        border-radius: 0;
        &__content {
          display: flex;
          align-items: center;
          .item-quantity__content__select {
            min-width: 60px;
            background: white;
            height: 27px;
            .customer-select, .css-2b097c-container,.input__box  {
              height: 100%;

            }
            .input__box  {
              .css-b8ldur-Input input {
                height: 100%;
                min-height: 100%;
                max-height: 9.72222vw;
              }
              &::before {
                background-image: url('../../../assets/images/icon_miniarrow_up_down.svg');
                width: 8px;
                height: 6px;
                background-size: contain;
                margin-top: -1px !important;
                right: 7px;
                transform: none;
                top: 50%;
              }
              &.down {
                &::before {
                  margin-top: 0px;
                }
              }
            }
            .customer-select .css-yk16xz-control {
              min-height: auto;
              max-height: inherit;
              height: 100%;
              div {
                height: 100%;
              }
              .css-1wy0on6 {
                pointer-events: none;
              }

            }
            .css-1uccc91-singleValue {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.21;
              letter-spacing: normal;
              text-align: right;
              color: var(--black);
              height: auto !important;
              right: 0;
              padding-right: 5px;
              bottom: 0;
              min-width: 35px;
              text-align: left;
              margin-top: -1px;
            }
            .css-1pahdxg-control {
              min-height: auto;
              max-height: inherit;
              height: 100%;
              div {
                height: 100%;
              }
            }
          }
          &__label {
            opacity: 0.8;
            font-family: NanumSquareR;
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: normal;
            text-align: right;
            color: var(--black);
            padding-right: 5px;
          }
          &__select {

          }
        }
        &__price {
          display: flex;
          align-items: center;
          &__label {
            opacity: 0.8;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            text-align: left;
            color: #767b84;
            padding-right: 5px;
          }
          &__value {
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.4px;
            text-align: left;
            color: #000;
          }
        }
      }
    }

    .content-submit-fixed-bottom {
      position: fixed;
      //bottom: 0;
      //min-height: 58px;
      //background-color: #fed400;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      //width: 100%;
      //left: 0;
      background-image: linear-gradient(to left, #fed400, #ffe356);
      label {
        font-family: NanumSquareEB;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: center;
        color: #111;
      }

      &.disabled {
        background-color: #d2d2d2;
        pointer-events: none;

        label {
          opacity: 0.6;
          font-family: NanumSquareEB;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: normal;
          text-align: center;
          color: #111;
        }
      }
    }

    .text-product-information {
      font-family: NanumSquareEB;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: left;
      color: #111;
      margin-bottom: pxToVW(15);
    }

    .image-box {
      width: pxToVW(320);
      height: pxToVW(160);
      position: relative;
      object-fit: contain;
      border-radius: 5px;

      .image-box-card {
        width: pxToVW(320);
        height: pxToVW(160);

        .img_card {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .time-style {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        width: 70px;
        height: 30px;
        padding: 6px 13px 9px 14px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0px 5px 0px 5px;

        .time-style-1 {
          font-family: NanumSquareB;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          color: $white;
        }
      }
    }

    .image-noti {
      position: absolute;
      background-color: transparent;
      content: '';
      width: pxToVW(320);
      height: pxToVW(160);
      top: 0;
      left: 0;
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        font-family: NanumSquareB;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $white;
      }
    }

    .image-cover {
      position: absolute;
      background-color: #111;
      opacity: 0.7;
      content: '';
      width: pxToVW(320);
      height: pxToVW(160);
      top: 0;
      left: 0;
      border-radius: 5px;
      z-index: 10;
    }

    .Random-card {
      padding-top: pxToVW(10);

      .title-card {
        font-family: NanumSquareEB;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #111;
      }
    }

    .text-box {

      position: relative;
      margin-top: -5px;

      .notification {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: palevioletred;
        text-align: center;
        position: absolute;

        .text-notification {
          font-family: NanumSquareEB;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          color: $white;
        }

      }

      .text-price {
        margin-top: 10px;

        .text-Pick-up-date {
          font-family: NanumSquareR;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          text-align: left;
          color: #111;
        }

        .text-money {
          font-family: NanumSquareEB;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #5815a5;
          margin-top: pxToVW(25);
          margin-left: pxToVW(5);
        }
      }
    }

    .out-of-stock {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .icon_listno-arow {
        width: 105px;
        height: 112px;
        margin: 0 30px 16px 32px;
      }

      span {
        margin: 16px 0 0;
        opacity: 0.5;
        font-family: NanumSquareR;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #111;
      }
    }
  }

  .css-26l3qy-menu {
    max-height: 200px !important;
    min-width: 70px;
    overflow-y: auto;
    > div {
      overflow: hidden;
    }
    >div>div {
      line-height: 1.2;
    }
    >div>div {

      white-space: nowrap;
      font-size: 12px;
      text-align: right;
      background-color: white;
    }
    .custom-class-disabled {
      background-color: white !important;
      background: white !important;
    }
  }
  .dropdown-common {
    padding: 0;
    .dropdown {
      width: 100%;
      .dropdown-toggle {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #0e0f11;
        display: block;
        padding: 5px 5px 5px 10px;
        min-width: 75px;
      }
      &.show {
        .dropdown-menu {
          max-width: 75px;
          min-width: 75px;
          inset: 32px auto auto 0px !important;
          padding: 0px;
          .dropdown-item {
            padding: 3px 10px;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #0e0f11;
          }
        }
      }
    }
    .icon-arrow-down {
      width: 12px;
      height: 6px;
      right: 10px;
    }
    &.list-more {
      .dropdown {
        width: 100%;
        .dropdown-toggle {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: left;
          color: #0e0f11;
          display: block;
          padding: 5px 5px 5px 10px;
          min-width: 75px;
        }
        &.show {
          .dropdown-menu {
            max-width: 75px;
            min-width: 75px;
            inset: 32px auto auto 0px !important;
            .dropdown-item {
              padding: 3px 10px;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #0e0f11;
            }
          }
        }
      }
    }
  }
  .modal-touch-bottom {
    left: 0;
    .touch-bottom {
      left: 0;
    }
    &.list-store-more {
      .touch-bottom {
        &.touchend {
          .touch-down{
            span{
              position: absolute;
                top: 2.77778vw;
                left: 50%;
                transform: translateX(-50%);
                background-color: #dfe2e6;
                width: 31px;
                height: 4px;
                border-radius: 3px;
            }
          }
          .content{
            overflow-y: scroll;
            height: 450px;
            padding-bottom: 200px;
          }
          animation: touchUpModalDetailBook 0.6s ease-in-out both;

          @keyframes touchUpModalDetailBook {
            0% {
              top: pxToVW(1000);
            }

            100% {
              top: calc(100vh - #{pxToVW(320)});
            }
          }
        }
      }
    }
  }


}


.order-detail {
  background-color: #fff;
  padding: 0;
  margin-bottom: 0 !important;
  padding-bottom: 50px;

  .modal-touch-bottom {
    visibility: hidden;
    &.overlay {
      visibility: visible;
    }
    .touch-bottom {

      padding: 0;
      &.back {
        animation: touchDownModal 0.6s ease-in-out both;

        @keyframes touchDownModal {
          0% {
            top: calc(100vh - #{pxToVW(150)});
          }

          100% {
            top: pxToVW(1000);
          }
        }
      }

      &.touchend {
        animation: touchUpModalOrder 0.6s ease-in-out both;

        @keyframes touchUpModalOrder {
          0% {
            top: pxToVW(1000);
          }

          100% {
            top: calc(100vh - 580px);
          }
        }
      }


    }
    &.empty-option {
      .touch-bottom {
        &.back {
          animation: touchDownModal 0.6s ease-in-out both;

          @keyframes touchDownModal {
            0% {
              top: calc(100vh - #{pxToVW(150)});
            }

            100% {
              top: pxToVW(1000);
            }
          }
        }
        &.touchend {
          animation: touchUpModalOrderEmpty 0.6s ease-in-out both;
          @keyframes touchUpModalOrderEmpty {
            0% {
              top: pxToVW(1000);
            }
            100% {
              top: calc(100vh - 430px);
            }
          }
        }


      }
      .main-choose-store {
          margin-bottom: 105px;
      }
    }
    .btn-order {
      border-radius: 10px;
    }
  }


  .order-detail-content {
    &__slide {
      height: 236px;
      // position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 9998;
      position: relative;

      .icon-back {
        position: fixed;
        left: 10px;
        top: 15px;
        z-index: 9999;
        width: 30px;
        height: 40px;
        text-align: center;
        &__img {
          width: 8px;
          height: 14px;
          object-fit: cover;
        }
      }

      .swiper-container {
        height: 100%;
        //pointer-events: none;
        .swiper-pagination {
          //display: none;
        }
        .swiper-slide {
          //display: none;
          //pointer-events: none;
          .item {
            height: 100%;
          }
          &.swiper-slide-active {
            display: block;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .swiper-pagination {
        position: absolute;
        bottom: 8px;
        opacity: 1;
        z-index: 9999999;
        //min-width: 100px;
        left: 50%;
        transform: translateX(-50%);

        .swiper-pagination-bullet {
          width: 5px;
          height: 5px;
          opacity: 0.8;
          box-shadow: 0 2px 2px 0 var(--black-16);
          background-color: var(--white);
          display: inline-block;
          border-radius: 50%;
          margin: 0 7px 0 0;

          &.swiper-pagination-bullet-active {
            width: 29px;
            height: 5px;
            border-radius: 3px;
            background-color: var(--white);
          }
        }
      }
      .time-style {
        bottom: 15px;
        top: auto;
        border-radius: 6px;
        border: solid 1px #ff4e4e;
        background-color: rgba(255, 78, 78, 0.1);
        padding: 8px 16px;
        min-width: 85px;
        right: 20px;
        z-index: 99;
        position: absolute;
        .time-style-letter {
          display: none;
        }
        .time-style-number {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.28px;
          text-align: left;
          color: #ff4e4e;
          display: block;
          width: 100%;
          margin: 0;
        }
      }
    }
    &__progress {
      padding: 21px 21px 0;
      background-color: white;
      .box-progress {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .progress {
          height: 10px;
          border-radius: 5px;
          overflow: inherit;
          width: calc(100% - 126px);
          background-color: #f2f2f2;
        }
        .progress-text {
          white-space: nowrap;
          label {
            font-family: Pretendard;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.24px;
            text-align: left;
            color: #363b45;
          }
          .progress-text__number {
            color: #a31ae9;
            font-weight: bold;
            font-family: Pretendard-Bold;
          }
          .progress-text__label {

          }
        }
        .progress-bar {
          position: relative;
          overflow: inherit;
          border-radius: 5px;
          background-color: #a31ae9;
          .icon-progress {
            width: 26px;
            height: 36px;
            position: absolute;
            top: -20px;
            right: -19px;
          }
        }
      }
    }

    &__info-box {
      padding: 10px pxToVW(20) 20px;
      border-bottom: 1px solid #dfe2e6;
      background-color: #fff;

      .info-box-time-buy {
        padding-bottom: 10px;

        label {
          font-family: NanumSquareB;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #111;

          strong {
            background: #f5f5f5;
            padding: 4px;
            color: #ff4040;
          }
        }
      }

      .info-box-name {
        font-family: NanumSquareEB;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #111;
        padding-bottom: 10px;
      }

      .progress-sell-box {
        display: flex;
        //align-items: center;
        justify-content: space-between;

        &__progress {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 9px 6px 9px 9px;
          border-radius: 5px;
          border: solid 1px #5815a5;
          background-color: rgba(88, 21, 165, 0.1);

          .content-progress {
            .progress {
              height: 7px;
              margin-bottom: 2px;
              background-color: rgba(129, 129, 129, 0.5);
              max-width: 100px;

              .progress-bar {
                background-color: #7500fb;
              }
            }

            .progress-label {
              font-family: NanumSquareB;
              font-size: 11px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.82;
              letter-spacing: normal;
              text-align: left;
              color: #111;
            }
          }

          .progress-icon {
            width: 23px;
            height: 27px;
            object-fit: cover;
            padding-bottom: 3px;
          }
        }

        &__price {
          display: flex;
          flex-wrap: wrap;
          align-items: self-end;

          &__value {
            font-family: NanumSquareEB;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #111;
            width: 100%;
          }

          &__label {
            font-family: NanumSquareR;
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.64;
            letter-spacing: normal;
            text-align: right;
            color: #111;
            display: block;
            display: block;
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }

    &__info-guide-product {
      .nav-tabs {
        //border: none;
        background-color: #fff;
        //padding-top: 10px;
        #tab-guide-tab-qa {
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            //content: "50";
            content: attr(data-after);
            color: #ff4040;
            min-width: 14px;
            display: block;
            height: 12px;
            padding-left: 2px;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.9;
            letter-spacing: normal;
            text-align: center;
            color: #ff4e4e;
          }
        }
        .nav-link {
          width: calc(100%/3);
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: center;
          color: #c2c9d5;
          border: none;
          padding: 10px 16px;
          &.active {
            border-bottom: 3px solid #fed400;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: center;
            color: #363b45;
          }
        }
      }

      .tab-content {
        //height: 50vh;
        background-color: #fff;

        .tab-pane {
          height: 100%;
        }
      }

      .tab-content-body {
        // all: initial;
        // * {
        //   all: initial;
        // }
        &.custom-tab-content {
          padding: 20px 20px 20px 23px;
          margin-bottom: 30px;
          .line-under {
            height: 1px;
            margin: 30px 0 20px;
            background-color: #dfe2e6;
            //width: 100%;
            margin: 0 -20px 0 -23px;
          }
        }
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          margin-bottom: 45px;
        }
        &__card {
          iframe{
            width: 100%;
            height: calc(100vh - 530px);
          }
          background-color: #fff;
          //padding: 15px 15px 15px 18px;
          margin-bottom: 10px;
          h4 {
            font-family: Pretendard;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: -0.24px;
            text-align: left;
            color: #5815a5;
            margin-bottom: 0px;
            padding-top: 10px;
            span {
              font-family: NanumSquareEB;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: -0.24px;
              text-align: left;
              color: #5815a5;
            }
            &:first-child {
              padding-top: 0px;
            }
          }
          ul {
            li {
              // list-style-type: disc;
              font-family: Pretendard;
            //   font-size: 14px;
            //   font-weight: normal;
            //   font-stretch: normal;
            //   font-style: normal;
            //   line-height: 1.29;
            //   letter-spacing: -0.56px;
            //   text-align: left;
            //   color: #8f949c;
            //   position: relative;
            //   padding-left: 5px;
            //   padding-bottom: 5px;
            //   strong {
            //     font-family: Pretendard;
            //     font-size: 14px;
            //     font-weight: 600;
            //     font-stretch: normal;
            //     font-style: normal;
            //     line-height: 1.29;
            //     letter-spacing: -0.56px;
            //     text-align: left;
            //     color: #8f949c;
            //   }
            //   &:before {
            //     content: "";
            //     display: inline-block;
            //     border-radius: 50%;
            //     width: 4px;
            //     height: 1px;
            //     background-color: #8f949c;
            //     position: absolute;
            //     left: -5px;
            //     top: 7px;
            //     margin: auto;
            //   }
            }
          }
          .item-content {

          }
          h3, h4 {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.28px;
            text-align: left;
            color: #363b45;
            padding-bottom: 10px;
          }
          .text-title {
            font-family: NanumSquareEB;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #5815a5;
            padding-bottom: 10px;
          }

          p {
            font-family: NanumSquareR;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.36px;
            text-align: left;
            color: #111;
            padding-bottom: 10px;
            position: relative;

            strong {
              font-family: NanumSquareB;
            }

            &.note {
              color: #ff4040;
            }

            // &:before {
            //   content: "";
            //   display: inline-block;
            //   border-radius: 50%;
            //   width: 2px;
            //   height: 2px;
            //   margin: 15px 3px 16px 0;
            //   background-color: #111;
            //   position: absolute;
            //   left: -5px;
            //   top: 5px;
            //   margin: auto;
            // }
          }

          .item-content {
            .text-title {
              padding-bottom: 7px;
            }

          }
          &.custom-card-render {
            //padding: 0;
            //background: transparent;
            // h1,h2,h3,h4,h5 {
            //   background-color: #fff;
            // }
            ul, ol {
              li{
                // list-style: disc;
                list-style: inside;
              }
            }
            
            p {
              // background-color: #fff;
              // padding-bottom: 5px;
              // &:before {
              //   display: none;
              // }

              img {
                //width: 250px;
                //height: 150px;
                object-fit: cover;
                margin: auto;
                display: block;
                //border-radius: 50%;
                margin-top: 5px;

              }
            }
            
          }
          &.information-use {
            p,span,strong {
              color: inherit;
              font-family: inherit;
            }
            h1,h2,h3,h4,h5 {
              margin-bottom: 5px;
              padding-bottom: 0;
            }
            p {
              padding-bottom: 5px;
              &::before {
                display: none;
              }
            }
            ul {
              padding-bottom: 15px;

              li {
                padding-left: 10px;
                p {
                  margin-bottom: 0;
                  padding-bottom: 0;
                }
                strong {
                  font-weight: bold;
                }
                &::before {
                  top: 7px;
                  left: 0;
                }
              }
            }
          }
          &.notice {
            padding-top: 15px;
            p {
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.29;
              letter-spacing: -0.28px;
              text-align: left;
              color: #ff4e4e;
              padding-left: 15px;
              &::before {
                display: none;
              }
            }
            ul {
              padding-left: 10px;
              li {
                padding-bottom: 5px;
              }
            }
          }
        }
        .qa-content {
          margin-bottom: 30px;
          .qa-group-content {
            .info-qa-create {
              padding: 15px 15px 30px;
              background-color: white;
              position: relative;

              &__title {
                font-family: Pretendard;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
                color: #0e0f11;
              }
              &__description {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: #767b84;
                width: 100%;
              }
              p {
                font-family: NanumSquareR;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.42px;
                text-align: left;
                color: var(--black);
              }
              &__btn {
                margin-top: 15px;
                button {
                  border-radius: 10px;
                  background-color: #f5f6f8;
                  padding: 10px 1px 10px 1px;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none;
                  label {
                    font-family: Pretendard;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2;
                    letter-spacing: normal;
                    text-align: left;
                    color: #363b45;
                    padding-right: 5px;
                  }
                  img {
                    width: 6px;
                    height: 12px;
                  }
                }
              }
              &__icon {
                //position: absolute;
                //right: 0;
                //top: 5px;
                margin: auto;
                display: block;
                width: 100px;
                height: 74px;
              }
            }
            .line-under {
              height: 1px;
              margin: 30px 0 20px;
              background-color: #dfe2e6;
              //width: 100%;
              margin: 0 -20px 0 -23px;
            }
            .info-qa-list {
              margin-top: 5px;
              padding: 15px 0 15px;
              background-color: white;
              .tab-filter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &.disabled {
                  pointer-events: none;
                }
                &__sort {
                  button {
                    padding: 9px 18px;
                    border-radius: 13px;
                    border: solid 1px #ececec;
                    background-color: var(--white);
                    font-family: Pretendard;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: left;
                    color: #8f949c;
                    margin-right: 3px;
                    min-width: 55px;
                    border-radius: 8px;
                    &:focus{
                      outline: none;
                      box-shadow: none;
                    }
                    &.active {
                      background-color: #fffbe2;
                      color: #d4b100;
                      border: solid 1px #fed400;
                    }
                  }
                }
                &__only-me {
                  .checkbox {
                    &__label {
                      span {
                        font-family: NanumSquareR;
                        font-size: 12px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.08;
                        letter-spacing: -0.36px;
                        text-align: right;
                        color: #000;
                      }
                    }
                  }
                  button {
                    padding: 9px 18px;
                    border-radius: 13px;
                    border: solid 1px #ececec;
                    background-color: var(--white);
                    font-family: Pretendard;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: left;
                    color: #8f949c;
                    margin-right: 3px;
                    min-width: 55px;
                    border-radius: 8px;
                    &:focus{
                      outline: none;
                      box-shadow: none;
                    }
                    &.active {
                      background-color: #fffbe2;
                      color: #d4b100;
                      border: solid 1px #fed400;
                    }
                  }
                }
              }
              .list-qa {
                margin-top: 15px;
                position: relative;
                .item-qa {
                  padding: 20px 0px 0;
                  //border-bottom: solid 1px #ececec;
                  border-top: solid 1px #ececec;
                  &:first-child {
                    border-bottom: none;
                    border-top: none;
                  }
                  .info-answer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &__state {
                      font-family: Pretendard;
                      font-size: 14px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.14;
                      letter-spacing: normal;
                      text-align: left;
                      color: #0e0f11;
                    }
                    &__qa {
                      display: flex;
                      align-items: center;
                      label {
                        //opacity: 0.5;
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: normal;
                        text-align: right;
                        color: #8f949c;
                        padding: 2px;
                        &.info-answer__qa__time {
                          //opacity: 0.5;
                          font-family: Pretendard;
                          font-size: 14px;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.14;
                          letter-spacing: -0.28px;
                          text-align: right;
                          color: #8f949c;
                        }
                      }
                      &__only-me {
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.17;
                        letter-spacing: normal;
                        text-align: left;
                        color: var(--white) !important;
                        padding: 0 4px 2px;
                        border-radius: 3px;
                        background-color: #425bdb;
                        opacity: 1 !important;
                        padding: 0 4px 2px;
                        border-radius: 3px;
                        border: solid 1px #4b75e1;
                        background-color: rgba(75, 117, 225, 0.7);
                      }
                      &__arrow-top {
                        margin-top: -2px;
                        padding: 0 7px 0 3px !important;
                        width: 1px;
                        height: 8px;
                        overflow: hidden;
                      }

                    }
                  }
                  .info-question {
                    margin-top: 8px;
                    &__text {
                      margin-bottom: 8px;
                       display: flex;
                      .is-private {
                        width: 10px;
                        height: 13px;
                        margin-left: 5px;
                      }
                      .icon-question {
                        padding-right: 2px;
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #0e0f11;
                        vertical-align: top;
                      }
                      label {
                        font-family: NanumSquareR;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.08;
                        letter-spacing: normal;
                        text-align: left;
                        color: #000;
                      }
                      &__icon-first {
                        padding-right: 3px;
                      }
                      &__value {
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        text-align: left;
                        color: #0e0f11;
                        //width: calc(100% - 15px);
                        position: relative;
                        // padding-left: 13px;
                        display: inline-block;
                        width: calc(100% - 12px);
                        vertical-align: super;
                        &::before {
                          content: 'Q.';
                          width: 14px;
                          font-family: NanumSquareR;
                          font-size: 12px;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.08;
                          letter-spacing: normal;
                          text-align: left;
                          color: #000;
                          padding-right: 2px;
                          display: none;
                          position: absolute;
                          left: 0;
                        }
                        &.is-has-private {
                          &::after {
                            content: '';
                            background-image: url('../../../assets/icons/icon_lock_qna.svg') !important;
                            width: 14px;
                            height: 15px;
                            display: inline-block;
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin: -1px 5px 0;
                            background-position-y: -1px;
                            vertical-align: top;
                            pointer-events: none;
                          }
                          @supports (-webkit-touch-callout: none) {
                            /* CSS specific to iOS devices */
                          }

                          @supports not (-webkit-touch-callout: none) {
                            /* CSS for other than iOS devices */
                            &::after {
                              content: '';
                              background-image: url('../../../assets/images/icon_lock_private.svg');
                              width: 14px;
                              height: 15px;
                              display: inline-block;
                              background-size: contain;
                              background-repeat: no-repeat;
                              margin: -2px 5px 0;
                              background-position-y: 1px;
                              vertical-align: top;
                              pointer-events: none;
                            }
                          }
                        }

                      }
                    }
                    &__action {
                      text-align: right;
                      .action-delete {
                        pointer-events: none;
                      }
                      label {
                        opacity: 0.7;
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: normal;
                        text-align: left;
                        color: #111;
                      }
                      &__arrow {
                        height: 10px;
                        overflow: hidden;
                        padding: 0 12px;
                        opacity: 1 !important;
                      }
                    }
                    &__has-answer {
                      display: flex;
                      position: relative;
                      transition: height .2s ease;
                      height: 16px;
                      margin-top:0px;
                      align-items: center;
                      overflow: hidden;
                      &:last-child {
                        margin-bottom: 20px;
                      }
                      &:nth-child(n+1){
                        margin-top:6px;
                      }
                      &.not-for-me {
                        pointer-events: none;
                        .icon-arrow {
                          display: none;
                        }
                      }
                      .icon-arrow {
                        position: absolute;
                        right: 0px;
                        top: 5px;
                        width: 12px;
                        height: 6px;
                      }
                      &__icon-first {
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        // line-height: 1.14;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ff4e4e;
                      }
                      &__value {
                        width: calc(100% - 30px);
                        text-align: justify;
                        transition: height .3s ease;
                        position: relative;
                        label {
                          display: block;
                          width: calc(100% - 1px);
                          font-family: Pretendard;
                          font-size: 14px;
                          font-weight: 500;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.29;
                          letter-spacing: normal;
                          text-align: left;
                          color: #363b45;
                          padding-left: 2px;
                          //height: 0;
                          &.hidden {
                            visibility: hidden;
                            overflow: inherit;
                            text-overflow: inherit;
                            white-space: break-spaces;
                            height: auto;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                          }
                        }
                        span {
                          width: 100%;
                          text-align: right;
                          opacity: 0.5;
                          font-family: Roboto;
                          font-size: 11px;
                          font-weight: 300;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.18;
                          letter-spacing: -0.22px;
                          text-align: justify;
                          color: var(--black);
                          display: none;

                        }
                        .content-answer {
                          position: relative;
                          span {
                            right: -20px !important;
                            bottom: -10px !important;
                          }
                        }
                        &.show {
                          height: auto;
                          label {
                            overflow: inherit;
                            text-overflow: inherit;
                            white-space: break-spaces;
                            height: auto;
                            margin-bottom: 0px;
                            text-align: justify;
                            margin-top: -1px;

                            font-family: Pretendard;
                            font-size: 14px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.29;
                            letter-spacing: normal;
                            text-align: left;
                            color: #363b45;

                          }
                          span {
                            display: block;
                            position: absolute;
                            right: -20px;
                            bottom: 0px;
                            width: auto;
                            font-family: Pretendard;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.14;
                            letter-spacing: -0.28px;
                            text-align: right;
                            color: #8f949c;
                          }
                        }
                        &.hidden {
                          position: absolute;
                          width: 100%;
                          top: 0;
                          visibility: hidden;
                          left: 0;

                        }
                      }
                      &.show {
                        background: #fff;
                        margin: 0 -20px;
                        padding: 6px 25px 15px 20px;
                        height: auto;
                        //margin-top: 10px;
                        align-items: inherit;
                        margin-bottom: -1px;
                        //&:nth-child(n+1){
                        //  margin-top: 2px;
                        //}
                        .icon-arrow {
                          right: 20px;
                          top: 11px;
                          transform: rotate(180deg);
                        }
                      }

                    }
                  }
                }
                .show-loading-qna {
                  margin: 0;
                  text-align: center;
                  background: white;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  z-index: 99;
                  top: 0;
                  padding-top: 30px;
                }
                .non-data {
                  min-height: 240px;
                  position: static;
                  width: 100%;
                  transform: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: -25px;
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
      #tab-guide-tabpane-qa {
        .tab-content-body {
          //padding-bottom: 0;
        }
      }
    }
  }

  .content-submit-fixed-bottom {
    position: fixed;
    bottom: 36px;
    min-height: 58px;
    background-color: #fed400;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 11px;
    label {
      font-family: NanumSquareEB;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: center;
      color: #111;
    }

    &.disabled {
      background-color: #d2d2d2;
      pointer-events: none;

      label {
        opacity: 0.6;
        font-family: NanumSquareEB;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: center;
        color: #111;
      }
    }
  }
  .btn-action-submit-bottom {
    position: fixed;
    width: 100%;
    left: 0;
    height: 92px;
    bottom: 0;
    background: white;
    z-index: 9999;
    .content-submit-fixed-bottom {
      position: static;
      transform: none;
      text-align: center;
      margin: auto;
    }
  }
}

.modal-alert-status-box {
  .modal-content {
    .modal-title {
      font-family: NanumSquareEB;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #111;
      padding-bottom: 15px;
    }

    .modal-body {
      padding-top: 0;
      padding-bottom: 25px;

      .content-modal {

        &__title {
          background-color: #f5f6f8;
          padding: 12px 18px;
          border-radius: 10px;
          margin-bottom: 18px;
          white-space: nowrap;
          border: solid 1px #dfe2e6;
          label {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #8f949c;
            white-space: break-spaces;
            strong {
              font-family: NanumSquareEB;
              font-size: 18px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.17;
              letter-spacing: normal;
              text-align: left;
              color: #a31ae9;
            }
          }
        }

        &__description {
          margin-bottom: 30px;

          p {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.56px;
            text-align: left;
            color: #8f949c;
            padding-bottom: 3px;
            position: relative;
            padding-left: 10px;
            &:before {
              content: "";
              display: inline-block;
              border-radius: 50%;
              width: 4px;
              height: 4px;
              background-color: #8f949c;
              position: absolute;
              left: 0px;
              top: 7px;
              margin: auto;
            }
            strong {
              color: #ff4e4e;
            }
          }
        }
      }
    }
  }
}

.store-selection {
  .content-submit-fixed-bottom {
    background-image: linear-gradient(to left, #fed400, #ffe356);
    label {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #363b45;
    }
  }
  &.modal {
    .modal-content {
      border-radius: 20px;
      padding: 36px 20px;
      .modal-body {
        padding: 0;

      }
      .modal-footer {
        padding: 0;
        border-radius: 0 0 20px 20px;
        button {
          border-radius: 8px;
          padding: 18px 28px;
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #363b45;
          white-space: nowrap;
          background-image: linear-gradient(to left, #fed400, #ffe356);
          height: 56px;
          &.btn-left {
            margin-right: 5px;
            background-color: #f5f6f8;
            background-image:none;
          }
          &.btn-right {
            margin-left: 5px;
            background-color: #fed400;
          }
        }
      }
    }

  }

  .setting-page {
    margin-bottom: 15px;
    .app-sub-header {
      width: 100%;
      height: 13.61111vw;
      position: fixed;
      top: -1px;
      left: 0;
      display: flex;
      align-items: center;
      z-index: 9999;


      .app-sub-header__content {
        //position: fixed;
        width: 100%;
        font-weight: 400;
        background-color: #ffffff;
        position: static;
        padding: 0 20px;
        .app-sub-header__content__ico {
          z-index: 99;
          right: 0px;
          outline: none;
          left: auto;
          width: 15px;
          margin-top: 0;
          top: 0;
          .icon_close_B3 {
            //position: absolute;
            z-index: 99;
            right: 15px;
            outline: none;
            margin-top: 0.55556vw;
            width: 15px;
            height: 15px;
            color: #111;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .app-sub-header__content__text {
          font-size: 5vw;
          position: relative;
          font-weight: 700;
          width: 100%;
          text-align: center;
          position: relative;
          line-height: 1.17;
          letter-spacing: normal;
          color: #111111;
          padding: 0;
          left: 0;
          span {
            font-family: NanumSquareEB;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: center;
            color: #111;
          }

        }
      }
    }
  }

  .modal-content {
    margin: 0px;
    padding: 0px;
    .modal-title {
      padding: 0;
      margin-bottom: 20px;
    }
    .modal-body {
      // margin: 0px;
      padding: 0vw 5.55556vw 0vw 5.55556vw;
      .modal-body__no-header {
        .content-modal {
          .modal-title1 {
            font-family: NanumSquareEB;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #5815a5;
          }

          .modal-title2 {
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #363b45;
          }

          .content-modal__title {
            padding: 20px;
            border-radius: 20px;
            border: solid 1px #dfe2e6;
            background-color: #f5f6f8;
            margin: 0px 0px 30px 0px;
            .modal-time {
              img {
                width: 15px;
                height: 15px;
                object-fit: contain;
                margin-right: 4px;
                margin-bottom: 5px;
              }
            }

            .content-modal__description {
              text-align: start;

              h3 {
                font-family: Pretendard;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: -0.36px;
                text-align: left;
                color: #363b45;
              }

              span {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: -0.28px;
                text-align: left;
                color: #767b84;
              }
            }

            .modal-time {
              display: flex;
              align-items: center;
              text-align: left;
              margin-top: 17px;
              span{
                font-family: NanumSquareR;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: var(--black);
              }
      
            }
          }
        }
      }
    }
    .modal-footer{
      .group-button{
        .btn-left{
          font-family: NanumSquareEB;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: center;
          color: var(--black);
        }  
        .btn-right{
          font-family: NanumSquareEB;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: center;
          color: var(--black);
        }
      }
    }

  }

  .box-search-auto {
    margin-top: 25px;
    position: relative;
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 15px;
        height: 15px;
        object-fit: cover;
      }
      .gg-search {
        box-sizing: border-box;
        position: relative;
        display: block;
        transform: scale(var(--ggs,1));
        width: 14px;
        height: 14px;
        border: 2px solid #c2c9d5;
        border-radius: 100%;
        margin-left: -4px;
        margin-top: -4px
      }
      .gg-search::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        border-radius: 3px;
        width: 2px;
        height: 7px;
        background: #c2c9d5;
        transform: rotate(-45deg);
        top: 8px;
        left: 10px;
      }
      input {
        //margin-left: 10px;
        border: none;
        background-color: #f5f6f8;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #8f949c;
        width: 100%;
      }
      .btn-search {
        border: none;
        padding: 16px 16px 14px 16px;
        border-radius: 10px;
        background-color: #fed400;
      }
    }
    .box-content {
      display: flex;
      align-items: center;
      padding: 16px 16px 14px 16px;
      border-radius: 10px;
      background-color: #f5f6f8;
      width: calc(100% - 57px)
    }
    .result-search {
      margin-top: 10px;
      min-height: 150px;
      max-height: 150px;
      overflow-y: auto;
      border: 1px solid #f5f6f8;
      border-radius: 8px;
      padding: 20px 15px;
      display: none;
      z-index: 999;
      position: absolute;
      background: white;
      width: 100%;
      &.show {
        display: block;
        .text-muted {
          display: block;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: -0.28px;
          text-align: left;
          color: #767b84;
        }
      }
    }
  }

  .body-main {
    margin-top: 20px;
    .search {
      width: 100%;
      height: 46px;
      position: relative;

      .input__wrapper {
        .input__box {
          position: absolute;
          border: solid 1px #e6e6e6;
          border-radius: 5px;
          background-color: $white;
          z-index: 99999;
          height: 100%;
          width: 100%;
          .line {
            width: 92%;
            height: 1px;
            margin: auto;
            background-color: #e6e6e6
          }

          .search-input {
            max-height: 67px;
            overflow-y: scroll;
            display: none;
            border-top: 1px solid #e6e6e6;
            margin: 0px 15px;

            .search-input-list {
              span {
                margin: 10px 10px 10px 0px;
                font-family: NanumSquareB;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #111;
                display: block;
                width: 300px;
                display: block;
                max-width: 100%;
              }
            }
          }

          .input-change {
            border: none;
          }

          ::-webkit-input-placeholder {
            /* Edge */
            font-family: NanumSquareB;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #111111;
          }

          :-ms-input-placeholder {
            /* Internet Explorer */
            font-family: NanumSquareB;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #111111;
          }

          ::placeholder {
            font-family: NanumSquareB;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #111;
          }

          .icon_icon_search3 {
            position: absolute;
            right: 15px;
            top: 0;

            img {
              width: 16px;
              height: 16px;
            }
          }

        }
      }
    }

    .wrapper-page-notice {
      .title-location {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
        margin: 0px 0px 5px 0px;
        position: relative;
        width: auto;
        display: inline-block;
        z-index: 10;

        //&::before {
        //  content: "";
        //  width: calc(100% + 15px);
        //  height: 10px;
        //  position: absolute;
        //  bottom: -1px;
        //  background-color: #f5ebf6;
        //  z-index: -1;
        //  transform: skew(-25deg);
        //  left: -7px;
        //
        //}

      }
      .item-store {

        margin-bottom: 5px;
      }
      .item {
        padding: 15px 0;
        border-top: 1px solid #dfe2e6;
        border-bottom: none;
        .title-location-detail {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #363b45;
          margin: 0px 0px 5px 0px;

          .no-oder {
            width: 42px;
            height: 16px;
            border-radius: 2px;
            background-color: #f5f5f5;
            display: inline;

            span {
              font-family: Pretendard;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.29;
              letter-spacing: -0.28px;
              text-align: left;
              color: #767b84;
              border-radius: 2px;
            }
          }

        }
        .btn-detail {
          display: none;
          img {
            width: 6px;
            height: 12px;
            object-fit: cover;
          }
        }
      }

      .text {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: left;
        color: #767b84;

      }
    }
    .item-select {
      .btn-detail {
        top: 15px;
        transform: none;
        padding-top: 0;
      }
      .title-location-detail {
        width: calc(100% - 8px);
      }
    }


  }
}

.order-complete {
  overflow-x: hidden;
  .app-sub-header__content__text span {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #0e0f11;
  }
  &.bg-order-complete {
    background-color: #fff;
    margin-bottom: 0 !important;
    min-height: calc(100vh - 0px);

    .wrapper-order-complete {
      background: white;
      margin: 0 -23px;
      padding: 0 23px;
    }
  }

  .wrapper-order-complete {
    &__info-box {
      padding: 20px 0 25px;

      .info-box-item {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 3px;

        &__name {
          font-family: NanumSquareEB;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: -0.63px;
          text-align: left;
          color: #111;
        }

        &__status {
          font-family: NanumSquareB;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.09;
          letter-spacing: normal;
          text-align: center;
          color: var(--white);
          padding: 3px 7px 3px 8px;
          background-color: #5815a5;
          white-space: nowrap;
        }

        &__note {
          opacity: 0.8;
          font-family: NanumSquareR;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.09;
          letter-spacing: normal;
          text-align: left;
          color: #111;
        }

        &__time {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: -0.28px;
          text-align: right;
          color: #8f949c;
        }
      }
    }

    .border-line {
      height: 2px;
      margin: 20px;
      border-radius: 2px;
      background-color: #f5f5f5;
      margin: 0;
    }

    &__avatar-box {
      height: 197px;
      margin: 20px 0 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    &__guide-use-product {
      h3 {
        margin: 21px 222px 10px 4px;
        opacity: 0.8;
        font-family: NanumSquareB;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -1.05px;
        text-align: left;
        color: var(--black);
        display: flex;
        align-items: center;
        width: 100%;
        &:first-child {
          margin-top: 0;
        }
        &::before {
          content: "";
          background-image: url('../../../assets/images/icon_pencil.svg');
          width: 18px;
          height: 18px;
          display: block;
          margin-bottom: 5px;
          margin-right: 5px;
        }
      }
      .guide-use-title {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #0e0f11;
        padding-bottom: 7px;
      }

      .product-not-order {
        background-color: #f8f8f8;
        margin: 0 -23px;
        padding: 20px 23px 0;
        text-align: center;
        display: flex;
        justify-content: center;
        position: relative;

        &__icon {
          width: 177px;
          height: 110px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__title {
          font-family: NanumSquareEB;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: normal;
          text-align: left;
          color: #e10606;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
        }
      }

      .guide-use-content {
        background-color: #fff;
        margin: 0 -23px;
        padding: 0px 23px;
        .item-guide {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #8f949c;
          margin-bottom: 6px;
          display: block;
        }
        &__item {
          margin-bottom: 28px;
          .item-content {
            margin-bottom: 25px;
            .text-title {
              opacity: 0.8;
              font-family: NanumSquareB;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: -1.05px;
              text-align: left;
              color: var(--black);
              display: flex;
              margin-bottom: 8px;
              &:before {
                content: "";
                background-image: url('../../../assets/images/icon_pencil.svg');
                display: block;
                width: 18px;
                height: 18px;
                margin-right: 5px;
                //margin-bottom: 5px;
              }
            }
          }
          &__icon {
            width: 18px;
            height: 18px;
            object-fit: cover;
            margin-bottom: 5px;
          }

          p {
            opacity: 0.8;
            font-family: NanumSquareR;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: -0.9px;
            text-align: left;
            color: #111;
            position: relative;
            margin-left: 5px;
            z-index: 10;

            &:before {
              content: "";
              display: inline-block;
              border-radius: 50%;
              width: 2px;
              height: 2px;
              margin: 15px 3px 16px 0;
              background-color: #111;
              position: absolute;
              left: -5px;
              top: 10px;
              margin: auto;
            }

            strong {

              position: relative;

              &:before {
                content: "";
                display: inline-block;
                width: 100%;
                height: 8px;
                position: absolute;
                background-color: #fed400;
                bottom: 0;
                z-index: -1;
              }
            }
          }
        }
      }
    }
  }
}

.content-submit-fixed-bottom {
  //position: fixed;
  //bottom: 0;
  //min-height: 58px;
  //background-color: #fed400;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //width: 100%;
  //left: 0;
  position: fixed;
  bottom: 36px;
  min-height: 58px;
  background-color: #fed400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: calc(100% - 40px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  z-index: 99;
  label {
    font-family: NanumSquareEB;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  &.disabled {
    background-color: #d2d2d2;
    pointer-events: none;

    label {
      opacity: 0.6;
      font-family: NanumSquareEB;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: center;
      color: #111;
    }
  }
}
.modal-alert-choose-store {
  .modal-content-title {
    font-family: NanumSquareEB;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
  }
  .modal-body {
    padding-top: 25px;
    padding-bottom: 45px;
    .modal-content-head {
      padding-bottom: 25px;
    }
    .modal-content-body {
      padding: 17px 23px;
      border-radius: 10px;
      background-color: rgba(88, 21, 165, 0.05);
      label {
        font-family: NanumSquareR;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--black);
        strong{
          color: #5815a5;
          font-weight: bold;
          font-family: NanumSquareB;
        }
      }
    }
  }
  .modal-footer {
    .btn-right {
      color: #000 !important;
    }
  }
}

.box-event-order {
  position: relative;
  .image-box {
    width: 100%;
    height: pxToVW(160);
    position: relative;
    object-fit: contain;
    border-radius: 5px;

    .image-box-card {
      width: 100%;
      height: pxToVW(160);

      .img_card {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .time-style {
      position: absolute;
      top: 0;
      right: 0;
      //width: 100px;
      height: 34px;
      z-index: 5;
      padding: 8px 4px 8px 9px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 0px 5px 0px 5px;

      .time-style-number {
        width: 5px;
        height: 18px;
        margin: 0 3px 0 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.7px;
        text-align: center;
        color: #ff4040;
      }

      .time-style-letter {
        width: 22px;
        height: 13px;
        margin: 3px 0 2px 3px;
        font-family: NanumSquareB;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $white;
      }
    }
  }

  // .image-order:before {
  //   position: absolute;
  //   background-color: #111;
  //   opacity: 0.5;
  //   content: '';
  //   width: pxToVW(320);
  //   height: pxToVW(160);
  //   top: 0;
  //   left: 0;
  //   z-index: 10;
  // }
  .image-noti {
    position: absolute;
    background-color: transparent;
    content: '';
    width: pxToVW(320);
    height: pxToVW(160);
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      font-family: NanumSquareB;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }

  }

  .image-cover {
    position: absolute;
    background-color: #111;
    opacity: 0.7;
    content: '';
    width: pxToVW(320);
    height: pxToVW(160);
    top: 0;
    left: 0;
    border-radius: 5px;
    z-index: 10;
  }

  .Random-card {
    padding-top: pxToVW(10);

    .title-card {
      font-family: NanumSquareEB;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #111;
    }
  }

  .text-box {

    position: relative;

    .notification {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: palevioletred;
      text-align: center;
      position: absolute;
      margin-top: -5px;

      .text-notification {
        font-family: NanumSquareEB;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        color: $white;



      }

    }

    .text-price {
      margin-top: 12px !important;

      .text-Pick-up-date {
        width: 30px;
        height: 12px;
        margin: 12px 3px 6px 0px;
        font-family: NanumSquareR;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
      }

      .text-money {
        font-family: NanumSquareEB;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #5815a5;
        margin-top: pxToVW(25);
      }
    }



  }

  .text-third {
    position: absolute;
    bottom: -20px;
    left: 6px;
    .icon-image {
      margin-top: 2%;
      border-radius: 5px;

      .yellowbubble {
        width: pxToVW(225);
        height: pxToVW(38);
      }
    }

    .text-notification {
      position: absolute;
      margin-top: pxToVW(15);
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.82;
      letter-spacing: normal;
      text-align: left;
      color: #111;
      font-family: NanumSquareB;
      white-space: nowrap;
      .text-total {
        font-family: NanumSquareEB;
        margin-right: 5px;
      }

      .text-multi-sale {
        font-family: NanumSquareEB;
        color: #a31ae9;
        margin-right: 0px;
        margin-left: 5px;
      }

      .icon-bboki {
        width: 17px;
        height: 19px;
        margin-left: pxToVW(10);
        margin-right: pxToVW(3);
        margin-top: -3px;
      }

    }
  }

}
.red-price {
  &.box-event-order .text-box .text-price .text-money {
    color: #c52321;
  }
}
body {
  &.order-detail-bg {
    //background-color: #f8f8f8;
    overflow-x: hidden;
    &.modal-open {
      position: static;
    }
    .modal {
      .modal-content {
        border-radius: 20px;
        padding: 36px 20px;
        .modal-body {
          padding: 0;
        }
        .modal-footer {
          padding: 0;
          border-radius: 0 0 20px 20px;
          button {
            border-radius: 8px;
            padding: 18px 28px;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #363b45;
            white-space: nowrap;
            background-image: linear-gradient(to left, #fed400, #ffe356);
            height: 56px;
            &.btn-left {
              margin-right: 5px;
              background-color: #f5f6f8;
              background-image:none;
            }
            &.btn-right {
              margin-left: 5px;
              background-color: #fed400;
            }
          }
        }
      }

    }
    .modal-alert-choose-store {
      .modal-content {
        .modal-body {
          padding-bottom: 30px;
          .modal-content-head {
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .modal-content-body {
            background-color: white;
            padding-bottom: 0;
            .text-center {
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.38;
              letter-spacing: normal;
              text-align: center;
              color: #8f949c;
            }
          }
        }
        .modal-footer {
          .group-button {
            button {
              border-radius: 8px;
              padding: 18px 28px;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #363b45;
              white-space: nowrap;
              &.btn-left {
                margin-right: 5px;
                background-color: #f5f6f8;
                background-image:none;
              }
              &.btn-right {
                margin-left: 5px;
                background-color: #fed400;
              }
            }
          }
        }
      }

    }
    .modal-alert-status-box {
      .modal-content {
        .modal-title {
          padding: 0 0 15px;
        }
      }
    }
  }
  &.fixed-nav-tab {
    min-height: auto;
    overflow-x: hidden;
    .order-detail-content__info-guide-product {
      .nav {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;
      }
    }
    .icon-back {
      display: none;
    }
    .order-detail {
    }
    .layout-main {
      min-height: auto;
    }
  }
  &.fixed-nav-tab-first-qna {
    min-height: auto;
    .order-detail-content__info-guide-product {
      .nav {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;
      }
    }
    .icon-back {
      display: none;
    }
  }
}
.create-qa-page {
  .icon-close-back {
    position: fixed;
    width: 15px;
    height: 15px;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }
  .wrapper-create-qa {
    margin-top: 20px;
    .content-main {
      position: relative;
      .count-character {
        position: absolute;
        bottom: 2px;
        right: 3px;
        width: calc(100% - 4px);
        text-align: right;
        background: white;
        padding-bottom: 3px;
        margin-bottom: -1px;
        padding-right: 5px;
        padding-left: 2px;
        span {
          //opacity: 0.8;
          font-family: Pretendard;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.15;
          letter-spacing: -0.65px;
          text-align: right;
          color: #131313;
          label {
            color: #ff3737;
          }
          &:nth-child(2){
            padding: 0 3px;
          }
        }
      }
    }
    .note-qa {
      font-family: NanumSquareR;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      text-align: left;
      color: var(--black);
    }
    .place-holder-textarea {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      z-index: 1;
      pointer-events: none;
      label {
        //opacity: 0.5;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #8f949c;
        padding-bottom: 20px;
      }
    }
    .content-qa {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #191919;
      padding: 18px 15px;
      margin-top: 12px;
      border-radius: 10px;
      background-color: #f5f6f8;
      border: none;
      resize: none;
      &:focus{
        //background-color: white;
      }
    }
    .private-qa {
      display: flex;
      align-items: center;
      .wrap-radio-cus.checked {
        .status-check {
          border-bottom: 2px solid #111;
          border-right: 2px solid #111;
        }
      }
      .icon-private {
        width: 18px;
        height: 14px;
      }

      &__text {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #0e0f11;
        margin-left: 10px;
      }
      .checkbox {
        &__label {
          display: flex;
          align-items: center;
          height: 5.55556vw;
          span {
            margin-top: 3px;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #0e0f11;
          }
        }
      }
    }
    .description-qa {
      margin: 0px -5.55556vw;
      background: #fff;
      padding: 25px 20px;
      .description-qa-icon {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        background-color: #8f949c;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-family: Pretendard;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: center;
          color: var(--white);
        }
      }
      &__text {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #8f949c;
      }
      &__title {
        margin-bottom: 7px;
        display: flex;align-items: center;
        label {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: normal;
          text-align: left;
          color: #0e0f11;
          position: relative;
          z-index: 99;
          &::before {
            content: "";
            display: block;
            background-color: #fed400;
            height: 12px;
            width: 100%;
            position: absolute;
            bottom: 5px;
            z-index: -1;
            opacity: .5;
          }
        }

        img {
          width: 18px;
          height: 14px;
        }
      }
      p {
        font-family: NanumSquareR;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.6px;
        text-align: left;
        color: var(--black);

      }
      ul {
        margin-top: 10px;
        margin-left: 0px;
        li {
          position: relative;
          padding-left: 10px;
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 1px;
            color: #111111;
            background: #8f949c;
            border-radius: 50%;
            margin-right: 3px;
            position: absolute;
            left: 0;
            top: 8px;
          }
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: left;
          color: #8f949c;
          padding-bottom: 6px;
          white-space: nowrap;
        }
      }
    }
  }
  .content-submit-fixed-bottom {
    position: fixed;
    bottom: 36px;
    min-height: 58px;
    background-color: #fed400;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    label {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #363b45;
    }
    &.disabled {
      background-color: #dfe2e6;
      pointer-events: none;
      color: #767b84;
      opacity: 1;
    }
  }
  .btn-action-submit-bottom {
    position: fixed;
    width: 100%;
    left: 0;
    height: 92px;
    bottom: 0;
    background: white;
    z-index: 9999;
    .content-submit-fixed-bottom {
      position: static;
      transform: none;
      text-align: center;
      margin: auto;
    }
  }
}
.modal-alert-create-success {
  .modal-body {
    padding: 35px;
    margin-bottom: 30px;
    p {
      font-family: NanumSquareR;
      font-size: 14px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: var(--black);
    }
    .content-modal {
      p {
        font-family: Pretendard;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #363b45;
      }
    }
  }
  &.modal {
    .modal-content {
      border-radius: 20px;
      padding: 36px 20px;
      .modal-body {
        padding: 0;
      }
      .modal-footer {
        padding: 0;
        border-radius: 0 0 20px 20px;
        button {
          border-radius: 8px;
          padding: 18px 28px;
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #363b45;
          white-space: nowrap;
          background-image: linear-gradient(to left, #fed400, #ffe356);
          height: 56px;
        }
      }
    }

  }
}
.modal-alert-delete-qa {
  .modal-content-body {
    position: relative;
    margin-bottom: 30px;
    label {
      font-family: Pretendard;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #363b45;

    }
    .show-loading-qna {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.modal-select-update {
  display: none;

  border-radius: 20px 20px 0 0;
  .modal-close {
    display: none;
  }

  .box-content-choose-option {
    position: relative;
    padding: 30px 0px;
    .modal-select--header {
      padding-left: 0;
      border-bottom: none;
      height: auto;
      margin-bottom: 15px;
      padding: 0 20px;
      span {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
        width: 100%;
        display: block;
      }
    }
    .box-modal-close {
      content: "";
      height: 4px;
      width: 31px;
      border-radius: 3px;
      background-color: #dfe2e6;
      color: #dfe2e6;
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
    }
    .main-option-product {
      border: none;
      padding: 0 20px;
      .group-radio-item {
        justify-content: space-between;
        border: solid 1px #dfe2e6;
        padding:12px 16px !important;
        border-radius: 6px;
        margin-bottom: 10px;
        .text-option {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 500 !important;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: left;
          color: #363b45 ;
        }
        &.active {
          .text-option {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #363b45;
          }
        }
      }
    }
    .main-choose-store {
      padding: 0 20px 10px;
      &__title {
        display: block;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
        margin-bottom: 10px;
        padding-left: 1px;
      }
      
    }
    .box-submit-modal {
      padding: 0 20px;
      // position: fixed;
      // width: 100%;
      // bottom: 36px;
    }
    .button {
      &.disabled {
        background-color: #d2d2d2;
        pointer-events: none;
        color: #424b5c;
      }
    }
    .dropdown-common {
      padding: 0;
      border: none;
      background-color: transparent;
      border-radius: 0;
      .dropdown {
        padding: 18px 16px 18px 16px;
        border: solid 1px #f5f6f8;
        background-color: #f5f6f8;
        border-radius: 10px;
        width: 100%;
        .dropdown-toggle {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #a8afb9;
          box-shadow: none;
          background-color: transparent;
        }
        &.show {
          border: solid 1px #ffe76d;
          .dropdown-toggle {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #a8afb9;
          }
          .dropdown-menu {
            height: 115px;
            height: 220px;
            border-radius: 10px;
            .dropdown-item {
              font-family: Pretendard;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: left;
              color: #363b45;
              padding: 16px;
              border-bottom: 1px solid #edf0f6;
            }
          }
        }
      }
      &.list-more {
        .dropdown {
          &.show {
            .dropdown-menu {
              // inset: -230px auto auto 0px !important;
              height: 220px;
              border-radius: 10px;
              .dropdown-item {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #363b45;
                padding: 16px;
                border-bottom: 1px solid #edf0f6;
              }
            }
          }
        }
      }
    }
    .business-drop{
      .dropdown{
        .dropdown-menu{
          box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
          border: 0px;
          inset: -170px auto auto 0px !important;
          max-height: 165px !important;
        }
      }
    }
  }
  &.open {
    display: block;
    height: auto;
  }

  .modal-content {
    transform: none;
    &::after {
      display: none;
    }
    .modal-over {
      margin: 0 0 10px;
      height: auto;
      overflow: initial;
    }
    &::before {
      display: none;
    }
    .modal-over {
      margin: 0;
      &::before {
        display: none;
      }

      .main-option-product {
        margin: 0px 0 15px;
        //border-bottom: 1px solid #ececec;
        max-height: 195px;
        min-height: 195px;
        overflow-y: auto;
        .group-radio-item {
          display: flex;
          align-items: center;
          //margin-bottom: 16px;
          padding: 15px 3px;
          &:first-child {

          }
          &.disabled {
            pointer-events: none;

            .wrap-radio {
              opacity: 0.6;
            }
            label {
              color: #cbcfd5 !important;
            }
          }
          .wrap-radio {
            padding-right: 7px;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
          label {
            font-family: NanumSquareR;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
          }
          &.active {
            label {
              //color: #5815a5;
              font-weight: 900 !important;
            }
          }
        }

      }
      .main-price-product {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 20px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          left: 0;
          background-color: #dfe2e6;
          top: -10px;
        }
        .box-price {
          text-align: right;
          padding: 5px 0px 15px;
          //border-top: 1px solid #ececec;
          margin: 0px 5px;
          display: flex;
          justify-content: space-between;
        }
        label {
          margin-bottom: 0;
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: normal;
          text-align: left;
          color: #767b84;
          padding-right: 4px;
        }
        strong {
          font-family: Pretendard;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.4px;
          text-align: right;
          color: #000;
        }
      }
    }
  }
  .button {
    &.fixed {
      font-family: NanumSquareEB;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: center;
      color: #111;
    }
  }
}
.modal-confirm-pay-one-won {
  &.modal {
    .modal-content {
      border-radius: 20px;
      padding: 36px 20px;
      .modal-body {
        padding: 0;
      }
      .modal-footer {
        padding: 0;
        border-radius: 0 0 20px 20px;
        button {
          border-radius: 8px;
          padding: 18px 28px;
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #363b45;
          white-space: nowrap;
          background-image: linear-gradient(to left, #fed400, #ffe356);
          height: 56px;
          &.btn-left {
            margin-right: 5px;
            background-color: #f5f6f8;
            background-image:none;
          }
          &.btn-right {
            margin-left: 5px;
            background-color: #fed400;
          }
        }
      }
    }

  }
  .modal-body {
    padding: 25px;
  }
  .modal-content-body {
    margin-bottom: 36px;
    label {
      font-family: Pretendard;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #363b45;
    }
    p {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: -0.32px;
      text-align: center;
      color: #363b45;
      strong {
        font-weight: 900;
        color: #a31ae9;
      }
      margin: 16px 0 36px;
    }
    .info-store {
      padding: 18px;
      border-radius: 20px;
      border: solid 1px #dfe2e6;
      background-color: #f5f6f8;
      label{
        display: block;
      }
      &__name {
        font-family: Pretendard;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: -0.36px;
        text-align: left;
        color: #363b45;
      }
      &__address {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: left;
        color: #767b84;
      }
    }
  }
  .group-button {
    .btn-right {
      color: #111111 !important;
    }
  }
}

.list-event-good {
  background-color: #fffbec;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  //background-position: center;
  //background-size: contain;
  background-image: url('../../../assets/images/bg_order_list.svg') !important;
}
.list-event-good {
  //background-color: #fffbec;
  width: 100%;
  height: 100%;
  display: block;
  //background-size: contain;
  //.find-store {
  //  padding-bottom: 0;
  //  margin-bottom: 0;
  //}
  //.body-main:first-child {
  //  margin-top: 0px;
  //}
  .header-filter-box .content-select__item .order-select {
    padding: 0 16px;
    justify-content: space-between;
    height: 52px;
  }
  .header-filter-box {
    background-color: transparent;
    border-radius: inherit;
    box-shadow: none;
    //position: static;
    margin-bottom: 0;
    //.content-select__item {
    //  padding: 0;
    //}
    .icon-back {
      position: fixed;
      top: 18px;
      left: 20px;
      padding: 0px 20px 20px 0px;
      img {
        width: 8px;
        height: 14px;
        margin: auto;
      }
    }
    .header-title-page {
      background-color: transparent;
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      //line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #0e0f11;
      line-height: 13.88889vw;
    }

    .order-select {
      border-radius: 10px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
      background-color: #fff;

      width: 100%;

      .text-order {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #8f949c;
        margin: 0;
      }
    }
  }
  .list-order-body {
    .body-main {
      width: 100%;
    }
    .box-event-order {
      width: 100%;
      .image-box {
        .time-style {
          bottom: 10px;
          top: auto;
          border-radius: 6px;
          border: solid 1px #ff4e4e;
          background-color: rgba(255, 78, 78, 0.1);
          padding: 8px 16px;
          min-width: 85px;
          right: 15px;
          .time-style-letter {
            display: none;
          }
          .time-style-number {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -0.28px;
            text-align: left;
            color: #ff4e4e;
            display: block;
            width: 100%;
            margin: 0;
          }
        }
      }
      .info-event-box {
        background-color: #fff;
        padding: 10px 14px 25px;
        border-radius: 0 0 20px 20px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
        .box-progress {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 26px;
          justify-content: space-between;
          .progress {
            height: 10px;
            border-radius: 5px;
            overflow: inherit;
            width: calc(100% - 103px);
            background-color: #f2f2f2;
          }
          .progress-text {
            white-space: nowrap;
            label {
              font-family: Pretendard;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: -0.24px;
              text-align: left;
              color: #363b45;
            }
            .progress-text__number {
              color: #a31ae9;
              font-weight: bold;
              font-family: Pretendard-Bold;
            }
            .progress-text__label {

            }
          }
          .progress-bar {
            position: relative;
            overflow: inherit;
            border-radius: 5px;
            background-color: #a31ae9;
            .icon-progress {
              width: 26px;
              height: 36px;
              position: absolute;
              top: -20px;
              right: -19px;
            }
          }
        }
        .Random-card {
          padding-top: 0;
        }
        .title-card {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: normal;
          text-align: left;
          color: #0e0f11;
        }
        .text-box {
          .text-price {
            margin-top: 0 !important;
          }
          .text-Pick-up-date {
            display: none;
          }
          .text-money {
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #0e0f11;
          }
        }
      }
      .image-box-card {
        border-radius: 20px 20px 0 0 ;
        overflow: hidden;
        .image-cover {
          border-radius: inherit;
        }
      }
    }
  }

  &.not-fixed-header {
    .header-filter-box {
      padding-bottom: 0;
      margin-bottom: 0;
      .setting-header {
        padding-bottom: 0;
        position: static;
        .header-title {
          position: static;
        }
        .select-store {
          padding-top: 0;
          .content-select__item {
            padding: 0;
          }
        }
      }
    }
    .list-order-body {
      //margin-top: 15px;
      overflow-y: scroll;
      min-height: 86vh;
      background-color: #fffbec;
      &::-webkit-scrollbar-track
      {
        background-color: #fffbec;
      }

      &::-webkit-scrollbar
      {
        background-color: #fffbec;
      }

      &::-webkit-scrollbar-thumb
      {
        background-color: #fffbec;
      }
    }
    .body-main:first-child {
      margin-top: 0;
    }
  }


}
body {
  &.fixed-filter-store {
    .list-event-good {
      //.body-main:first-child {
      //  margin-top: 105px;
      //}
      //.find-store {
      //  padding-bottom: 9.44444vw;
      //  margin-bottom: 11.11111vw;
      //}
      //.setting-header .content-select__item .order-select {
      //}
      //.setting-header {
      //  position: fixed;
      //  margin-bottom: 31px;
      //  .content-select__item {
      //    padding-left: 5.55556vw;
      //    padding-right: 5.55556vw;
      //  }
      //}
    }
  }
}