
.loading-page{
  width: 100%;
  height: 100%;
  z-index: 1000005;
  .img-loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000005;
  }
}

.loading-page:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000005;
  background: #d9d9d9;
  opacity: 0.3;
}

