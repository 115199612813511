.login-page {
  background-color: $governor-2;
  height: 100vh;

  .login-header {
    text-align: center;
    padding: pxToVW(60) 0;

    img {
      width: pxToVW(120);
      height: pxToVW(60);
    }
  }

  .login-form {
    background-color: $white;
    padding: pxToVW(30) pxToVW(20) pxToVW(30) pxToVW(20);
    width: 100%;
    min-height: pxToVW(360);
    height: calc(100% - #{pxToVW(181)});
    border-top-left-radius: pxToVW(50);

    .title-login {
      font-size: pxToVW(23);
      line-height: 1.17;
      text-align: center;
      color: $black-light-3;
      font-weight: $black;
      margin-bottom: pxToVW(30);
    }

    .input__wrapper {
      margin-bottom: pxToVW(20);

      input {
        padding-left: pxToVW(15);
        font-size: pxToVW(14);
      }
    }

    .login-button {
      padding-top: pxToVW(10);

      button {
        height: pxToVW(48);
        border-radius: pxToVW(5);
      }
    }

    .group-footer {
      margin-top: pxToVW(40);
      margin-bottom: 0;
      text-align: center;
      // position: relative;
      padding-bottom: pxToVW(30);

      // height: 100px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      // min-height: pxToRem(90);

      @media (min-width: 400px) {
        height: 120px;
      }

      height: calc(100% - #{pxToRem(305)});

      @media (max-width: 340px) {
        height: calc(100% - 260px);
      }

      a {
        font-size: pxToVW(14);
        line-height: 1.14;
        color: $black-light-3;
        text-decoration: underline;
      }

      span {
        color: $black-light-3;
        margin: 0 pxToVW(20);
      }
    }
  }
}

.modal-login {
  .modal-dialog {
    padding: 0 pxToVW(20);
  }
}

.mh-90vh {
  margin-bottom: pxToVW(100);
}

.mb-60 {
  margin-bottom: pxToVW(60) !important;
}

.mb-10 {
  margin-bottom: pxToVW(10) !important;
}

.layout-main {

  &.mb-0 {
    margin-bottom: 0 !important;
  }
}