.non-data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform-translate(-50%, -50%);

  img {
    width: 78px;
    margin-bottom: 15px;
  }

  p {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: center;
    color: #8f949c;
  }
}