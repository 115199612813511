.inquiry-product-page {
  .button {
    opacity: 1;
  }

  .user-info {
    margin-top: pxToVW(20);
    display: flex;
    align-items: center;
    margin-bottom: pxToVW(20);

    .avatar-user {
      width: pxToVW(45);
      height: pxToVW(45);
      border-radius: 50%;
      background-color: $gallery;
      margin-right: pxToVW(10);

      img {
        width: pxToVW(45);
        height: pxToVW(45);
        border-radius: 50%;
      }
    }

    .user-name {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      color: #363b45;
    }
  }

  .form-inquiry {
    textarea.content-textarea {
      height: pxToVW(320);
      margin-bottom: pxToVW(20);
      border: none;
      border-radius: 10px;
      background-color: #f5f6f8;
      outline: none;
      box-shadow: none;

      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #191919;
      padding: 15px;
      @media (max-width: 414px) {
        height: pxToVW(240);
      }

      @media (max-width: 360px) {
        height: pxToVW(220);
      }
      &::placeholder {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #c2c9d5;
        padding-top: 2px;
      }
    }

    .group-btn-file {
      width: 82px;
      height: 82px;
      position: relative;
      box-shadow: inset -2px -2px 10px 1px rgba(97, 97, 97, 0.16);
      border-radius: 6px;
      margin-bottom: 100px;
      .custom-file-input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      .img-view {
        width: 82px;
        height: 82px;
        border-radius: 8px;
        background-color: #f5f6f8;
        position: relative;
        .icon-delete {
          position: absolute;
          width: 25px;
          height: 25px;
          background-color: #363b45;
          border-radius: 50%;
          padding: pxToVW(7);
          top: -10px;
          right: -10px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }

    .upload-img {
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #dfe2e6;
      // background-color: #ffffff;
      width: 82px;
      height: 82px;
      border-radius: 6px;
      .text-upload{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: #c2c9d5;
      }
    }
    .list-stores-like{
      h2{
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
        margin-top: 25px;
        margin-bottom: 16px;
      }
      .lists {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        .item {
          font-family: Pretendard;
          border-radius: 8px;
          border: solid 1px #dfe2e6;
          padding: 10px 20px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #8f949c;
          margin-right: 15px;
          &.selected{
            border: solid 1px #fed400;
            background-color: #fffbe2;
            color: #d4b100;
          }
        }
      }
    }
    .auto-complete{
      .find-store-like-search{
        padding: 0px;
        // position: absolute;
        // left: 20px;
        // right: 20px;
      }
      .list-stores-like{
        h2{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: normal;
          text-align: left;
          color: #363b45;
          margin-top: 25px;
          margin-bottom: 16px;
        }
        .lists {
          display: flex;
          flex-wrap: nowrap;
          white-space: nowrap;
          overflow-x: auto;
          .item {
            font-family: Pretendard;
            border-radius: 8px;
            border: solid 1px #dfe2e6;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #8f949c;
            margin-right: 15px;
            &.selected{
              border: solid 1px #fed400;
              background-color: #fffbe2;
              color: #d4b100;
            }
          }
        }
      }
    }
    
  }

  
  &.register-product {
    margin-top: pxToVW(30);

    .form-group {
      margin-bottom: pxToVW(15);
    }

    .form-inquiry {
      input {
        background-color: $white;
      }

      .group-btn-file {
        width: 100%;
        height: pxToVW(320);
        
      }

      .upload-img {
        width: 100%;
        height: pxToVW(320);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: pxToVW(128);
          height: pxToVW(100);
        }
      }
    }
  }
  .btn-register-inquiry{
    border-radius: 10px;
    position: absolute;
    bottom: 35px;
    left: 0px;
    right: 0;
    z-index: 1;
    margin: 0px 20px;
    width: auto;
    height: 57px;

    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #363b45;
    background-color: #fed400;
    &:disabled{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #767b84;
      background-color: #dfe2e6;
    }
  }
}

.page-product-list {
  padding-top: 51px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: pxToVW(80);

  // .app-sub-header {
  //   .app-sub-header__content {
  //     background-color: $orange-2;
  //   }
  // }

  .product-page {
    .data-store-product{
      padding: 0px 20px;
    }
    .list-store-select{
      margin: 10px 20px 23px 20px;
      // margin-top: 10px;
      .list {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        .item {
          font-family: Pretendard;
          border-radius: 8px;
          border: solid 1px #dfe2e6;
          padding: 10px 20px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #8f949c;
          margin-right: 15px;
          &.selected{
            border: solid 1px #fed400;
            background-color: #fffbe2;
            color: #d4b100;
          }
        }
      }
    }

    .search-store {
      // position: relative;
      padding: 0px 20px;
      // .icon-search{
      //   position: absolute;
      //   top: 22px;
      //   right: 35px;
      // }
      // input.input-search{
      //   border-color: #d9d9d9;
      //   outline: none;
      //   box-shadow: none;
      // }
    }
    // .result-search{
    //   height: 165px;
    //   position: absolute;
    //   width: 100%;
    //   background: #fff;
    //   z-index: 999;
    //   left: 20px;
    //   right: 20px;
    //   border-radius: 5px;
    //   box-shadow: 0 pxToVW(3) pxToVW(20) 0 rgba(243, 242, 235, 0.2);
    //   border: solid 1px #d9d9d9;
    //   overflow: scroll;
    //   margin-top: 2px;
    //   width: auto;
    //   padding: 20px;
    //   .list-search-item {
    //     border-bottom: 1px solid #d9d9d9;
    //     padding: 10px 0;
    //     display: flex;
    //     align-items: center;
    //   }
    // }
    .sort-store {
      height: auto;
      padding: pxToVW(10) pxToVW(20) pxToVW(15) pxToVW(25);
      box-shadow: 0 pxToVW(3) pxToVW(20) 0 rgba(254, 212, 0, 0.2);
      background-color: $orange-2;
      border-bottom-left-radius: pxToVW(20);
      border-bottom-right-radius: pxToVW(20);
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      z-index: 999;

      .select-name {
        display: flex;
        align-items: center;
        justify-content: center;

        .name {
          max-width: pxToVW(250);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: pxToVW(20);
          font-weight: $black;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
        }

        img {
          margin-left: pxToVW(5);
          width: pxToVW(12);
        }
      }
    }
  }

  .list-product-inquiry {
    // padding: 20px;

    .item-product-inquiry {
      position: relative;
      padding-top: 1px;
      .wrap-item-product-inquiry{
        margin: 20px;
        // &:after {
        //   content: "";
        //   left: pxToVW(20);
        //   width: calc(100% - #{pxToVW(40)});
        //   right: pxToVW(20);
        //   position: absolute;
        //   bottom: pxToVW(-20);
        //   border-bottom: 1px solid #dfe2e6;
        // }
      }
      .line-item-product{
        width: calc(100% - #{pxToVW(40)});
        right: pxToVW(20);
        position: absolute;
        bottom: pxToVW(-20);
        border-bottom: 1px solid #dfe2e6;
      }

      .inquiry-head {
        display: flex;
        justify-content: space-between;
        margin-bottom: pxToVW(10);
        position: relative;
        .inquiry-user {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .action-edit-delete-comment{
          .edit-delete{
            position: absolute;
            right: -12px;
            width: 65px;
            border-radius: 7px;
            border: solid 1px #dfe2e6;
            // display: flex;
            // flex-direction: column;
            // align-content: center;
            // align-items: center;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #0e0f11;
            .action-edit{
              font-family: Pretendard;
              height: 33px;
              background-color: #fff;

              display: flex;
              justify-content: center;
              align-items: center;
              border-top-left-radius: 7px;
              border-top-right-radius: 7px;
            }
            .action-delete{
              height: 33px;
              background-color: #fff;
              font-family: Pretendard;
              display: flex;
              justify-content: center;
              align-items: center;

              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
            }
          }
        }
        .left-box-info{
          .inquiry-user-name {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #363b45;
          }
          .inquiry-time{
            font-family: Pretendard;
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #8f949c;
            padding-top: 6px;
          }
        }
        

        .inquiry-user-img {
          overflow: hidden;
          width: pxToVW(30);
          height: pxToVW(30);
          margin-right: pxToVW(7);
          border: solid pxToVW(1) $gallery;
          background-color: $gallery;
          border-radius: 50%;

          img {
            width: pxToVW(30);
            height: pxToVW(30);
            border-radius: 50%;
          }
        }

        .action-delete {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            padding: pxToVW(4) pxToVW(6);
          }

          * {
            font-size: pxToVW(12);
            font-weight: normal;
            line-height: 1.08;
          }

          span {
            font-size: pxToVW(7);
            font-weight: bold;
          }
        }
        .action-edit-delete{
          .icon-dot{

          }
          .edit-delete{
            position: absolute;
            right: -12px;
            width: 65px;
            border-radius: 7px;
            border: solid 1px #dfe2e6;
            // display: flex;
            // flex-direction: column;
            // align-content: center;
            // align-items: center;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #0e0f11;
            .action-edit{
              font-family: Pretendard;
              height: 33px;
              background-color: #fff;

              display: flex;
              justify-content: center;
              align-items: center;
              border-top-left-radius: 7px;
              border-top-right-radius: 7px;
            }
            .action-delete{
              height: 33px;
              background-color: #fff;
              font-family: Pretendard;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top: 1px solid #f2f2f2;
              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
            }
          }
        }

      }
      

      .inquiry-content-product {
        margin: pxToVW(10) 0;
        white-space: pre-line;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;

      }

      .img-product {
        height: pxToVW(150);
        background-position: center center;
        background-size: cover;
        background-color: $orange-2;
        margin: 0;
        border-radius: 15px;
      }

      .inquiry-footer {
        margin-bottom: pxToVW(20);

        .inquiry-time {
          font-family: $Roboto;
          font-size: pxToVW(11);
          font-weight: 300;
          line-height: 1.18;
          letter-spacing: normal;
          opacity: 0.5;
        }

        .inquiry-like {
          padding-right: 8px;

          .inquiry-comment {
            padding-right: 0px;
            margin-left: 15px;
          }

          .inquiry-like {
            padding-left: pxToVW(5);
          }

          img {
            margin-right: pxToVW(3);
          }

          * {
            font-family: Pretendard;
            font-weight: 300;
            line-height: 1.18;
            color: #0e0f11;
            font-size: 11px;
            font-weight: 500;
          }
        }
      }

      .list-comment {
        // padding-bottom: 25px;
        background-color: $bg-stability;
        width: 100%;
        position: relative;
        padding: 25px 20px 25px 20px;
        .list-comment-ovl {
          // position: relative;
          // max-height: pxToVW(300);
          // overflow: auto;

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
            background-color: $btn-bg;
            height: 0;
          }

          &::-webkit-scrollbar {
            width: 0;
            background-color: $btn-bg;
            height: 0;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $btn-bg;
            border: 0 solid $btn-bg;
            height: 0;
          }
          .action-edit-delete-comment{

          }
        }

        .inquiry-user-img {
          width: pxToVW(25) !important;
          height: pxToVW(25) !important;

          * {
            width: pxToVW(25) !important;
            height: pxToVW(25) !important;
          }
        }

        .content-comment {
          line-height: 1.33;
          margin-bottom: pxToVW(5);
          white-space: pre-line;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          text-align: left;
          color: #363b45;
        }

        .date-comment {
          opacity: 0.5;
          font-family: $Roboto;
          font-size: pxToVW(11);
          font-weight: 300;
          line-height: 1.18;
        }

        .item-comment {
          margin-bottom: pxToVW(15);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .form-addComment {
        margin-top: pxToVW(20);
        position: relative;
        .input__wrapper {
          width: 100%;
          // position: relative;
          input {
            height: 46px;
            border-radius: 23px;
            font-size: pxToVW(12);
            border: solid pxToVW(1) $gallery;
            padding: 15px 60px 15px 15px;
            background-color: #ffffff;
            color: $color-black;
          }
        }

        .login-button {
          position: absolute;
          bottom: -12px;
          right: 15px;
          button {
            font-family: Pretendard;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.15;
            letter-spacing: normal;
            text-align: left;
            color: #4b75e1;
            background-color: #ffffff00;
            &:disabled{
              background-color: #ffffff00;
            }
          }
        }
        .edit-comment{
          position: absolute;
          right: 15px;
          top: -8px;
          button {
            font-family: Pretendard;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.15;
            letter-spacing: normal;
            text-align: left;
            color: #4b75e1;
            background-color: #ffffff00;
            &:disabled{
              background-color: #ffffff00;
            }
          }
        }
      }
    }
  }
}

.wrapper-page-detail-product {
  margin-top: 20px;
  .product-image {
    margin-bottom: 15px;
    max-height: 320px;
    overflow: hidden;
    img {
      width: 100%;
      //height: 100%;
      //object-fit: contain;

    }
  }
  .product-name {
    opacity: 0.7;
    font-family: NanumSquareB;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #111111;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    label {
      opacity: 0.7;
      font-family: NanumSquareB;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #111111;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
    }

  }
  .product-store {
    font-family: NanumSquareB;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #111111;
  }
}

.view-product-popup{
  .modal-dialog{
    .modal-content{
      .modal-body{
        max-height: 80vh;
        overflow-y: scroll;
        border-radius: 10px;
        /* width */
        &::-webkit-scrollbar {
          width: 0px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: transparent;
        }
      }
    }
  }
}


.page-detail-community{
  padding: 0px;
  // overflow-y: scroll;
  // height: 150vh;
  &--keyboard-android{
    overflow-y: scroll;
    height: 150vh;
  }
  .wrapper-page-content{
    margin-top: 43px;
    .infinite-scroll-component {
      overflow: inherit !important;
    }
    .item-community {
      padding: 23px 20px;
      border-bottom: 1px solid #f2f2f2;
      .wrap-item-community{

        .community-content{
          margin-top: 23px;
          margin-bottom: 24px;
          .title{
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.1;
            letter-spacing: -0.5px;
            text-align: left;
            color: #363b45;
            margin-bottom: 12px;
          }
          .content{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.4px;
            text-align: left;
            color: #363b45;
            margin-bottom: 24px;
            white-space: break-spaces;
            word-break: break-word;
          }
          .image{
            height: 130px;
            border-radius: 10px;
            margin-bottom: 10px;
            img{
              border-radius: 10px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .community-detail-stores{
           .item-box-store{
              display: flex;
              align-items: center;
              flex-direction: row;
              flex-wrap: wrap;
              &__store{
                margin-bottom: 4px;
              }
           } 
          }
        }

        .community-like-cmt{
          display: flex;
          justify-content: space-between;
          div{
            padding: 8px;
            border-radius: 14px;
            border: solid 1px #dfe2e6;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .community-like{
            
          }
          .community-cmt{

          }
          .number{
            padding-left: 6px;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            text-align: left;
            color: #767b84;
          }
        }
      }
      .community-head {
        &--playground{
          margin-bottom: 24px;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 24px;
        }
        .community-user {
          display: flex;
          justify-content: space-between;
          .left-box-info{
            display: flex;
            justify-content: left;
            align-items: center;
            .img-user{
              width: 30px;
              height: 30px;
              margin-right: 6px;
              img{
                border-radius: 50%;
                width: 100%;
                height: 100%;
              }
            }
            .community-user-name {
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.14;
              text-align: left;
              color: #363b45;
            }
          }
          .right-box-time{
            .community-time{
              font-family: Pretendard;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.5;
              letter-spacing: -0.3px;
              text-align: left;
              color: #a8afb9;
            }
          }
        }
        .title-playground{
          margin-top: 12px;
          .title{
            h2{
              font-family: Pretendard;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              text-align: left;
              color: #363b45;
            }
          }
        }
      }
      .content-playground{
        margin-bottom: 24px;
        .title{
          h2{
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            text-align: left;
            color: #363b45;
          }
        }
        .community-hashtag{
          // overflow-x: scroll;
          // overflow-y: hidden;
          padding-right: 20px;
          margin-top: 12px;
          ul{
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            li.item{
              padding: 6px 8px;
              border-radius: 13px;
              background-color: #f5f6f8;
              display: flex;
              justify-content: left;
              align-items: center;
              margin-right: 8px;
              margin-bottom: 8px;
              span{
                font-family: Pretendard;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                text-align: left;
                color: #767b84;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    .item-comment {
      .wrap-item-comment {
        .comment-right {
          .comment-content {
            .content {
              padding-right: 3px;
            }
          }
        }
      }
    }

  }
}

.comment-reply{
  margin-top: 8px;
  position: relative;
  .input__wrapper {
    .input__box {
      .input-reply-comment{
        border-radius: 17px;
        border: solid 1px #efefef;
        padding: 9px 60px 9px 14px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: left;
        color: #363b45;
        height: 34px;
      }
    }
  }
  .input-comment{
    margin-right: 10px;
    .input-comment__custom{
      background-color: #fff;
      border-radius: 17px;
      border: solid 1px #efefef;

      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      text-align: left;
      color: #363b45;
      width:100%;
      max-height: 70px;
      min-height: 40px;
      resize: none;
      padding: 9px 14px;
      box-sizing: border-box;
      padding-right: 35px;
    }
  }
  .button-reply-comment{
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translate(-50%,-50%);
    &__custom{
      &:focus{
        box-shadow: none;
      }
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      color: #4b75e1;
    }
  }
}

.comment{
  padding-bottom: 130px;
  .item-comment {
    padding: 23px 20px 0px 20px;
    .wrap-item-comment{
      display: flex;
      justify-content: left;
      .comment-left{
        .img-user{
          width: 30px;
          height: 30px;
          margin-right: 6px;
          img{
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
      }
      .comment-right{
        width: 100%;
        &__info{
          position: relative;
          margin-bottom: 11px;
          display: flex;
          justify-content: space-between;
          .left{
            display: flex;
            justify-content: left;
            align-items: center;
            &--qna{
              display: block;
            }
            .user-name{
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              text-align: left;
              color: #363b45;
              margin-bottom: 3px;
              // margin-right: 4px;
            }
            span.dot-space{
              width: 2px;
              height: 2px;
              margin: 4px 4px;
              background-color: #a0a8b4;
            }
            .time{
              font-family: Pretendard;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              text-align: left;
              color: #a8afb9;
            }
          }
          .right{
            .item-action-dropdown{
              top: 0px;
            }
            .dropdown-action{
              z-index: 999;
              right: -3px;
              top: 22px;
            }
          }
        }
        .comment-content{
          .content{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.4px;
            text-align: left;
            color: #363b45;
            white-space: break-spaces;
            word-break: break-word;
          }
          .comment-replay{
            margin-top: 8px;
            position: relative;
            .input__wrapper {
              .input__box {
                .input-reply-comment{
                  border-radius: 17px;
                  border: solid 1px #efefef;
                  padding: 9px 60px 9px 14px;
                  font-family: Pretendard;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  text-align: left;
                  color: #363b45;
                  height: 34px;
                }
              }
            }
            .button-replay-comment{
              position: absolute;
              top: 0;
              right: 13px;
              &__custom{
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                text-align: left;
                color: #4b75e1;
              }
            }
          }
        }
      }
      .comment-empty{
        margin-top: 30px;
        .img{
          text-align: center;
          margin-bottom: 14px;
        }
        p{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          text-align: center;
          color: #c2c9d5;
        }
      }
    }

  }
  .comment-empty{
    margin-top: 30px;
    .img{
      text-align: center;
      margin-bottom: 14px;
    }
    p{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      color: #c2c9d5;
    }
  }
}


.wrapper-page-footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #f2f2f2;
  z-index: 999;
  // padding-bottom: 400px;
  &--keyboard-android{
    position: unset;
    bottom: 0;
    left: 0;
  }
  &__group{
    display: flex;
    padding: 8.5px 20px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    .input-comment{
      margin-right: 10px;
      width: calc(100% - 70px);
      .input-comment__custom{
        background-color: #fff;
        border: 1px solid #ccc;
        // background-color: red;
        &::placeholder{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          text-align: left;
          color: #c2c9d5;
        }
        &:focus{
          border: none;
        }
        border: none;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        text-align: left;
        color: #363b45;
        width:100%;
        max-height: 70px;
        min-height: 40px;
        resize: none;
        padding: 12px 4px;
        box-sizing: border-box;
        border-radius: 6px;
      }
    }
    .button-comment{
      &__custom{
        padding: 4px 14px;
        border-radius: 6px;
        background-color: #fed400;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        text-align: left;
        color: #363b45;
        border: none;
        &:disabled{
          background-color: #dfe2e6;
        }
        &:focus{
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

.page-product-list {
  .product-page {
    width: 100%;
    .list-store-select {
      margin: 10px -20px 23px 0px;
      overflow-x: auto;
      width: 100%;
      .list {
        width: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .auto-complete {
    margin-left: 0;
    .search-store {
      padding-left: 0;
      margin-top: 0;
      padding-right: 0;
    }
  }
  .main-box-category {
    width: 100%;
  }
}

.playground-modal{
  .modal-content{
    border-radius: 20px;
    .modal-body{
      padding: 36px 20px;
      .content{
        h3{
          font-family: Pretendard;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #363b45;
          margin-bottom: 34px;
          word-break: keep-all;
        }
      }
      .group-button-popup{
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Pretendard;
        
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        .button--half{
          border-radius: 10px;
          height: 56px;
        }
        .btn-left{
          font-size: 16px;
          background-color: #f5f6f8;
          margin-right: 11px;
        }
        .btn-right{
          font-size: 16px;
          background-color: #fed400;
        }
      }
    }
  }
}
.time-ago{
  font-family: Pretendard;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: right;
  color: #a8afb9;
}
