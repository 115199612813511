.consultion-page{
  padding: 10px 20px 20px 20px;
  margin-top: 51px;
  padding-top: 30px;
  padding-bottom: 130px;
  .support-heading{
    display: flex;
    margin-bottom: 12px;
    img{
      width: 18px;
      height: 18px;
      margin-right: 8px;
      border-radius: 50%;
    }

    strong{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: -0.32px;
      text-align: center;
      color: #363b45;
    }
  }
  .support-panel{
    padding: 20px 29px 20px 20px;
    border-radius: 12px;
    background-color: #f5f6f8;
    margin-bottom: 27px;
    p{
      margin-bottom: 9px;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.32px;
      text-align: left;
      color: #767b84;
      &:last-child{
        margin-bottom: 0px;
      }
    }

  }
  .consult-item{
    padding: 20px 20px;
    border-radius: 12px;
    box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    margin-bottom: 20px;
    label{
      padding: 5px 8px;
      border-radius: 6px;
      background-color: #f5f6f8;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: -0.24px;
      text-align: left;
      color: #8f949c;
    }
    h3{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.32px;
      text-align: left;
      color: #363b45;
      margin: 14px 0;
    }

    &.completed{
      label{
        color: #4b75e1;
        background-color: #e5ecff;
      }
    }
  }
  .info{
    display: flex;
    span{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: -0.28px;
      text-align: left;
      color: #767b84;
      margin-right: 8px;
      &:nth-child(2){
        color: rgba(0,0,0,.08)
      }
      &:nth-child(3){
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #767b84;
      }
    }
  }
  .empty-list{
    text-align: center;
    padding-top: 120px;
    img{
      width: 78px;
      height: 78px;
    }
    p{
      margin-top: 20px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #8f949c;
    }
  }
  &.detail-page{
    .content{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.32px;
      text-align: left;
      color: #767b84;
      white-space: break-spaces;
    }
    .question {
      margin: 0 -20px;
      padding: 20px;
      .support-heading {
        padding: 16px 0;
        border-bottom: 1px solid #eaedf2;
        span{
          font-family: Pretendard;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.36px;
          text-align: center;
          margin-right: 8px;
          color: #fed400;
        }
      }

    }
    .answer{
      margin: 0 -20px;
      padding: 20px;
      background: #f5f6f8;
      margin-top: 10px;
      .support-heading {
        padding: 16px 0;
        border-bottom: 1px solid #eaedf2;
        span{
          font-family: Pretendard;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.36px;
          text-align: center;
          margin-right: 8px;
          color: #4b75e1;
        }
      }
      .attachments{
        ul{

        }
      }
    }
    &+.preview{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.37);
      z-index: 99999999999;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      img{
        object-fit: contain;
        max-height: 90%;
        max-width: 90%;
      }
    }
  }
  .attachment-safezone{
    height: 150px;
    width: 100vw;
    margin: 0 -20px;
    overflow: hidden;
  }
  .attachments{
    display: block;
    overflow-x: scroll;
    width: 100vw;
    ul {
      display: flex;
      width: max-content;
      margin: 20px 0;
      li {
        display: block;
        width: 124px;
        height: 124px;
        margin: 5px;
        border-radius: 4px;
        overflow: hidden;
        &:first-child{
          margin-left: 20px;
        }
        &:last-child{
          margin-right: 20px;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &.create-page{
    .user-info{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      .avatar{
        width: 44px;
        height: 44px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 13px;
        img{
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .title{
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #363b45;
      }
    }
    .form-control{
      padding: 18px !important;
      border-radius: 10px;
      background-color: #f5f6f8;
      border: 0;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      box-sizing: border-box;
      letter-spacing: -0.28px;
      text-align: left;
      min-height: 55px;
      margin-top: 10px;
      &::placeholder{
        color: #c2c9d5;
      }
      &+.error{
        padding-left: 16px;
        margin-top: 5px;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ff4e4e;
      }
    }
    .image-picker{
      width: 72px;
      height: 72px;
      border-radius: 6px;
      box-shadow: 2px 2px 6px 0 rgba(97, 97, 97, 0.16);
      border: solid 1px #dfe2e6;
      padding: 16px;
      text-align: center;
      margin-top: 15px;
      input{
        display: none;
      }
      span{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
        text-align: center;
        color: #c2c9d5;
      }
    }
    .thumbs{
      display: flex;
      .file-item {
        width: 72px;
        height: 72px;
        border-radius: 6px;
        box-shadow: 2px 2px 6px 0 rgba(97, 97, 97, 0.16);
        border: solid 1px #dfe2e6;
        padding: 0;
        position: relative;
        margin-top: 15px;
        margin-left: 10px;
        .thumb{
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 6px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .closebtn{
          position: absolute;
          right: -5px;
          top: -5px;
        }
      }
    }
  }
}

.bottom-panel{
  position: fixed;
  bottom: 0;
  left: 0; right: 0;
  padding: 36px 20px;
  background: #FFF;
  button{
    height: 56px;
    padding: 18px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    background-image: linear-gradient(to left, #fed400, #ffe356);
    border: 0;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #363b45;
    &:disabled{
      background: #dfe2e6;
      color: #767b84;
    }
  }

}

.modal-filesize-validate{
  .modal-content{
    border-radius: 20px;
    overflow: hidden;
    .modal-body{
      padding: 36px 20px;
    }
    .content{
      font-family: Pretendard;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #363b45;
      button{
        margin-top: 34px;
        display: block;
        width: 100%;
        padding: 18px;
        background-image: linear-gradient(to left, #fed400, #ffe356);
        border: 0;
        border-radius: 10px;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #363b45;
      }
    }
  }
}

