.find-store {
  
  .setting-header {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    background-color: $governor-2;
    z-index: 9;
  }

  .header-title {
    position: relative;
  }

  .page-store-content {
    padding-top: 15px;
    .list-store-select{
      margin-top: 10px;
      height: 36px;
      .list {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        .item {
          font-family: Pretendard;
          border-radius: 8px;
          border: solid 1px #dfe2e6;
          padding: 10px 20px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #8f949c;
          margin-right: 15px;
          &.selected{
            border: solid 1px #fed400;
            background-color: #fffbe2;
            color: #d4b100;
          }
        }
      }
    }
    &.pt-100 {
      padding-top: pxToVW(100);
    }
  }
}

.page-store {
  padding-bottom: pxToVW(34);
  margin-top: 51px;
  .wrapper-info-buy {
    padding-top: pxToVW(65);
  }

  .setting-header {
    padding-bottom: pxToVW(15);
    margin-bottom: pxToVW(30);
    border-bottom-right-radius: pxToVW(20);
    border-bottom-left-radius: pxToVW(20);
  }

  .header-title {
    display: inline-block;
    width: 100%;
    color: $white;
    line-height: pxToVW(50);
    font-size: pxToVW(18);
    font-family: $NanumSquare-Font;
    font-weight: $black;
    margin-bottom: pxToVW(10);
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    background-color: $governor-2;
    z-index: 9;
  }

  .select-store {
    padding-left: pxToVW(20);
    padding-right: pxToVW(20);
    padding-top: pxToVW(60);

    &--item {
      background-color: rgba($white, 0.3);
      border-radius: pxToVW(20);
      width: 33.33%;
      font-size: pxToVW(14);
      padding: 0px pxToVW(11);
      color: $black-light-3;
      display: inline-block;
      margin-right: pxToVW(10);
      line-height: pxToVW(36);
      font-weight: $black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-height: pxToVW(36);

      &.active {
        background-color: rgba($white, 0.7);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.page-store-content {
  padding-left: pxToVW(20);
  padding-right: pxToVW(20);

  .search {
    margin-bottom: pxToVW(10);

    input {
      height: pxToVW(46);
      background-color: $white !important;
      border-radius: pxToVW(5);
      font-size: pxToVW(14);
      line-height: pxToVW(16);
      color: $black-light-3;
      font-weight: $black;
      border: solid pxToVW(1) $gallery;
    }
  }

  .wrapper-map-content {
    margin-top: 20px;
    // border: pxToVW(1) solid $gallery;
    // overflow: hidden;

    .map-content {
      width: 100%;
      height: 359px;
      overflow: hidden;
      position: relative;
      .map {
        height: 100%;
        >div {

          >div {
            &:nth-child(2) {
              display: none !important;
            }
          }
        }
      }
    }
    .loading-maps{
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9998;
      .img-loading{
        width: 35px;
      }
    }
    .box-list-stores{
      height: 270px;
      overflow-y: scroll;
      .info-map {
        margin-top: 14px;
        padding: 22px 20px;
        border-radius: 20px;
        box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.1);
        background-color: #fffffe;
        .title {
          font-size: pxToVW(14);
          line-height: pxToVW(16);
          color: $black-light-3;
          font-weight: bold;
          margin-bottom: pxToVW(5);
        }
  
        .sub-title {
          font-weight: 400;
          font-size: pxToVW(12);
          line-height: pxToVW(13);
        }
      }
    }
  }
}

.page-store-content {
  #react-kakao-maps-sdk-map-container{
    border-radius: 20px;
    div{
      border-radius: 10px;
      // box-shadow: 0 2px 7px 1px rgb(0 0 0);
      // opacity: 0.3;
      div{
        .info-window{
          max-width: 170px;
          height: auto;
          border-radius: 5px;
          position: relative;
          .content{
            z-index: 2;
            .title-store{
              margin-top: 5px;
              font-weight: bold;
              text-align: center;
              font-size: 16px;
              margin-bottom: 5px;
              margin-right: 12px;
            }
            .store-address{
              text-align: center;
              font-size: 12px;
              margin-bottom: 5px;
              word-break: keep-all;
              white-space: break-spaces;
              // white-space: nowrap;
              // margin-bottom: 5px;
            }
          }
          .close{
            position: absolute;
            width: 28px;
            top: -9px;
            right: -11px;
            z-index: 3;
            .close-icon-info{
              width: 12px;
            }
          }
        }
      }
    }
    

  }

  .wrapper-map-content .map-content {

    .icon_gps {
      position: absolute;
      bottom: 10px !important;
      right: 10px;
      z-index: 998;
      cursor: pointer;
      .bgr-icon-gps{
        width: 43px;
        height: 43px;
        border-radius: 13px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #fff;

        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 21px;
          height: 22px;
        }
      }
    }
  }
}
.page-store-like{
  margin-top: 51px;
  .page-store-content{
    padding: 15px 20px 0px 20px;
    .list-store-select{
      margin-top: 10px;
      height: 36px;
      .list {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        .item {
          margin-right: 6px;
          padding: 9px 12px;
          border-radius: 8px;
          border: solid 1px #dfe2e6;
          &.selected{
            border: solid 1px #fed400;
            background-color: #fffbe2;
            color: #d4b100;
          }
          .store-name{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #8f949c;
            margin-right: 6px;
          }
          img.img-close{
            width: 8.5px;
            height: 8.5px;
          }
        }
      }
    }
    .auto-complete{
      .result-search{
        padding: 0px;
        .list-search-item{
          margin-top: 10px;
        }
      }
    }
  }
  .wrapper-list-content{
    margin-top: 20px;
    .list-all-store{
      .item{
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid #d9d9d9;
        &:first-child{
          border-top: 1px solid #d9d9d9;
        }
        padding: 17px 20px;
        .content{
          .store-name{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #363b45;
            margin-bottom: 12px;
            span.color-autocomplete{
              color: #fed400;
            }
          }
          .position{
            display: flex;
            justify-content: left;
            align-items: flex-start;;
            .icon-position{
              width: 8px;
              height: 16.9px;
              margin-right: 4px;
            }
            span.address{
              line-height: 20px;
              font-family: Pretendard;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: #8f949c;
            }
          }
        }
        .button-add{
          margin-left: 10px;
          .btn-add{
            width: 88px;
            height: 31px;
            border-radius: 8px;
            border: solid 1px #fed400;
            background-color: #fffbe2;

            display: flex;
            justify-content: center;
            align-items: center;
            span{
              font-family: Pretendard;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.5;
              letter-spacing: normal;
              text-align: left;
              color: #d4b100;
              margin-right: 8px;
            }
            .img-plus{
              width: 9px;
              height: 9px
            }
            &.disabled{
              border: none;
              outline: none;
              border-radius: 8px;
              box-shadow: none;
              background-color: #dfe2e6;
              span{
                font-family: Pretendard;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.5;
                letter-spacing: normal;
                text-align: left;
                color: #767b84;
              }
              .img-plus{
                color: #767b84;
              }
            }
            &:focus{
              box-shadow: none;
            }
          }
          
        }
      }
    }
  }
}

.store-popup{
  .modal-content{
    border-radius: 20px;
    .modal-body{
      padding: 36px 20px;
      .title-popup{
        font-family: Pretendard;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #363b45;
        margin-bottom: 34px;
      }
      .btn-close{
        height: 56px;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #363b45;
        border-radius: 10px;
      }
    }
  }
}

.gps-request-popup{
  .modal-dialog{
    width: calc(100% - 40px);
    .modal-content{
      border-radius: 12px;
      .modal-body{
        padding: 0px;
        .modal-body__no-header{
          .content{
            margin: 21px 21px 0px 21px;
            h3{
              font-family: Pretendard;
              font-size: 18px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: -0.36px;
              text-align: center;
              color: #363b45;
              margin-bottom: 10px;
            }
            p{
              font-family: Pretendard;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: -0.28px;
              text-align: center;
              color: #363b45;
              margin-bottom: 22px;
            }
            
          }
          .group-button-popup{
            display: flex;
            border-top: 1px solid #dbdbdb;
            button{
              background-color: #fff;
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              text-align: center;
              color: #4b75e1;
              padding: 10px;
              height: auto;
              &:first-child{
                border-right: 1px solid #dbdbdb;
                border-bottom-left-radius: 12px;
              }
              &:last-child{
                border-bottom-right-radius: 12px;
              }
            }
          }
        }
      }
    }
  }


}