.title-sign-up {
  font-size: pxToVW(14);
  font-weight: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: pxToVW(30);
  .padding10{
    padding-bottom: pxToVW(10);
  }
}

.text-message {
  font-size: pxToVW(12);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;
  color: $text-box-active;
  margin-top: pxToVW(5);
}

.sign-up-form {
  &.top {
    padding-bottom: pxToVW(20);
    border-bottom: pxToVW(2) solid $bg-stability;
  }
  &.center{
    padding-top: pxToVW(30);
  }
  &.middle{
    padding-top: pxToVW(10);
  }
  .count-down {
    position: absolute;
    right: pxToVW(110);
    font-size: pxToVW(14);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $text-box-active;
    font-family: $Roboto;
    top: 50%;
    @include transform-translateY(-50%)
  }

  .group-form {
    margin-bottom: pxToVW(10);
    position: relative;

    &.w-100 {
      .input__wrapper {
        width: 100%;
      }
      .text-message{
        padding-bottom: pxToVW(5);
      }
    }

    .input__wrapper {
      width: calc(100% - #{pxToVW(94)});
      input{
        font-size: pxToVW(14);
      }
    }

    .button {
      width: pxToVW(84);
      height: pxToVW(36);
      margin-left: pxToVW(10);
      padding: pxToVW(5);
      font-size: pxToVW(14);
      border-radius: pxToVW(5);
    }
  }
}

.register-success{
  .img-register-success{
    div[aria-label='animation'] {
      width: pxToVW(300) !important;
      position: relative;
      left: pxToVW(-15);
      height: pxToVW(175);
    }
  }
}


.sign-up-page {
  .btn-sign-up {
    //position: fixed;
    //width: 100%;
    //left: 0;
    //bottom: 0;
  }
  .sign-up-form {
    input.no-value {
      background-color: transparent;
      opacity:1;
    }
    &.verify-code-number {
      pointer-events: none;
      input {
        background-color: #ebebeb !important;
        opacity: 0.5;
      }
      .input__box {
        background-color: #ebebeb !important;
        opacity: 0.5;
      }
      .group-form {
        .button {
          background-color: #ebebeb !important;
          opacity: 0.5;
        }
      }
    }
  }
  .dob-content {
    .label {
      font-size: 3.88889vw;
      font-weight: 900;
      line-height: 1.4;
      color: #111111;
      margin-bottom: 2.77778vw;
      display: flex;
    }
    .css-26l3qy-menu {
      //min-height: 200px;
      //max-height: 280px;
      //overflow-y: hidden;
      //padding-bottom: 10px !important;
      //margin-bottom: 100px !important;
      >div>div {
        line-height: 1.2;
      }
    }
  }
  .group-form-dob {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .customer-select {

      &:first-child {
        width: 50%;
      }
      &:nth-child(2) {
        width: 30%;
        padding: 0 5px;
      }
      &:nth-child(3) {
        width: 25%;
      }
      .css-1uccc91-singleValue {
        height: 100%;
        display: flex;
        align-items: center;
        top: 49%;
        @supports (-webkit-touch-callout: none) {
          top: 42%;
        }
      }
      .css-g1d714-ValueContainer {
        padding-bottom: 0;

        .css-1uccc91-singleValue {

        }
        .css-b8ldur-Input {

        }
      }
    }
    .customer-select .input__box::before {
      background-image: url('../../../assets/images/icon_miniarrow.svg') !important;
      width: 9px;
      height: 6px;
      background-size: contain;
    }
    .customer-select .input__box::after {
      background-image: url('../../../assets/images/icon_miniarrow.svg') !important;
      width: 9px;
      height: 6px;
      background-size: contain;
    }
  }
  .sex-status {
    .label {
      font-size: 3.88889vw;
      font-weight: 900;
      line-height: 1.4;
      color: #111111;
      margin-bottom: 2.77778vw;
      display: flex;
    }
    .group-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button {
        width: calc(100%/2 - 2px);
        background-color: white;
        border:1px solid #e6e6e6;
        opacity: 0.5;
        font-family: NanumSquareR;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #111111;
        &:first-child {
          margin-left: 0;
        }
        &:nth-child(2) {
          margin-left: 0px;
        }
        &.active {
          background-color: #5815a5;
          color: #fff;
          opacity: 1;
        }
      }
    }
  }
  .policy-sign-up {
    padding-top: 30px;
    padding-bottom: 26px;
    label {
      //font-family: NanumSquareEB;
      font-size: 14px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #111;
      margin-bottom: 10px;
    }
    .group-form-checkbox {
      .item-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        //justify-content: space-between;
        .label-policy {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #111;
        }
        .icon-to-page {
          width: 11px;
          height: 12px;
          margin-top: 5px;
          transform: rotate(180deg);
        }
      }
      .checkbox {
        padding-right: 5px;
        .checkbox__label {
          margin-bottom: 0px;
          height: 5.55556vw;
          &::before {
            border: 0.27778vw solid #e6e6e6;
          }
          span {
            display: none;
          }
        }
        span {
          font-family: NanumSquareB;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #111;
          padding-top: 1px;
        }
        &.checkbox--checked {
          .checkbox__label {
            &::before {
              border: 0;
            }
          }
        }
      }
    }
  }
}

.modal-content-policy-term {
  .modal-dialog {
    padding: 0;
    .layout-main {
      padding: 0;
    }
    .modal-content {
      height: 100%;
      text-align: left;
      .app-sub-header__content__ico {
        display: none;
      }
      .modal-body {
        overflow-y: scroll;
        .close-modal-policy {
          width: 15px;
          height: 15px;
          object-fit: cover;
          position: fixed;
          right: 18px;
          z-index: 99999;
          top: 18px;
        }
      }
    }
  }
}
