.my-page {
  padding-left: 0;
  padding-right: 0;

  .app-sub-header {
    .app-sub-header__content {
      background-color: $orange-2;
    }
  }

  .my-header {
    background-color: $orange-2;
    height: pxToVW(330);

    padding: pxToVW(20) 0 pxToVW(25) 0;
    box-shadow: 0 pxToVW(3) pxToVW(20) 0 rgba(254, 212, 0, 0.2);
    border-bottom-left-radius: pxToVW(20);
    border-bottom-right-radius: pxToVW(20);

    .group-my-info {
      text-align: center;
      position: relative;
      padding: 0 pxToVW(20) pxToVW(20) pxToVW(20);

      .toggle-switch {
        position: absolute;
        right: pxToVW(20);
      }

      .my-avatar {
        width: pxToVW(150);
        height: pxToVW(150);
        // padding: pxToVW(15);
        background-color: $bg-stability;
        border-radius: 50%;
        margin: 0 auto pxToVW(15) auto;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        .my-level {
          width: pxToVW(51);
          height: pxToVW(24);
          padding: pxToVW(3) pxToVW(10);
          border-radius: pxToVW(20);
          background-color: $color-black;
          color: $white;
          font-size: pxToVW(16);
          font-weight: normal;
          line-height: 1.13;
          position: absolute;
          letter-spacing: pxToVW(-0.48);
          left: 50%;
          bottom: pxToVW(-8);
          @include transform-translateX(-50%);
        }
      }

      .user-name {
        font-size: pxToVW(23);
        font-weight: $black;
        line-height: 1.17;
        letter-spacing: normal;
        margin-bottom: pxToVW(3);
      }

      .my-phone {
        opacity: 0.8;
        font-family: $Roboto;
        font-size: pxToVW(12);
        font-weight: normal;
        line-height: 1.17;
        letter-spacing: normal;
      }
    }

    .title-store-like {
      font-size: pxToVW(14);
      font-weight: bold;
      line-height: 1.14;
      letter-spacing: normal;
      margin-bottom: pxToVW(10);
      padding: 0 pxToVW(20) 0 pxToVW(20);
    }

    .list-store {
      overflow: auto;
      margin: 0;
      padding: 0;
      height: pxToVW(36);
      // padding: 0 pxToVW(20) 0 pxToVW(20);
      margin-left: pxToVW(20);

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
        background-color: $btn-bg;
        height: 0;
      }

      &::-webkit-scrollbar {
        width: 0;
        background-color: $btn-bg;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $btn-bg;
        border: 0 solid $btn-bg;
        height: 0;
      }

      ul {
        display: inline-flex;
        align-items: center;

        li {
          padding: pxToVW(10) pxToVW(25);
          border-radius: pxToVW(20);
          background-color: rgba($white, 0.5);
          margin-right: pxToVW(10);

          div {
            font-size: pxToVW(14);
            font-weight: $black;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            max-width: pxToVW(200);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .vs-name {
    margin-top: pxToVW(10);
    padding: 0 pxToVW(20);

    span {
      font-size: pxToVW(11);
      font-weight: normal;
      line-height: 1.09;
      opacity: 0.5;
      letter-spacing: normal;
      text-align: right;
    }
  }

  // .my-logout {
  //   display: inline-block;
  //   font-size: pxToVW(12);
  //   font-weight: normal;
  //   line-height: 1.08;
  //   letter-spacing: normal;
  //   text-align: center;
  //   color: rgba($governor-2, 0.5);
  //   border-bottom: pxToVW(1) solid rgba($governor-2, 0.5);

  //   img {
  //     margin-left: pxToVW(3);
  //     width: pxToVW(10);
  //   }
  // }
}

.list-box-my {
  display: flex;
  align-items: center;
  padding: 0 pxToVW(20);
  .my-box {
    width: pxToVW(105);
    height: pxToVW(100);
    margin: pxToVW(30) pxToVW(10) 0 0;
    padding-top: pxToVW(25);
    border-radius: pxToVW(10);
    box-shadow: 0 pxToVW(3) pxToVW(6) 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    img {
      margin-bottom: pxToVW(7);
      width: pxToVW(34);
      margin: auto;
    }

    .box-name {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.08;
      letter-spacing: normal;
      text-align: center;
      padding-top: pxToVW(7);

      &.box1 {
        color: $governor-2;
      }

      &.box2 {
        color: $green;
      }

      &.box3 {
        color: $yellow;
      }
      &.box4 {
        color: #63b1ff;
      }
      &.box5 {
        color: #f22d63;
      }
      &.box6 {
        color: #858585;
      }
      &.box7 {
        color: #858585;
      }
    }
  }
  .menu-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    &.menu-user {
      .my-box {

      }
    }
    &.menu-admin {
      .my-box {
        width: calc(100% / 4 - 8px);
        .box-name {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 33px;
        }
      }
    }
  }
}

.mb-20 {
  margin-bottom: pxToVW(20) !important;
}

.sign-up-page {
  margin-top: pxToVW(20);

  .my-avatar {
    width: pxToVW(150);
    height: pxToVW(150);
    // padding: pxToVW(15);
    background-color: $bg-stability;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    border: pxToVW(1) solid $gallery;

    img {
      width: 100%;
      height: 100%;
    }

    .avatar {
      border-radius: 50%;
    }

    .icon-edit {
      width: pxToVW(40);
      height: pxToVW(40);
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .custom-file-input {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + #{pxToVW(15)});
      height: calc(100% + #{pxToVW(15)});
      border-radius: 50%;
    }
  }

  .group-list-store {
    ul {
      li {
        text-align: center;
        height: pxToVW(36);
        margin: pxToVW(10) pxToVW(10) pxToVW(0) pxToVW(0);
        padding: pxToVW(10) pxToVW(25);
        border-radius: pxToVW(20);
        background-color: $wild-sand;
        display: inline-block;

        div {
          font-size: pxToVW(14);
          font-weight: $black;
          line-height: 1.14;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          flex-wrap: nowrap;
          white-space: nowrap;

          img {
            height: pxToVW(8);
            padding: 0 pxToVW(4);
            position: relative;
            width: auto;
          }
        }
      }
    }
  }
}

.layout-edit-info {
  padding-bottom: pxToVW(70);
  min-height: 100vh;
}

.my-page {
  .list-box-my {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    overflow-y: scroll;
    .my-box {
      width: calc(100% / 3 - 10px);
      margin-top: 0;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
  .vs-name {
    margin-top: 0;
  }
  .my-page-info {
    //padding-bottom: 70px;
  }
  @media only screen and (max-width: 380px) {
    .my-page-info {
      margin-bottom: 95px;
    }
  }
}


.list-box-my {
  .my-box {
    .box-name {
      &.box5 {
        color: #f22d63;
      }

      &.box6 {
        color: #858585;
      }

      &.box7 {
        color: #858585;
        font-family: NanumSquareB;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: -0.12px;
        text-align: center;
        color: #4e4e4e;
      }
    }
  }

  .menu-item {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &.menu-user {
      .my-box {

      }
    }

    &.menu-admin {
      .my-box {
        width: calc(100% / 4 - 8px);

        .box-name {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 33px;
        }
      }
    }
  }
}


.list-box-custom {
  .my-box {
    &:last-child {
      visibility: hidden;
      pointer-events: none;
    }
  }
}